import {logOut} from "../redux/dataUploader/dataSlice";
import {getActiveYieldkitOffersListService} from "../services/yieldkit";
import prepareCSVData from "../helpers/prepare-csv-data";

const GetActiveYieldkitOffersListData = async (setLoading, setError, dispatch, setData, setCsvData, an_id, restData, offset, limit) => {
    setLoading(true);

    try {
        const results = await getActiveYieldkitOffersListService(offset, limit, an_id);
        if(results.data.error) {
            setError(true);
            setLoading(false);
        } else {
            const modifiedData = [...restData, ...results.data.data];
            setData(modifiedData);
            setCsvData(prepareCSVData(modifiedData));
            setLoading(false);
            return results.data.data;
        }
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default GetActiveYieldkitOffersListData;