import React from 'react';
import "./message-sending-data.scss";

const MessageSendingData = ({messageText, setClosePopup}) => {
    return (
        <div className='message-sending-wrap'>
            <div className='message-sending'>
                <div className="message-sending-block">
                    <button onClick={setClosePopup}>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.79065 6.77981C8.06978 7.05894 8.06978 7.51151 7.79065 7.79065C7.51151 8.06978 7.05894 8.06978 6.77981 7.79065L4.00017 5.01101L1.22053 7.79065C0.941395 8.06978 0.488826 8.06978 0.209691 7.79065C-0.0694449 7.51151 -0.069445 7.05894 0.209691 6.77981L2.98933 4.00017L0.209352 1.22019C-0.0697839 0.941055 -0.0697838 0.488487 0.209352 0.209351C0.488487 -0.0697841 0.941056 -0.0697837 1.22019 0.209352L4.00017 2.98933L6.78015 0.209352C7.05928 -0.0697837 7.51185 -0.0697841 7.79099 0.209351C8.07012 0.488487 8.07012 0.941055 7.79099 1.22019L5.01101 4.00017L7.79065 6.77981Z" fill="#FFFFFF"/>
                        </svg>
                    </button>
                    {messageText}
                </div>
            </div>
        </div>
    );
}

export default MessageSendingData;