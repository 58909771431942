import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accountInformationStart,
    affiliateNetworksStart,
    logOut
} from "../../redux/dataUploader/dataSlice";
import {USER_PROJECT, USER_ROLE} from "../../constants/Auth";
import {accountDetailsService} from "../../services/yieldkit";
import PreTableWithPopups from "../../components/tables/PreTableWithPopups";
import CreateNewItemBtn from "../../components/buttons/CreateNewItemBtn";
import {deleteAccountDetailsService} from "../../services/yieldkit";
import {modifyTableData} from '../../helpers/modify-accounts-data';
import filterByProject from "../../helpers/filter-by-project";
import CustomTooltip from "../../components/CustomTooltip";
import Loader from "../../components/loaders/Loader";

function YieldkitAccountsPage() {
    const dispatch = useDispatch();
    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const affiliateNetworks = useSelector(state => state.dataUploader.affiliateNetworks);
    const [accountDetails, setAccountDetails] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [newDataStatus, setNewDataStatus] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);
    const [configData, setConfigData] = useState([]);
    const [addPopupStatus, setAddPopupStatus] = useState(false);
    const [addPopupWithConfigData, setAddPopupWithConfigData] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [isPopupType, setPopupType] = useState('updating');
    const [deletePopupStatus, setDeletePopupStatus] = useState(false);
    const [currentDeleteItem, setCurrentDeleteItem] = useState({});
    const [remarksList, setRemarksList] = useState([]);

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
        dispatch(affiliateNetworksStart());
    }, [dispatch, projectName])

    const getAccountDetails = async () => {
        setLoading(true);
        try {
            const accountDetailsData = await accountDetailsService({an_id: '', voluum_id: ''});
            const modifyData = modifyTableData(accountDetailsData.data.data);

            if(role !== 'admin') {
                setAccountDetails(filterByProject(modifyData, projectName));
                setConfigData(filterByProject(modifyData, projectName));
            } else {
                setAccountDetails(modifyData);
                setConfigData(modifyData);
            }

            if (accountDetailsData.data.data.length) {
                const keyArr = Object.keys(modifyData[0])
                const countedNames = createInitialValue(keyArr);
                setInitialValues(countedNames)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
            console.error('Error!', error);
            if (error.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    useEffect(() => {
        getAccountDetails();
    }, [setAccountDetails])

    useEffect(() => {
        const getRemarkName = data => data.map(({project}) => project);
        setRemarksList(getRemarkName(accountInformation));
    }, [accountInformation])

    const modifiedColumnsData = (columns) => columns.map(el => {
        let columnDef = {
            field: el,
            headerName: el.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
            filter: true,
            wrapText: true,
            tooltipField: el,
            tooltipComponent: CustomTooltip,
        };

        if (el === 'id') {
            columnDef = {
                ...columnDef,
                hide: true
            }
        } else if (el === 'voluum_account_id') {
            columnDef = {
                ...columnDef,
                hide: role === 'admin'
            }
        } else if (el === 'affiliate_network_id') {
            columnDef = {
                ...columnDef,
                headerName: 'Affiliate Network',
                minWidth: 130,
                cellRenderer: (params) => {
                    const {value} = params;
                    return (
                        value === 2 ? 'Yieldkit CPC' : 'Yieldkit'
                    )
                }
            }
        } else if (el === 'status') {
            columnDef = {
                ...columnDef,
                minWidth: 130,
                maxWidth: 130,
                cellRenderer: (params) => {
                    const {value} = params;
                    return (
                        <span className={`status-cell${value ? ' active-cell' : ' deactivated-cell'}`}>
                            {value ? 'active' : 'deactivated'}
                        </span>
                    )
                }
            }
        } else if (el === 'api_key' || el === 'api_secret' || el === 'site_id' || el === 'voluum_affiliate_network_id') {
            columnDef = {
                ...columnDef,
                wrapText: false,
                minWidth: 150,
                cellStyle: {'display': 'block'}
            }
        } else if (el === 'voluum_affiliate_network') {
            columnDef = {
                ...columnDef,
                suppressSizeToFit: true,
                minWidth: 50,
                maxWidth: 140
            }
        } else {
            columnDef = {
                ...columnDef,
                suppressSizeToFit: true,
                minWidth: 50,
                maxWidth: 150
            }
        }
        return columnDef;
    })

    const createInitialValue = (data) => data.reduce((allEl, el) => {
        if(role !== 'admin' && el === 'remark') {
            return {
                ...allEl,
                [el]: projectName,
            };
        }

        return {
            ...allEl,
            [el]: '',
        };
    }, {});

    const onCountedNames = (allValues, currentItemData) => {
        const countedNames = allValues.reduce((allEl, el) => {
            if(role !== 'admin' && el === 'remark') {
                return {
                    ...allEl,
                    [el]: projectName,
                };
            }
            return {
                ...allEl,
                [el]: currentItemData[el] !== null ? currentItemData[el] : '',
            };
        }, {});
        setInitialValues(countedNames);
    }

    const openCreatePopup = () => {
        setPopupType('creating');
        const {id, voluum_account_id, voluum_affiliate_network, ...other} = accountDetails[0];
        const keyArr = Object.keys(other)
        const countedNames = createInitialValue(keyArr);

        setInitialValues(countedNames)
        setAddPopupStatus(true);
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <div className='yieldkit-accounts-page-container'>
            {accountDetails.length > 0 && (remarksList.includes(projectName) || role === 'admin') ? <CreateNewItemBtn onClick={openCreatePopup}/> : <p></p>}
            <PreTableWithPopups
                rowData={accountDetails}
                newDataStatus={newDataStatus}
                setNewDataStatus={setNewDataStatus}
                columnDefs={columnDefs}
                setColumnDefs={setColumnDefs}
                addPopupStatus={addPopupStatus}
                setAddPopupStatus={setAddPopupStatus}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                configData={configData}
                modifiedColumnsData={modifiedColumnsData}
                onCountedNames={onCountedNames}
                addPopupWithConfigData={addPopupWithConfigData}
                isPopupType={isPopupType}
                setPopupType={setPopupType}
                deletePopupStatus={deletePopupStatus}
                setDeletePopupStatus={setDeletePopupStatus}
                setCurrentDeleteItem={setCurrentDeleteItem}
                remarksList={remarksList}
                accountDetails={accountDetails}
                setAccountDetails={setAccountDetails}
                affiliateNetworks={affiliateNetworks}
            />
        </div>
    );
}

export default YieldkitAccountsPage;