import {useEffect, useState} from "react";
import Select from "react-select";
import DataTable from "../../components/tables/DataTable";
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination";
import DiscoveryToolTableFilter from "../../components/DiscoveryToolTableFilter";
import CsvDownload from "../../components/buttons/CsvDownload";
import {
    columnDefs,
    orderDirectionOptions,
    rowsLimitOptions,
    filterConditionOptions,
} from "./table-options";
import './discovery-tool-offers.scss';
import InfoPopup from "../../components/popups/InfoPopup";
import DTChangeStatusPopup from "../../components/popups/DTChangeStatusPopup";


const DiscoveryToolOffers = ({type}) => {
    const orderByOptions = columnDefs.reduce((acc, item) => {
        acc.push({value: item.key, label: item.label})
        return acc;
    }, [])
    const searchByColumnOptions = columnDefs.reduce((acc, item) => {
        acc.push({value: item.key, label: item.label})
        return acc;
    }, [])

    const [isLoading, setIsLoading] = useState(true);
    const [restTableData, setRestTableData] = useState([]);
    const [rowsLimit, setRowsLimit] = useState({value: "10", label: "10 / page"});
    const [totalRows, setTotalRows] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        orderDirection: orderDirectionOptions[0],
        orderBy: orderByOptions[0],
        columnsToSearch: [],
    });
    const [allRestData, setAllRestData] = useState([])
    const [csvData, setCSVData] = useState([])
    const [selectedRowsIDs, setSelectedRowsIDs] = useState([])
    const [isShowInfoPopup, setIsShowInfoPopup] = useState(false)
    const [infoPopupText, setInfoPopupText] = useState("")
    const [triggerUpdateTable, setTriggerUpdateTable] = useState(false)
    const [isShowChangeStatusPopup, setIsShowChangeStatusPopup] = useState(false)
    const [statusOptions, setStatusOptions] = useState([
        {value: "deactivated", label: "Deactivated"},
        {value: "completed", label: "Completed"},
    ])

    const rowsLimitSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        })
    }

    useEffect(() => {
        //fetch data when sorting, filtering, rows limit or page changes
        handleGetTableData()
    }, [rowsLimit, currentPage, selectedFilters]);

    useEffect(() => {
        // fetch all data for CSV
        handleGetTableData(true)
    }, [totalRows, selectedFilters]);

    useEffect(() => {
        if (totalRows > 0) {
            setTotalPagesCount(Math.ceil(totalRows / rowsLimit.value));
        } else {
            setTotalPagesCount(1);
        }
        setCurrentPage(1);
    }, [totalRows, rowsLimit]);

    const handleGetTableData = (isForCSV = false) => {
        if (isForCSV && totalRows === 0) return
        if (!isForCSV) {
            setIsLoading(true)
        }

        setSelectedRowsIDs([])

        //get parameter for filtering
        let filterParam = {}
        if (selectedFilters.columnsToSearch.length > 0) {
            for (let i = 0; i < selectedFilters.columnsToSearch.length; i++) {

                let val = selectedFilters.columnsToSearch[i].searchValue
                if (selectedFilters.columnsToSearch[i].condition === "like" || selectedFilters.columnsToSearch[i].condition === "not like") {
                    val = `%${selectedFilters.columnsToSearch[i].searchValue}%`
                }

                filterParam[selectedFilters.columnsToSearch[i].columnName] = {
                    operator: selectedFilters.columnsToSearch[i].condition,
                    value: val
                }
            }
        }

        let urlParams = `
            ?limit=${isForCSV ? totalRows : rowsLimit.value}
            &offset=${isForCSV ? 0 : (currentPage - 1) * rowsLimit.value}
            &orderBy=["${selectedFilters?.orderBy?.value ? selectedFilters.orderBy.value : "id"}"]
            &orderByDirection=${selectedFilters.orderDirection.value}
            &where=${encodeURI(JSON.stringify(filterParam))}
        `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks

        //fetch when params changes
        fetch(`${process.env.REACT_APP_DT_API_URL}offers${urlParams}`, {
            method: "GET",
            headers: {
                "X-Api-Static-Key": process.env.REACT_APP_DT_API_KEY,
            }
        })
            .then(res => res.json())
            .then(res => {
                if (!isForCSV) {
                    setIsLoading(false);
                    setRestTableData(res.data);
                    setTotalRows(res.count);
                }

                if (isForCSV) {
                    setCSVData(res.data)
                    setAllRestData(res.data);
                }
            })
    }

    const handleChangeSelectedFilters = (value) => {
        setSelectedFilters(value)
    }

    const handleClearSearchByColumnFilter = () => {
        let temp = {...selectedFilters};
        temp["columnsToSearch"] = [];
        setSelectedFilters(temp);
        setSelectedRowsIDs([])
    }

    const handleChangeSelectedIDs = (idsArr) => {
        setSelectedRowsIDs(idsArr)
        if (idsArr.length > 0) {
            setCSVData(allRestData.filter(item => idsArr.includes(item.id)))
        } else {
            setCSVData(allRestData)
        }
    }

    const handleChangeStatus = (status) => {
        const rowsArr = allRestData.filter(item => selectedRowsIDs.includes(item.id))
        const body = rowsArr.reduce((acc, item) => {
            acc.push({id: item.id, status: status.value})
            return acc
        }, [])

        setIsLoading(true)
        setIsShowChangeStatusPopup(false)
        fetch(`${process.env.REACT_APP_DT_API_URL}offers`, {
            method: "PUT",
            headers: {
                "X-Api-Static-Key": process.env.REACT_APP_DT_API_KEY,
            },
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (Array.isArray(res)) {
                    setIsLoading(false)
                    setInfoPopupText("Status changed successfully")
                    setIsShowInfoPopup(true)
                    setSelectedRowsIDs([])
                    setTriggerUpdateTable(true)
                } else {
                    setIsLoading(false)
                    setInfoPopupText("Something went wrong")
                    setIsShowInfoPopup(true)
                }
            })
    }

    const handleChangeStatusButton = () => {
        let statusesOfSelectedRows = restTableData.filter(item => selectedRowsIDs.includes(item.id)).reduce((acc, item) => {
            acc.push(item["status"])
            return acc
        }, [])

        // "completed" and "deactivated" statuses can't be changed
        if (statusesOfSelectedRows.includes("completed") || statusesOfSelectedRows.includes("deactivated")) {
            setInfoPopupText("Status completed or deactivated can't be changed")
            setIsShowInfoPopup(true)
            return
        }

        // "new" status can be "deactivated" only
        if (statusesOfSelectedRows.includes("new")) {
            setStatusOptions([{value: "deactivated", label: "Deactivated"}])
            setIsShowChangeStatusPopup(true)
            return
        }

        setStatusOptions([
            {value: "deactivated", label: "Deactivated"},
            {value: "completed", label: "Completed"},
        ])
        setIsShowChangeStatusPopup(true)
    }

    if (isLoading) return <Loader/>

    return (
        <div className={`discovery-tool-container ${type}-container`}>
            <div className="dt-buttons-container">
                <CsvDownload
                    csvData={csvData}
                    children={"Download CSV"}
                    filename={"discovery-offers"}
                    disabled={!csvData.length > 0}
                />
                <button
                    className={`blue-btn ${selectedRowsIDs.length === 0 ? "disabled" : ""}`}
                    onClick={handleChangeStatusButton}
                    disabled={selectedRowsIDs.length === 0}
                >Change Status
                </button>
            </div>
            <DiscoveryToolTableFilter
                orderByOptions={orderByOptions}
                orderDirectionOptions={orderDirectionOptions}
                searchByColumnOptions={searchByColumnOptions}
                filterConditionOptions={filterConditionOptions}
                selectedFilters={selectedFilters}
                changeSelectedFilters={handleChangeSelectedFilters}
                clearSearchFilters={handleClearSearchByColumnFilter}
            />
            <DataTable
                data={restTableData}
                columnDefs={columnDefs}
                isSelectableRows={true}
                onSelectChange={handleChangeSelectedIDs}
            />
            <div className="data-table-footer">
                {rowsLimitOptions.length > 0 && (
                    <Select
                        className={"data-table-rows-limit"}
                        styles={rowsLimitSelectStyles}
                        options={rowsLimitOptions}
                        isSearchable={false}
                        defaultValue={rowsLimit}
                        name={"rows_count"}
                        onChange={(option) => {
                            setRowsLimit(option);
                        }}
                    />
                )}
                <div className={"data-table-rows-count"}>Total rows count: <strong>{totalRows}</strong></div>
                <Pagination
                    totalPagesCount={totalPagesCount}
                    setCurrentPage={(value) => setCurrentPage(value)}
                    currentPage={currentPage}
                />
            </div>
            {isShowInfoPopup &&
                <InfoPopup
                    text={infoPopupText}
                    onClose={() => {
                        setIsShowInfoPopup(false)
                        if (triggerUpdateTable) {
                            handleGetTableData()
                            handleGetTableData(true)
                        }
                    }}
                    onConfirm={() => {
                        setIsShowInfoPopup(false)
                        if (triggerUpdateTable) {
                            handleGetTableData()
                            handleGetTableData(true)
                        }
                    }}
                />
            }

            {isShowChangeStatusPopup && <DTChangeStatusPopup
                statusOptions={statusOptions}
                onConfirm={handleChangeStatus}
                onCancel={() => setIsShowChangeStatusPopup(false)}
            />}
        </div>
    )
}

export default DiscoveryToolOffers;
