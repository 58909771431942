const prepareCSVData = data => {
    let newData = [];
    data.forEach(obj => {
        if(obj['offer_description']) obj['offer_description'] = obj['offer_description'].replaceAll(', ', '; ');
        newData.push(obj);
    })
    return newData;
}

export default prepareCSVData;
