import {AUTH_TOKEN, USER_OTP_ENABLED} from "../constants/Auth";

const initialState = {
    date: new Date(),
    dateRange: [new Date(), new Date()],
    dateRangeEmpty: [null, null],
    currentPath: '/',
    token: localStorage.getItem(AUTH_TOKEN),
    userOtpEnabled: localStorage.getItem(USER_OTP_ENABLED),
    collapseMenu: true,
    errorLogIn: false,
    isLoading: false,
    isSuccessfully: false,
    errorSubmitted: false,
    accountDetails: [],
    accountInformation: [],
    voluumId: '',
    voluumAlerts: [],
    accountSelected: '',
    voluumAlertItem: {},
    voluumAlertItemStatus: false,
    hiddenColumns: [],
    affiliateNetworks: [],
    settingsViewSelected: '',
    isVoluumCSVdifferentColumn: false,
};

export default initialState;