import Home from "../pages/Home";
import Login from "../pages/Login";
import YieldkitOffersList from "../pages/YieldkitOffersList";
import NewUser from "../pages/NewUser";
import NotFound from "../pages/NotFound";
import VoluumAlertsConfig from "../pages/VoluumAlertsConfig";
import YieldkitAccountsPage from "../pages/YieldkitAccountsPage";
import AlertsDownload from "../pages/AlertsDownload";
import UserInfoPage from "../pages/UserInfoPage";
import ClosedCampaignsList from "../pages/ClosedCampaignsList";
import ClosedCampaignsDetails from "../pages/ClosedCampaignsDetails";
import ResetPassword from "../pages/ResetPasswordPage";
import YieldkitStatusChecker from "../pages/YieldkitStatusChecker";
import ChangeVoluumByOffer from "../pages/ChangeVoluumByOffer";
import VoluumReports from "../pages/VoluumReports";
import OponiaStatusChecker from "../pages/OponiaStatusChecker";
import KelkooReports from "../pages/KelkooReports";
import AdminLinks from "../pages/AdminLinks";
import DiscoveryToolOffers from "../pages/DiscoveryToolOffers";
import ApprovalRateChecker from "../pages/ApprovalRateChecker";
import {DISCOVERY_TOOL_TYPE_CAMPAIGNS, DISCOVERY_TOOL_TYPE_OFFERS, DISCOVERY_TOOL_TYPE_DETAILS} from "../constants";
import AffiliateNetworkOffers from "../pages/AffiliateNetworkOffers";
import DiscoveryToolCampaigns from "../pages/DiscoveryToolCampaigns";
import DiscoveryToolDetails from "../pages/DiscoveryToolDetails";

const routeConfig =  [
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: '/login/',
        element: <Login/>,
    },
    {
        path: '/yieldkit-offers/',
        element: <YieldkitOffersList />,
    },
    {
        path: '/yieldkit-offers-cpc/',
        element: <YieldkitOffersList an_id={2} />,
    },
    {
        path: '/voluum-alerts-settings/',
        element: <VoluumAlertsConfig />,
    },
    {
        path: '/alerts/',
        element: <AlertsDownload />,
    },
    {
        path: '/new-user/',
        element: <NewUser />,
    },
    {
        path: '/yieldkit-account-settings/',
        element: <YieldkitAccountsPage />,
    },
    {
        path: '/yieldkit-status-checker/',
        element: <YieldkitStatusChecker />,
    },
    {
        path: '/yk2-checker/',
        element: <OponiaStatusChecker />,
    },
    {
        path: '/change-voluum-offer/',
        element: <ChangeVoluumByOffer />,
    },
    {
        path: '/voluum-reports/',
        element: <VoluumReports />,
    },
    {
        path: '/discovery-tool-offers/',
        element: <DiscoveryToolOffers type={DISCOVERY_TOOL_TYPE_OFFERS}/>,
        role: ["admin"],
    },
    {
        path: '/discovery-tool-campaigns/',
        element: <DiscoveryToolCampaigns type={DISCOVERY_TOOL_TYPE_CAMPAIGNS}/>,
        role: ["admin"],
    },
    {
        path: '/discovery-tool-details/',
        element: <DiscoveryToolDetails type={DISCOVERY_TOOL_TYPE_DETAILS}/>,
        role: ["admin"],
    },
    {
        path: '/kelkoo-reports/',
        element: <KelkooReports />,
        role: ["admin"],
    },
    {
        path: '/approval-rate-checker/',
        element: <ApprovalRateChecker />,
    },
    {
        path: '/user-profile/',
        element: <UserInfoPage />,
    },
    {
        path: '/admin-links/',
        element: <AdminLinks />,
    },
    {
        path: '/changed-campaigns/',
        element: <ClosedCampaignsList />,
    },
    {
        path: '/changed-campaigns-details/',
        element: <ClosedCampaignsDetails />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
    {
        path: '/affiliate-network-offers/',
        element: <AffiliateNetworkOffers />,
    },
]

export default routeConfig;
