import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {accountSelected, voluumId} from "../../redux/dataUploader/dataSlice";

import './custom-select.scss';

const CustomSelect = ({data, curValue, setTableLoading, applyDispatch, onChange, setVoluumId, setVoluumNum, setVoluumNumReport, setDispatchVoluumId, setTgChatId, pagination = false, allOption = false, setSettingsStatus}) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const curAccount = useSelector(state => state.dataUploader.accountSelected);
    const [optionSelected, setOptionSelected] = useState(curAccount);
    const [options, setOptions] = useState(data);
    const [showOptionList, setShowOptionList] = useState(false);

    useEffect(() => {
        if(data.length > 0 && setVoluumId && !setVoluumNum) {
            setOptionSelected('all');
            setVoluumId('');
        } else if(data.length > 0 && setVoluumNum) {
            if(optionSelected === '' || optionSelected === 'all') {
                setOptionSelected('choose account');
                setVoluumNum('');
            }
        } else if(data.length > 0 && setVoluumNumReport) {
            const firstAcc = data.reduce((prev, curr) => +prev['voluum_id'] < +curr['voluum_id'] ? prev : curr);
            setOptionSelected(firstAcc.project);
            setVoluumNumReport(firstAcc.voluum_id);
        } else if(data.length > 0 && (setTgChatId || setDispatchVoluumId)) {
            const arr = data.reduce((prev, curr) => +prev['voluum_id'] < +curr['voluum_id'] ? prev : curr);
            if(!allOption) {
                curAccount === 'all' ? setOptionSelected(arr.project) : curAccount ? setOptionSelected(curAccount) : setOptionSelected(arr.project);
            } else if(allOption && optionSelected === '' && !curValue) {
                setOptionSelected('all');
                dispatch(voluumId('all'));
            } else if(curValue && curValue !== '') {
                const curAccArr = data.filter(el => el['voluum_id'] === curValue);
                if(curAccArr.length > 0 && optionSelected === '' && optionSelected !== curAccArr[0].project) {
                    setOptionSelected(curAccArr[0].project);
                    dispatch(voluumId(curValue));
                } else if(optionSelected === '') {
                    setOptionSelected('all');
                    dispatch(voluumId('all'));
                }
            }
        } else if (data.length > 0) {
            setOptionSelected(data[0].project);
        }
    }, [data, setVoluumId, setVoluumNum, setVoluumNumReport, curValue, pathname]);

    useEffect(() => {
        if(applyDispatch) dispatch(accountSelected(optionSelected));
        if(onChange) onChange(optionSelected);
        const newArr = data.filter(el => el.project !== optionSelected);
        if(setVoluumId && !setVoluumNum) newArr.unshift({project: 'all', voluum_id: ''});
        if(setDispatchVoluumId && allOption) newArr.unshift({project: 'all', voluum_id: 'all'});
        setOptions(newArr);
    }, [data, optionSelected, applyDispatch, dispatch, onChange]);

    const handleListDisplay = () => {
        setShowOptionList(!showOptionList);
    };

    const handleOptionClick = e => {
        if(setTableLoading) setTableLoading(true);
        setShowOptionList(false);
        setOptionSelected(e.target.getAttribute("data-name"));
        if(setVoluumId) setVoluumId(e.target.getAttribute("data-id"));
        if(setVoluumNum) setVoluumNum(e.target.getAttribute("data-id"));
        if(setVoluumNumReport) setVoluumNumReport(e.target.getAttribute("data-id"));
        if(setTgChatId) setTgChatId(e.target.getAttribute("data-id"));
        if(setDispatchVoluumId) {
            dispatch(voluumId(e.target.getAttribute("data-id")));
            dispatch(accountSelected(e.target.getAttribute("data-name")));
            if(setSettingsStatus && curValue !== '') setSettingsStatus(e.target.getAttribute("data-id") !== curValue);
        }
    };

    return (
        <div className={`transparent-btn category-dropdown ${showOptionList ? 'active' : ''}`} onClick={handleListDisplay}>
            <div className={`selected-text ${showOptionList ? 'active' : ''}`}>
                {pagination ? `${optionSelected} / page` : optionSelected}
            </div>
            {showOptionList && (
                <ul className='custom-select-option-container'>
                    <li
                        className="custom-select-option"
                        data-name={optionSelected}
                        hidden
                    >
                        {pagination ? `${optionSelected} / page` : optionSelected}
                    </li>
                    {options.map((option, i) => {
                        return (
                            <li
                                className="custom-select-option"
                                data-name={option.project}
                                data-id={setVoluumId || setVoluumNum || setDispatchVoluumId ? option['voluum_id'] : setTgChatId ? option['tg_chat_id'] : ''}
                                key={i}
                                onClick={handleOptionClick}
                            >
                                {pagination ? `${option.project} / page` : option.project}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
