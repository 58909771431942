import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {USER_EMAIL} from "../../../constants/Auth";

export default function YkStatusCheckerPopup({open, handleClose}) {

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose(false)
                window.location.reload()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Links sent for checking"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    The files with results will be sent to email <strong>{localStorage.getItem(USER_EMAIL)}</strong>.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleClose(false)
                    window.location.reload()
                }} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
}
