import {logOut} from "../redux/dataUploader/dataSlice";
import {addNewUserService} from "../services/user";

const addNewUser = async (setFormLoading, setFormErrorMsg, setSuccessStatus, setFormSuccessMsg, setFormError, dispatch, data, resetForm) => {
    setFormLoading(true);

    try {
        const results = await addNewUserService(data);
        if(results.data.message) {
            setFormError(false);
            setSuccessStatus(true);
            setFormSuccessMsg(results.data.message);
            resetForm({values: ''});
        } else {
            setFormError(true);
            setSuccessStatus(false);
        }
        setFormLoading(false);
    } catch (error) {
        setSuccessStatus(false);
        setFormLoading(false);
        if(error.response.data.message) {
            setFormError(true);
            setFormErrorMsg(error.response.data.message);
        }
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default addNewUser;