import React from 'react';
import {Field, Form, Formik} from "formik";

const OptValidate = ({
                         initialValues,
                         currentValues,
                         onSubmit,
                         validateCode,
                         isLoading,
                         isSuccessfully
                     }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({errors, touched}) => (
            <Form>
                <div className={`input-wrap ${errors.optCode && touched.optCode ? 'error-input' : ''}`}>
                    <div className="input-block login-input-block">
                        <label htmlFor="optCode" className="label-name">
                                        <span className="content-name">
                                          2FA Code
                                        </span>
                        </label>
                        <Field
                            id="optCode"
                            name="optCode"
                            type="text"
                            validate={validateCode}
                            className={currentValues.optCode !== '' ? 'filled' : ''}
                        />
                    </div>
                    {errors.optCode && touched.optCode &&
                    <div className='error-text login-error-text'>{errors.optCode}</div>}
                </div>
                <button className={`form-submit ${isSuccessfully ? 'successfully' : ''}`} type="submit" disabled={isLoading}>Submit</button>
            </Form>
        )}
    </Formik>
);

export default OptValidate;