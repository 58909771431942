import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PreTableAlerts from "../tables/PreTableAlerts";
import PreTableInfected from "../tables/PreTableInfected";
import PreTableClosedCampaigns from "../tables/PreTableClosedCampaigns";
import {OFFER_TYPE} from "../../constants";

import 'react-tabs/style/react-tabs.css';
import './custom-react-tabs.scss';

const CustomTabs = ({titles, getTabTitle, data, additionalData, onDataTableChanged, alertsStatus = false, closedCampaignsStatus = false, getTableRef}) => {
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const date = useSelector((state) => state.dataUploader.date);
    const [visibleItems, setVisibleItems] = useState(0);
    const [showList, setShowList] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabNodes, setTabNodes] = useState([]);
    const tabIndexStorage = sessionStorage.getItem('tabIndexInfectedSites');

    useEffect(() => {
        if(!tabIndexStorage) sessionStorage.setItem('tabIndexInfectedSites', tabIndex);
        const nodes = document.querySelectorAll('[data-tabs-role="tab"]');
        setTabNodes(nodes);
    }, [titles]);

    useEffect(() => {
        if (titles.length > 0) {
            const mainContainer = document.querySelector('#tab-list-container')
            const tabsContainer = document.querySelector('#tabs-container')
            const containerWidth = mainContainer.offsetWidth;
            const children = tabsContainer.children;
            let totalWidth = 0;
            let itemsToHide = [];

            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                totalWidth += child.offsetWidth;
                if (totalWidth + child.offsetWidth >= containerWidth) {
                    itemsToHide.push(child);
                }
            }
            setVisibleItems(itemsToHide.length);
        }
    }, [titles, data, date]);

    const onTabSelect = e => {
        sessionStorage.setItem('tabIndexInfectedSites', e);
        setTabIndex(e);
    }
    const checkLastRow = arr => {
        if (Object.keys(arr[0])[0] !== Object.keys(arr[arr.length - 1])[0]) return arr.slice(0, -1);
        return arr;
    }

    const handleShowMore = () => {
        setShowList(!showList);
    };

    const popupTabClick = e => {
        const i = e.target.id;
        if (tabNodes.length > 1) {
            tabNodes[i].click();
            setShowList(!showList);
        }
    }

    return (
        <>
            <Tabs tabsrole="custom-tabs-role"
                  className={`tabs-container-ref${!menuStatus ? ' tabs-collapse-menu' : ''}`} defaultIndex={Number(tabIndexStorage)}
                  onSelect={onTabSelect}>
                <div id="tab-list-container" className="tab-list-container">
                    <TabList id='tabs-container'>
                        {titles.map((tab, i) => {
                            return (
                                <Tab key={i}
                                     data-tabs-role="tab"
                                     className={`${i > titles.length - visibleItems ? 'hidden' : ''}`}>{tab}</Tab>)
                        })}
                    </TabList>
                    {visibleItems > 0 ?
                        <button onClick={handleShowMore} className="react-tabs-show-more-btn">...</button> : null}
                        <div className={`tabs-popup${showList ? '' : ' hidden'}`}>
                            {titles.map((tab, i) => {
                                return (i > titles.length - visibleItems ?
                                    <div key={i} id={i} onClick={e => popupTabClick(e)}>{tab}</div> : null)
                            })}
                        </div>
                </div>

                {data.map((tableData, i) => {
                    return (<TabPanel key={i}>
                        {tableData && tableData.length > 0 && typeof tableData[0] !== "string" ?
                            alertsStatus ?
                                <PreTableAlerts
                                    rowData={checkLastRow(tableData)}
                                    name={titles[i]}
                                    alertNum={titles[i] && titles[i].length > 0 ? i + 1 : 1}
                                    getTableRef={getTableRef}
                                /> :
                                closedCampaignsStatus && titles[i] === OFFER_TYPE ?
                                    <PreTableClosedCampaigns
                                        title={titles[i]}
                                        getTabTitle={getTabTitle}
                                        rowData={checkLastRow(tableData)}
                                        listStatus={true}
                                        onDataTableChanged={onDataTableChanged}
                                        getTableRef={getTableRef}
                                    /> :
                                    closedCampaignsStatus ?
                                        <PreTableClosedCampaigns
                                            title={titles[i]}
                                            getTabTitle={getTabTitle}
                                            rowData={checkLastRow(tableData)}
                                            onDataTableChanged={onDataTableChanged}
                                            getTableRef={getTableRef}
                                        /> :
                                        <PreTableInfected rowData={checkLastRow(tableData)}/> :
                                    typeof tableData[0] === "string" && tableData[0] !== '' ?
                                        <h3>{tableData[0]}</h3> :
                                        <h3 className='no-data-title'>{closedCampaignsStatus ? 'The chosen offer does not have data available for this specific type.' : 'No data for this date.'}</h3>
                        }

                        {additionalData && additionalData.length > 0 && additionalData[i].length > 0 && tableData.length > 0 ?
                            <PreTableAlerts
                                rowData={additionalData[i]}
                                smallTable={true}
                                name={titles[i]}
                                alertNum={titles[i] && titles[i].length > 0 ? i + 1 : 1}
                                getTableRef={getTableRef}
                            /> : null}
                    </TabPanel>)
                })}
            </Tabs>
        </>
    )
}

export default CustomTabs;