import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {USER_ROLE, USER_ID} from "../../constants/Auth";
import {ADMIN_LINKS_SIDEBAR_BLOCKS, SIDEBAR_LINKS, SIDEBAR_LINKS_DOCUMENTATION} from "../../constants";
import './sidebar.scss';

const Sidebar = () => {
    const {pathname} = useLocation();
    const role = localStorage.getItem(USER_ROLE);
    const userId = localStorage.getItem(USER_ID);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);

    return (<div className={`sidebar-container${menuStatus ? '' : ' collapse-menu'}`}>
        <div className='sidebar-inner-container'>
            <div className="home-title-block">
                {menuStatus ?
                    <>
                        <div className='page-icon'>
                            <img src={`${process.env.PUBLIC_URL}/images/sidebar/home.svg`} alt=""/>
                        </div>
                        <Link to='/'>home</Link>
                    </> :
                    <Link to='/' className='page-icon' title='home'>
                        <img src={`${process.env.PUBLIC_URL}/images/sidebar/home.svg`} alt=""/>
                    </Link>
                }
            </div>

            {Object.entries(SIDEBAR_LINKS).map(([title, pageArr], i) => {
                if (!ADMIN_LINKS_SIDEBAR_BLOCKS.includes(title) || ((ADMIN_LINKS_SIDEBAR_BLOCKS.includes(title) && (role === 'admin' || userId === '22')))) {
                    return (
                        <div className="pages-block" key={i}>
                            <div className="page-title-block">
                                <div className={`page-icon ${title !== 'home' ? ' page-icon-bg' : ''}`}>
                                    <img src={`${process.env.PUBLIC_URL}/images/sidebar/${title}.svg`} alt=""/>
                                </div>
                                {menuStatus ?
                                    <h4 className={pageArr.length === 0 ? 'disabled' : ''}>{title.replaceAll('_', ' ')}</h4> : null}
                            </div>
                            {pageArr.length > 0 ?
                                <ul className={menuStatus ? '' : 'sidebar-popup'}>
                                    {pageArr.map(({link, status, name}, j) => {
                                        return (
                                            <li className={pathname === link ? 'active ' : !status ? 'disabled' : ''}
                                                key={j}>
                                                {status ?
                                                    link.includes('http') ?
                                                        <a href={link} target='_blank'>{name}</a> :
                                                        <Link to={link}>{name}</Link> :
                                                    <p>{name}</p>
                                                }
                                            </li>
                                        )
                                    })}
                                </ul> : null}
                        </div>
                    )
                } else {
                    return null;
                }
            })}
            <div className="pages-block">
                <div className="page-title-block">
                    <div className="page-icon">
                        <img src={`${process.env.PUBLIC_URL}/images/sidebar/admin.svg`} alt=""/>
                    </div>
                    {menuStatus ? <h4>Admin</h4> : null}
                </div>
                <ul className={menuStatus ? '' : 'sidebar-popup'}>
                    <li className={pathname === '/user-profile/' ? 'active ' : ''}>
                        <Link to='/user-profile/'>User profile</Link>
                    </li>
                    {role === 'admin' ?
                        <>
                            <li className={pathname === '/new-user/' ? 'active ' : ''}>
                                <Link to='/new-user/'>New User</Link>
                            </li>
                            <li className={pathname === '/admin-links/' ? 'active ' : ''}>
                                <Link to='/admin-links/'>Admin Links</Link>
                            </li>
                        </>
                        : null
                    }
                </ul>
            </div>
            <div className="pages-block-doc">
                <div className="page-title-block">
                    {menuStatus ? <h4 className='sidebar-section-title'>documentation</h4> : null}
                </div>
                {SIDEBAR_LINKS_DOCUMENTATION.map(({title, link}, index) => {
                    return (
                        <div key={index} className="pages-block">
                            <div className='page-title-block-doc page-title-block'>
                                <div className='page-icon'>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/sidebar/${title.replaceAll(' ', '_').toLowerCase()}.svg`}
                                        alt=""/>
                                </div>
                                <ul className={menuStatus ? '' : 'sidebar-popup'}>
                                    <li>
                                        <a href={link} className='sidebar-link' target='_blank'>{title}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        {!menuStatus ?
            <ul>
                {Object.values(SIDEBAR_LINKS)[0].map(({link, status, name}, j) => {
                    return (
                        <li className={pathname === link ? 'active ' : !status ? 'disabled' : ''}
                            key={j}>
                            {status ?
                                <Link to={link}>{name}</Link> :
                                <p>{name}</p>
                            }
                        </li>
                    )
                })}
            </ul> :
            null
        }
    </div>)
}

export default Sidebar;
