import {setHiddenColumns} from '../redux/dataUploader/dataSlice';

const getVoluumHiddenColumns = (data, dispatch) => {
    const result = data.reduce((accumulator, currentValue) => {
        if (!currentValue['table_visibility']) {
            return [...accumulator, currentValue.name];
        }
        return accumulator;
    }, []);
    dispatch(setHiddenColumns(result));
}

export default getVoluumHiddenColumns;