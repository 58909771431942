import React, {useState, useEffect, useMemo} from 'react';
import CustomTooltip from "../../CustomTooltip";
import roundToFourDecimals from "../../../helpers/round-to-four-decimals";
import Table from "../Table";
import {filterParams} from "../../../constants"

const PreTableComponent = ({onGridReady, rowData, onFilterChanged, onSortChanged, getTableRef, filterStatus, removeActiveFilter, hiddenColumns = [], addFlex = false}) => {
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
        if (rowData && rowData.length > 0) {

            const colArr = Object.entries(rowData[0]).map(([field, value]) => {
                let columnDef = {
                    field: field,
                    headerName: field.replaceAll('_', ' '),
                    filter: !isNaN(value) && value !== '' ? 'agNumberColumnFilter' : true,
                    wrapText: true,
                    tooltipField: field,
                    tooltipComponent: CustomTooltip,
                    valueFormatter: params => roundToFourDecimals(params.value),
                    minWidth: 200
                };

                if (field === 'id') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 100,
                        width: 100
                    };
                } else if (field === 'tracking_link' || field === 'url' || field === 'offer_description' || field === 'offer_id') {
                    columnDef = {
                        ...columnDef,
                        suppressSizeToFit: true,
                        minWidth: 340,
                        flex: 2
                    };
                } else if (field === 'link') {
                    columnDef = {
                        ...columnDef,
                        flex: 2
                    };
                } else if (field === 'json_file_data') {
                    columnDef = {
                        ...columnDef,
                        hide: true
                    };
                } else if (field === 'process_status') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 100,
                        headerName: 'status',
                        cellRenderer: (params) => {
                            const {value} = params;
                            if(value === 'new') {
                                return (<span>{value}</span>)
                            } else if(value === 'in progress') {
                                return (<span className='yk-offer-progress table-loader'><div></div><div></div><div></div><div></div></span>)
                            } else if(value === 'success') {
                                return (<span className='yk-offer-success'>✔</span>)
                            } else if(value === 'fail') {
                                return (<span className='yk-offer-fail'>✘</span>)
                            } else {
                                return (<span>{value}</span>)
                            }
                        }
                    };
                } else if (field === 'offer_status') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 100,
                        maxWidth: 140,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return (
                                <span className={`status-cell${value === 'active' ? ' active-cell' : value === 'deactivated' || value === 'failed to check' ? ' deactivated-cell' : ' empty-cell'}`}>
                                    {value}
                                </span>
                            )
                        }
                    };
                } else if (field === 'id') {
                    columnDef = {
                        ...columnDef,
                        comparator: (valueA, valueB) => valueA - valueB,
                        minWidth: 50,
                        maxWidth: 70,
                    };
                } else if (field === 'creation_date' || field === 'last_update_date' || field === 'last_activation_date' || field === 'created_at' || field === 'updated_at' || field === 'last_deactivation_date') {
                    columnDef = {
                        ...columnDef,
                        filter: 'agDateColumnFilter',
                        filterParams: filterParams,
                    };
                } else if (field === 'page' || field === 'position') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 80,
                        maxWidth: 100,
                    };
                } else if (hiddenColumns.length > 0 && !hiddenColumns.includes(field)) {
                    columnDef = {
                        ...columnDef,
                        hide: true
                    };
                } else {
                    columnDef = {
                        ...columnDef,
                        filter: !isNaN(value) && value !== '' ? 'agNumberColumnFilter' : true
                    };
                }

                return columnDef;
            });
            setColumnDefs(colArr);
        }
    }, [rowData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        autoHeight: true
    }));

    return (
        <Table
            onGridReady={onGridReady}
            rowData={rowData}
            defaultColDef={addFlex ? {...defaultColDef, flex: 1} : defaultColDef}
            columnDefs={columnDefs}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            getTableRef={getTableRef}
            filterStatus={filterStatus}
            removeActiveFilter={removeActiveFilter}
        />
    );
};


export default PreTableComponent;