import {YK_OFFERS_CHECKER_PROCESS_ID} from "../constants/Auth";
import {getCheckedYieldkitOffersService} from "../services/yieldkit";

const getCheckedYKOfferDetails = async (setLoading, setError, setTableData) => {
    setLoading(true);
    try {
        const processId = localStorage.getItem(YK_OFFERS_CHECKER_PROCESS_ID);
        const tableResults = await getCheckedYieldkitOffersService({process_id: processId});
        if(tableResults) setTableData(tableResults.data.data)
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
    }
}

export default getCheckedYKOfferDetails;