import React, {useEffect, useMemo, useState} from "react";
import {Field, Form, Formik} from "formik";
import CustomFileUploader from "../../components/CustomFileUploader";
import {UPLOADER_TYPE_TOP_OFFERS} from "../../constants";
import {checkYieldkitTopOffersService} from "../../services/yieldkit";
import YkStatusCheckerPopup from "../../components/popups/YkStatusCheckerPopup";
import {USER_ID} from "../../constants/Auth";
import CustomDoubledCheckbox from '../../components/CustomDoubledCheckbox';

const defaultColumns = ['countries', 'domain'];

const YkOffersBulkLookup = ({columns, account}) => {
    const [currentValues, setCurrentValues] = useState({
        linksList: ''
    });
    const [formError, setFormError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [fileOffersList, setFileOffersList] = useState([]);
    const [preparedData, setPreparedData] = useState([]);
    const [initialValues, setInitialValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);

    const preparedColumns = useMemo(() => {
        return columns.length > 0 ? columns : defaultColumns;
    }, [columns]);

    const onSubmit = async (values, {setSubmitting}) => {
        try {
            let payload = {
                data: preparedData,
                user_id: localStorage.getItem(USER_ID),
                account: account,
                domainInsteadOfOffer: domainInsteadOfOffer
            };
            setLoading(true);
            const results = await checkYieldkitTopOffersService(payload);
            setFileOffersList([]);
            setPreparedData([]);
            setEmailNotification(true);
        } catch (e) {
            setLoading(false);
            console.log('YkOffersBulkLookup onSubmit error:', e);
        }
    };

    useEffect(() => {
        setFormError(false);
        setErrorMessage('');
        if(!Array.isArray(fileOffersList) || fileOffersList.length === 0) {
            return;
        }

        const keys = fileOffersList[0]
            .map(key => key.replaceAll(' ', '_').toLowerCase())
            .filter(key => preparedColumns.includes(key));

        if(keys.length === 0) {
            setFormError(true);
            setErrorMessage('No value found. Available keys: offer id, offer name, domain, tracking link');
            return;
        }

        let result = [];

        for (let i = 0; i < fileOffersList.length; i++) {
            if(i === 0) {
                continue;
            }
            let obj = {};
            for (let j = 0; j < keys.length; j++) {
                let value = fileOffersList[i][j];
                obj[keys[j]] = value !== undefined ? value : result[result.length - 1][keys[j]];
            }
            result.push(obj);
        }

        setPreparedData(result);
    }, [fileOffersList]);

    const [domainInsteadOfOffer, setDomainInsteadOfOffer] = useState(false)
    const getUniqueParam = e => setDomainInsteadOfOffer(e.target.checked)

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {({errors, touched, values}) => (
                <Form>
                    <p className='yk-checker-text'>YK Offers Bulk Lookup. Upload a file (max 1 MB) here:</p>
                    <Field>
                        {() => (
                            <CustomFileUploader
                                type={UPLOADER_TYPE_TOP_OFFERS}
                                setFileLinksList={setFileOffersList}
                                currentValues={currentValues}
                                setFormError={setFormError}
                            />
                        )}
                    </Field>
                    <div className={'fileDescription'}>
                        <p>The file format should be in the next format:</p>
                        <span>
                            You can use the following column names in the uploaded file: domain, offer name, tracking link, offer id.<br/>
                            It's possible to use only one column or some combinations, like: domain + countries (two separate columns).
                        </span>
                    </div>
                    <div className={'yieldkit-accounts-btn'}>
                        <CustomDoubledCheckbox
                            className={'customCheckbox'}
                            title={'Set Domain instead of Offer Name'}
                            value={domainInsteadOfOffer}
                            getValue={getUniqueParam}
                        />
                    </div>
                    {formError && <span className='yk-checker-form-error'>{errorMessage === '' ? 'Something went wrong.' : errorMessage}</span>}

                    <button className='blue-btn form-submit'
                            type="submit"
                            disabled={formError || !preparedData.length || loading}
                    >
                        Submit
                    </button>

                    <YkStatusCheckerPopup
                        open={emailNotification}
                        handleClose={setEmailNotification}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default YkOffersBulkLookup;
