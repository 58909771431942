import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/loaders/Loader";
import PreTableInfected from "../../components/tables/PreTableInfected";
import CsvDownload from "../../components/buttons/CsvDownload";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import GetKelkooReportData from "../../data-collectors/get-kelkoo-report-data";
import {setDateRangeEmpty} from "../../redux/dataUploader/dataSlice";
import ClearFilters from "../../components/buttons/ClearFilters";
import {USER_ID, USER_ROLE} from "../../constants/Auth";

const KelkooReports = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem(USER_ROLE);
    const userId = localStorage.getItem(USER_ID);
    const dateRange = useSelector((state) => state.dataUploader.dateRangeEmpty);
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [tableRef, setTableRef] = useState(null);

    useEffect(() => {
        if(role !== 'admin') {
            if(userId !== '22') {
                navigate('/not-found');
            }
        }
    }, [userId, pathname])

    useEffect(() => {
        const today = new Date();
        const monthAgo = new Date(new Date().setMonth(today.getMonth() - 1));
        dispatch(setDateRangeEmpty([monthAgo, today]))
    }, [])

    useEffect(() => {
        if(dateRange.filter(el => el === null || el === '').length === 0) {
            const newDates = dateRange.map(date => date.toISOString().split('T')[0]);
            GetKelkooReportData(setLoading, setError, dispatch, setData, setCsvData, newDates);
        }
    }, [dateRange, pathname])

    const onDataTableChanged = params => {
        let newCsvData = [];
        let {rowsToDisplay} = params.api.getModel();
        rowsToDisplay.forEach(el => {
            let obj = { ...el.data };
            newCsvData.push(obj)
        });
        setCsvData(newCsvData);
    };

    const clearFilters = () => {
        tableRef.api.setFilterModel(null);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <>
            <div className="reports-download-block">
                <CsvDownload csvData={csvData.length > 0 ? csvData : data} filename={'kelkoo_report'} separator={','} disabled={isLoading}>
                    Download CSV
                </CsvDownload>
            </div>
            <div className='page-btns-container'>
                <CustomDateRangePicker
                    additionalClass={'full-w'}
                    isClearable={false}
                    type={'report'}
                />
                <ClearFilters clearFilters={clearFilters} />
            </div>
            {data.length > 0 ?
                <PreTableInfected
                    rowData={data}
                    onFilterChanged={onDataTableChanged}
                    onSortChanged={onDataTableChanged}
                    getTableRef={getTableRef}
                /> :
                <h3>No data for this parameters.</h3>
            }
        </>
    )
}

export default KelkooReports;
