import "./dt-create-campaign-popup.scss"
import {ErrorMessage, Field, Form, Formik} from "formik";
import Select from "react-select";
import {DISCOVERY_TOOL_CAMPAIGN_STATUSES} from "../../../constants";

const DTCreateCampaignPopup = ({onConfirm, onCancel}) => {
    const statusesOptions = Object.keys(DISCOVERY_TOOL_CAMPAIGN_STATUSES).reduce((acc, key) => {
        let str = DISCOVERY_TOOL_CAMPAIGN_STATUSES[key].split("")
        str[0] = str[0].toUpperCase()
        str = str.join("")
        acc.push({value: key, label: str})
        return acc;
    }, []);

    const campaignIDRegexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            minHeight: "30px",
            cursor: "pointer",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            padding: "4px 12px",
            cursor: "pointer",
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
    }

    return (
        <div className="dt-create-campaign-popup-container">
            <div className="dt-create-campaign-popup-block">
                <h4>Create New Campaign</h4>
                <Formik
                    initialValues={{
                        campaign_id: "",
                        geo: "",
                        status: statusesOptions[0].value,
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.campaign_id) {
                            errors.campaign_id = "Required";
                        }
                        if (!campaignIDRegexp.test(values.campaign_id)) {
                            errors.campaign_id = "Campaign ID format should be: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
                        }
                        if (!values.geo) {
                            errors.geo = "Required";
                        }
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        onConfirm(values)
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, values, setFieldValue}) => (
                        <Form>
                            <Field
                                type="text"
                                name="campaign_id"
                                className="form-input"
                                placeholder="Campaign ID"
                            />
                            <ErrorMessage
                                name="campaign_id"
                                component="div"
                                className="form-field-error"
                            />
                            <Field
                                type="text"
                                name="geo"
                                className="form-input"
                                placeholder="Geo"
                            />
                            <ErrorMessage
                                name="geo"
                                component="div"
                                className="form-field-error"
                            />
                            <Select
                                className={"form-select"}
                                styles={selectStyles}
                                options={statusesOptions}
                                isSearchable={false}
                                defaultValue={statusesOptions[0]}
                                name={"status"}
                                onChange={(option) => {
                                    setFieldValue("status", option["value"])
                                }}
                            />
                            <div className="config-btns-block">
                                <button
                                    type="submit"
                                    className="update-btn"
                                    disabled={isSubmitting}
                                >Yes
                                </button>
                                <button className="discard-btn" onClick={onCancel}>No</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default DTCreateCampaignPopup;