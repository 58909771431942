import React, {useState, useCallback, useRef, useEffect} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {useSelector} from "react-redux";
import {PAGINATION_PAGE_SIZE_ARR} from "../../../constants";
import CustomSelect from "../../CustomSelect";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './table.scss';

const Table = ({
                   rowData,
                   defaultColDef,
                   columnDefs,
                   onGridReady,
                   onFilterChanged,
                   onSortChanged,
                   headerClassCol = false,
                   smallTable = false,
                   getTableRef,
                   filterStatus = false,
                   removeActiveFilter,
                   rowSelection,
                   onSelectionChanged
               }) => {
    const tableRef = useRef(null);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const [tagsArr, setTagsArr] = useState([]);
    const [activeTagsArr, setActiveTags] = useState([]);

    useEffect(() => {
        if (!smallTable && getTableRef) getTableRef(tableRef.current);
    }, [tableRef])

    useEffect(() => {
        if(filterStatus) {
            setActiveTags(['offer_status']);
        }
    }, [filterStatus])

    const cellClickedListener = useCallback(e => {
        navigator.clipboard.writeText(e.value);
    }, []);

    const addFilterTags = params => {
        let arr = [];
        let uniqueArray;
        const colArr = params.columnApi.columnModel.gridColumns;
        colArr.forEach(el => {
            if (el.filterActive) {
                arr.push(el.colId);
            }
        })
        uniqueArray = [...new Set(arr)];
        setTagsArr(uniqueArray);
    }

    const removeFilter = e => {
        const filterInstance = tableRef.current.api.getFilterInstance(e.target.id);
        filterInstance.setModel(null);
        tableRef.current.api.onFilterChanged();
    }

    const filterEvent = params => {
        addFilterTags(params);
        return onFilterChanged ? onFilterChanged(params) : null;
    }

    const paginationNumberFormatter = useCallback(params => {
        return params.value.toLocaleString();
    }, []);

    const onPageSizeChanged = useCallback(value => {
        if (tableRef.current.api && !isNaN(Number(value))) tableRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    return (
        <div className={`ag-theme-alpine${smallTable ? ' small-table' : ''}${headerClassCol ? ' col-names-break' : filterStatus && activeTagsArr.length > 0 ? ' shorter-table-wrap-with-tag' : ''}`}
             style={smallTable ? {
                 width: `${!menuStatus ? 'calc(100vw - 9.5rem)' : 'calc(100vw - 21rem)'}`,
                 marginTop: "3rem"
             } : {
                 width: `${!menuStatus ? 'calc(100vw - 9.5rem)' : 'calc(100vw - 21rem)'}`,
                 maxHeight: "calc(100vh - 15.2rem)",
                 "transition": 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
             }}>

            <div className="filter-tags-block">
                {filterStatus && activeTagsArr.length > 0 ?
                    <ul className='filter-tags-container filter-tags-active-container'>
                        {activeTagsArr.map((tag, i) => {
                            return (
                                <li key={i} className='filter-tags-active'>
                                    {tag}
                                    <button id={tag} onClick={removeActiveFilter}>
                                        <img id={tag} src={`${process.env.PUBLIC_URL}/images/close-icon.svg`} alt="close"/>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                    : null
                }

                {tagsArr.length > 0 ?
                    <ul className='filter-tags-container'>
                        {tagsArr.map((tag, i) => {
                            return (
                                <li key={i}>
                                    {tag}
                                    <button id={tag} onClick={removeFilter}>
                                        <img id={tag} src={`${process.env.PUBLIC_URL}/images/close-icon.svg`} alt="close"/>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                    : null
                }
            </div>

            <>
                <div className='table-inner-container'>
                    <AgGridReact
                        ref={tableRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onCellClicked={cellClickedListener}
                        onGridReady={onGridReady}
                        onFilterChanged={params => filterEvent(params)}
                        onSortChanged={onSortChanged ? params => onSortChanged(params) : null}
                        tooltipShowDelay={1000}
                        tooltipHideDelay={1000}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                        animateRows={true}
                        pagination={!smallTable}
                        enableFilter={true}
                        enableSorting={true}
                        rowSelection={rowSelection ? rowSelection : false}
                        rowMultiSelectWithClick={!!rowSelection}
                        suppressRowClickSelection={!!rowSelection}
                        onSelectionChanged={onSelectionChanged ? onSelectionChanged : false}
                    />
                    {rowData.length >= 100 ?
                        <div className='pagination-select'>
                            <CustomSelect data={PAGINATION_PAGE_SIZE_ARR} onChange={onPageSizeChanged} pagination={true}/>
                        </div> : null
                    }
                </div>
            </>
        </div>
    );
};


export default Table;