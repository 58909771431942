import React from "react";
import "../buttons.scss";
import './create-new-item-btn.scss';

const CreateNewItemBtn = ({onClick}) => {
    return (
        <button className="item-create-button blue-btn" onClick={onClick}>+ Create new</button>
    )
}

export default CreateNewItemBtn;