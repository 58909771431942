import {Formik, Form, Field} from 'formik';
import './reset-pass-popup.scss'

const ResetPassPopup = ({setStatus, onResetPassword, formError, oldPasswordValue, newPasswordValue, setNewPassword, setOldPassword}) => {
    const onClosePopup = () => {
        setStatus(false);
    }

    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^(?=.*[A-Za-z])[A-Za-z0-9@#$%^&+=!]{6,16}$/i.test(value)) {
            error = 'Please enter between 6 and 16 characters using Latin characters';
        }
        return error;
    }


    return (
        <div className='reset-pass-popup'>
            <div className="content-wrap">
                <h3>Reset Password</h3>

                <Formik
                    initialValues={{oldPassword: '', newPassword: ''}}
                    onSubmit={onResetPassword}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <div className="inputs-wrap">
                                    <div className="input-wrap">
                                        <label>Old password</label>
                                        <Field
                                            type="password"
                                            name='oldPassword'
                                            placeholder='Old password'
                                            className={errors.oldPassword ? 'input-error' : ''}
                                            validate={() => validatePassword(oldPasswordValue)}
                                            value={oldPasswordValue}
                                            onChange={({target}) => setOldPassword(target.value)}
                                        />
                                        { errors.oldPassword && touched.oldPassword &&
                                        <div className='error-text login-error-text'>{errors.oldPassword}</div>}
                                    </div>

                                    <div className="input-wrap">
                                        <label>New password</label>
                                        <Field
                                            type="password"
                                            name='newPassword'
                                            placeholder='New password'
                                            className={errors.newPassword ? 'input-error' : ''}
                                            validate={() => validatePassword(newPasswordValue)}
                                            value={newPasswordValue}
                                            onChange={({target}) => setNewPassword(target.value)}
                                        />
                                        {errors.newPassword && touched.oldPassword && <div className='error-text login-error-text'>{errors.newPassword}</div>}
                                    </div>
                                </div>

                                <div className="btn-wrap">
                                    <button className="reset-btn" type="submit">Reset Password</button>
                                    <button className="close-btn" type="button" onClick={onClosePopup}>Close</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassPopup;