import internalFetch from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

export const addNewUserService = async data => await internalFetch.post('/register/', {"data": data, "token": getAuthorizationHeader()});
export const isAuthService = async (token) => await internalFetch.post('/auth/', {"token": token});
export const logInService = async (email, password) => await internalFetch.post('/login/', {"email": email, "password": password});
export const logOutService = async (token) => await internalFetch.post('/logout/', {"token": token});
export const getProjectConnectionsService = async () => await internalFetch.get('/project-connection');
export const getAccountsInformationService = async project => await internalFetch.get(`/accounts?project=${project}`);


