import {logOut} from "../redux/dataUploader/dataSlice";
import {getApprovalRateCheckerService} from "../services/awin";
import prepareCSVData from "../helpers/prepare-csv-data";

const ApprovalRateCheckerData = async (setLoading, setError, setErrorText, dispatch, setData, setCsvData) => {
    setLoading(true);
    try {
        const results = await getApprovalRateCheckerService();
        if(!results || results.data.error) {
            setError(true);
            setErrorText(results.data.error);
        } else {
            const data = results.data.data;
            setData(data);
            setCsvData(prepareCSVData(data));
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default ApprovalRateCheckerData;
