import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import UpdateConfigForm from "../../forms/UpdateConfigForm";
import groupVoluumAlerts from "../../../helpers/group-voluum-alerts";
import './add-item.scss'

const NewItemPopupWithConfig = ({configArr, initialValues, closePopup, onCountedNames}) => {
    const hiddenColumns = useSelector((state) => state.dataUploader.hiddenColumns);
    const [allValues, setAllValues] = useState([])
    const [columnsArr, setColumnsArr] = useState({
        left: [],
        right: []
    });

    useEffect(() => {
        const result = configArr.filter(el => hiddenColumns.includes(el.name) ? null : el);
        setAllValues(result);
        const arr = groupVoluumAlerts(result);
        setColumnsArr({
            left: arr.slice(0, 2),
            right: arr.slice(2, arr.length)
        });
    }, [configArr, hiddenColumns]);

    return (
        <div className='add-item-popup-container'>
            <div className="add-item-popup-block">
                <UpdateConfigForm
                    columnsArr={columnsArr}
                    initialValues={initialValues}
                    closePopup={closePopup}
                    onCountedNames={onCountedNames}
                    allValues={allValues}
                />
            </div>
        </div>
    )
}

export default NewItemPopupWithConfig;
