import React from 'react';
import {BrowserRouter} from "react-router-dom";
import Router from "../routes/Router";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

const App = () => {
    return (
        <BrowserRouter>
            <div className="main-container">
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Router />
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    )
}

export default App;
