import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PreTableAlerts from "../tables/PreTableAlerts";

import './custom-react-tabs-mui.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export default function CustomTabsMUI({titles, data, additionalData, getTableRef}) {
    const [value, setValue] = React.useState(0);
    const tabIndexStorage = sessionStorage.getItem('tabIndexAlerts');

    React.useEffect(() => {
        if(!tabIndexStorage) sessionStorage.setItem('tabIndexAlerts', value);
    }, [])

    const handleChange = (event, newValue) => {
        sessionStorage.setItem('tabIndexAlerts', newValue);
        setValue(newValue);
    };

    const checkLastRow = arr => {
        if (arr.length > 0 && Object.keys(arr[0])[0] !== Object.keys(arr[arr.length - 1])[0]) return arr.slice(0, -1);
        return arr;
    }

    return (
        <Box style={{ bgcolor: 'transparent', width: 'calc(100% - 1rem)', padding: '0 ' }}>
            <AppBar position="static" style={{ boxShadow: 'none' }}>
                <Tabs
                    value={Number(tabIndexStorage)}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    aria-label="full width tabs example"
                    style={{ backgroundColor: '#f8f9f9' }}
                >
                    {titles.map((tab, i) => {
                        return (<Tab key={i} label={tab} />)
                    })}
                </Tabs>
            </AppBar>
            {data.map((tableData, i) => {
                return (<TabPanel value={Number(tabIndexStorage)} index={i} key={i}>
                    {tableData && tableData.length > 0 && typeof tableData[0] !== "string" ?
                        <PreTableAlerts
                            rowData={checkLastRow(tableData)}
                            name={titles[i]}
                            alertNum={titles[i] && titles[i].length > 0 ? i + 1 : 1}
                            getTableRef={getTableRef}
                        /> :
                        typeof tableData[0] === "string" && tableData[0] !== '' ?
                            <h3>{tableData[0]}</h3> :
                            <h3 className='no-data-title'>No data for this date.</h3>
                    }

                    {additionalData && additionalData.length > 0 && (additionalData[i] && additionalData[i].length > 0) && tableData && tableData.length > 0 ?
                        <PreTableAlerts
                            rowData={additionalData[i]}
                            smallTable={true}
                            name={titles[i]}
                            alertNum={titles[i] && titles[i].length > 0 ? i + 1 : 1}
                            getTableRef={getTableRef}
                        /> : null}
                </TabPanel>)
            })}
        </Box>
    );
}
