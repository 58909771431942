import {Field} from "formik";

const CustomInput = ({name, type, validate, currentValue, setFieldValue, errors, touched, additionalClass, setSettingsStatus}) => {
    return (
        <div className={`input-wrap ${additionalClass ? additionalClass : ''}${errors && touched ? ' error-input' : ''}`}>
            <div className="input-block">
                <label htmlFor="name" className="label-name">
                    <span className="content-name">{name.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}</span>
                </label>
                <Field
                    id={name}
                    name={name}
                    validate={validate}
                    type={typeof type === 'string' ? 'text' : type}
                    className={currentValue !== '' ? 'filled' : ''}
                    onChange={e => {
                        setFieldValue(name, e.target.value)
                        if(setSettingsStatus) setSettingsStatus(e.target.value !== currentValue);
                    }}
                />
            </div>
            {errors && touched && <div className='error-text'>{errors}</div>}
        </div>
    )
}

export default CustomInput;
