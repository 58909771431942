import React, {useState} from "react";
import {useNavigate} from "react-router";
import './show-more-btn.scss'

const ShowMoreBtn = ({params}) => {
    const navigate = useNavigate();
    const [btnColor, setBtnColor] = useState(false);

    const onClick = params => {
        const {id, deactivation_datetime, offerId, pathId, campaignId, flowId, voluum_offer_name} = params.data;
        navigate('/changed-campaigns-details/', {
            state: {
                id: id,
                date: deactivation_datetime,
                offerId: offerId,
                pathId: pathId,
                campaignId: campaignId,
                flowId: flowId,
                voluum_offer_name: voluum_offer_name
            }
        });
    }

    return (
        <div className='btns-container'>
            <button className='show-more-btn' onClick={() => onClick(params)} onMouseEnter={() => setBtnColor(true)}
                    onMouseLeave={() => setBtnColor(false)}>
                <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 7.5L1 14" stroke={btnColor ? "#FFF" : "#467FF7"} strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
    )
}

export default ShowMoreBtn;