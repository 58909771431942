import React from 'react';
import Loader from "../../loaders/Loader";

const FormsLayout = ({isLoading, formTitle, children}) =>  (
    <div className='form-wrap form-login-wrap' style={{
        background: `linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)) center center / cover no-repeat, url(${process.env.PUBLIC_URL}/images/login-bg.webp) transparent`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }}>
        {isLoading && <div className='form-loading'><Loader/></div>}
        <div className="form-inner-wrap">
            <div className="title-wrap">
                <h1>{formTitle}</h1>
            </div>
            {children}
        </div>
    </div>
);

export default FormsLayout;