import {useState} from "react";
import Select from "react-select";
import ClearFilters from "../buttons/ClearFilters";
import {InputMask} from '@react-input/mask';
import "./table-filter.scss";

const DiscoveryToolTableFilter = ({
                                      orderByOptions = [],
                                      orderDirectionOptions = [],
                                      searchByColumnOptions = [],
                                      filterConditionOptions = [],
                                      selectedFilters,
                                      changeSelectedFilters,
                                      clearSearchFilters,
                                  }) => {
    const [searchValue, setSearchValue] = useState("");
    const [selectedColumnToSearch, setSelectedColumnToSearch] = useState(null)
    const [selectedSearchCondition, setSelectedSearchCondition] = useState({value: 'like', label: 'Contains'})

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "12px",
            lineHeight: "1.2",
            minHeight: "26px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
        clearIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
        multiValueLabel: (baseStyles, state) => ({
            ...baseStyles,
            padding: "1px",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "12px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }

    const handleTriggerSearchByColumn = () => {
        let temp = [...selectedFilters?.columnsToSearch]
        let tempSearchValue = searchValue

        // Operator "in" takes an array instead of a string
        if (selectedSearchCondition.value === "in") {
            tempSearchValue = searchValue.split(",")
            // Remove empty spaces
            for (let i = 0; i < tempSearchValue.length; i++) {
                tempSearchValue[i] = tempSearchValue[i].trim()
            }
        }

        // Check if column already exists in the filter
        if (temp.some(item => item.columnName === selectedColumnToSearch.value)) {
            let idx = temp.findIndex(item => item.columnName === selectedColumnToSearch.value)
            temp[idx].searchValue = tempSearchValue
            temp[idx].condition = selectedSearchCondition.value
            changeSelectedFilters({
                ...selectedFilters,
                columnsToSearch: temp,
            })
            return
        }

        temp.push({
            columnName: selectedColumnToSearch.value,
            condition: selectedSearchCondition.value,
            searchValue: tempSearchValue
        })
        changeSelectedFilters({
            ...selectedFilters,
            columnsToSearch: temp,
        })
    }

    const handleRemoveColumnFromSearch = (column) => {
        let temp = selectedFilters?.columnsToSearch.filter(item => item.columnName !== column)
        changeSelectedFilters({
            ...selectedFilters,
            columnsToSearch: temp,
        })
    }

    return (
        <div className="data-table-filters-wrap">
            <div className="data-table-order-wrap">
                {orderByOptions.length > 0 && (
                    <Select
                        className={"sort-select order-by-select"}
                        styles={selectStyles}
                        options={orderByOptions}
                        defaultValue={selectedFilters.orderBy}
                        isSearchable={false}
                        placeholder="Select option:"
                        name={"order_by"}
                        onChange={(option) => {
                            changeSelectedFilters({
                                ...selectedFilters,
                                orderBy: option,
                            })
                        }}
                    />
                )}

                {orderDirectionOptions.length > 0 && (
                    <Select
                        className={"sort-select order-direction-select"}
                        styles={selectStyles}
                        options={orderDirectionOptions}
                        defaultValue={selectedFilters.orderDirection}
                        isSearchable={false}
                        name={"order_direction"}
                        onChange={(option) => {
                            changeSelectedFilters({
                                ...selectedFilters,
                                orderDirection: option,
                            })
                        }}
                    />
                )}
            </div>

            <div className="data-table-filter-wrap">
                {searchByColumnOptions.length > 0 && (
                    <>
                        <Select
                            className={"sort-select search-in-column-select"}
                            styles={selectStyles}
                            options={searchByColumnOptions}
                            defaultValue={selectedFilters["columnToSearch"]}
                            isSearchable={false}
                            isClearable={true}
                            name={"search_in_column"}
                            onChange={(option) => {
                                setSelectedColumnToSearch(option)
                                setSearchValue("")
                            }}
                        />

                        <Select
                            className={"sort-select"}
                            styles={selectStyles}
                            options={filterConditionOptions}
                            defaultValue={selectedSearchCondition}
                            isSearchable={false}
                            name={"filter_conditions"}
                            onChange={(options) => {
                                setSelectedSearchCondition(options)
                            }}
                        />

                        {(selectedColumnToSearch?.value === "created_at" || selectedColumnToSearch?.value === "updated_at") && (
                            <InputMask
                                mask="yyyy-mm-dd hh:mm:ss"
                                className="sort-input"
                                replacement={{d: /\d/, m: /\d/, y: /\d/, h: /\d/, s: /\d/,}}
                                showMask
                                separate
                                onChange={e => {
                                    setSearchValue(e.target.value)
                                }}
                            />

                        )}

                        {(selectedColumnToSearch?.value !== "created_at" && selectedColumnToSearch?.value !== "updated_at") && (
                            <input
                                type="text"
                                className="sort-input"
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                }}
                                placeholder="Search..."
                            />
                        )}

                        <div className="data-table-search-btns-wrap">
                            <ClearFilters
                                text={"Clear"}
                                clearFilters={clearSearchFilters}
                            />
                            <button
                                className={"blue-btn"}
                                onClick={handleTriggerSearchByColumn}
                                disabled={!Boolean(selectedColumnToSearch) || !Boolean(searchValue)}
                            >
                                Apply
                            </button>
                        </div>
                    </>
                )}
            </div>

            <ul className={"filter-tags-container discovery-tool-table-filter-tags"} style={{width: "100%"}}>
                {selectedFilters.columnsToSearch.map(item => {
                    const label = filterConditionOptions.find(option => option.value === item.condition).label
                    return (
                        <li key={`${item.columnName}-${item.condition}`} className={"filter-tags"}>
                            {item.columnName.replaceAll("_", " ")}
                            <strong> {label.toLowerCase()}: </strong> {Array.isArray(item.searchValue) ? item.searchValue.join(", ") : item.searchValue}
                            <button
                                onClick={() => handleRemoveColumnFromSearch(item.columnName)}
                            ><img src="/images/close-icon.svg" alt="close"/></button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default DiscoveryToolTableFilter;