import createSagaMiddleware from 'redux-saga'
import {
    configureStore,
} from '@reduxjs/toolkit';
import dataSlice from './dataUploader/dataSlice';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
    reducer: {
        dataUploader: dataSlice,
    },
    middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga);
