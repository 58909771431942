import React, {useEffect} from "react";
import {Outlet, Navigate, useLocation, Route} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {AUTH_TOKEN, USER_ROLE} from "../../constants/Auth";
import {getUserInfo, setCurrentPath} from '../../redux/dataUploader/dataSlice';
import routeConfig from "../index";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import NotFound from "../../pages/NotFound";

const AuthWrapper = () => {
    const currentUserRole = localStorage.getItem(USER_ROLE);
    const token = useSelector((state) => state.dataUploader.token);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const localToken = localStorage.getItem(AUTH_TOKEN);
    //TODO получить второе значение pathname что-бы понять что это за страница

    useEffect(() => {
        if (pathname !== '/reset-password') {
            if (pathname !== '/login/') {
                dispatch(setCurrentPath(pathname))
            } else if (token && pathname !== '/login/') {
                dispatch(getUserInfo())
            }
        }
    }, [token, localToken, dispatch, pathname])

    if (pathname !== '/reset-password') {
        if (!token && pathname !== '/login/') {
            return (
                <Navigate to="/login/"/>
            )
        }
    }

    const handleProtectedRoute = () => {
        for (let i = 0; i < routeConfig.length; i++) {
            if (pathname === routeConfig[i].path) {
                if (!routeConfig[i]?.role || routeConfig[i]?.role.includes(currentUserRole)) {
                    return (
                        <>
                            {pathname !== '/login/' && pathname !== '/reset-password' ?
                                <>
                                    <Header/>
                                    <Sidebar/>
                                </>
                                : null
                            }
                            <div className={`container page-container${!menuStatus ? ' collapsed-menu' : ''}`}>
                                <Outlet/>
                            </div>
                        </>
                    )
                } else if (!routeConfig[i]?.role.includes(currentUserRole)) {
                    return <Navigate to={"/"}/>
                }
            }
        }

        return (
            <>
                <>
                    <Header/>
                    <Sidebar/>
                </>
                <div className={`container page-container${!menuStatus ? ' collapsed-menu' : ''}`}>
                    <NotFound/>
                </div>
            </>
        )
    }

    return handleProtectedRoute();
};
export default AuthWrapper;
