import React from 'react';
import "./error-popup.scss";

const ErrorPopup = ({popupText, popupIsActive}) => {
    return (
        <div className={`error-popup ${popupIsActive ? 'popup-active' : ''}`}>
            <span>
                {popupText()}
            </span>
        </div>
    );
}

export default ErrorPopup;