import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {accountSelected} from "../../redux/dataUploader/dataSlice";

import './custom-select.scss';

const CustomSelect = ({data, curValue, setVoluumId, allOption = false, setSettingsStatus}) => {
    const dispatch = useDispatch();
    const curAccount = useSelector(state => state.dataUploader.accountSelected);
    const [optionSelected, setOptionSelected] = useState(curAccount);
    const [options, setOptions] = useState(data);
    const [showOptionList, setShowOptionList] = useState(false);

    useEffect(() => {
        if(data.length > 0) {
            if(allOption && !curValue && curValue === '') {
                setOptionSelected('all');
                setVoluumId('all');
            } else if(curValue && curValue !== optionSelected) {
                const curAccArr = data.filter(el => el['voluum_id'] === curValue);
                curAccArr.length > 0 ? setOptionSelected(curAccArr[0].project) : setOptionSelected('all');
                setVoluumId(curValue);
            } else if(optionSelected !== '') {
                setOptionSelected(optionSelected);
                setVoluumId(optionSelected);
            }
        }
    }, [curValue]);

    useEffect(() => {
        if(data.length > 0 && optionSelected === '') {
            const curAccArr = data.filter(el => el['voluum_id'] === curValue);
            if(curAccArr[0]) setOptionSelected(curAccArr[0].project);
            setVoluumId(curValue);
        }
        const newArr = data.filter(el => {
            return el.project !== optionSelected;
        });
        if(allOption) newArr.unshift({project: 'all', voluum_id: 'all'});
        setOptions(newArr);
    }, [data, optionSelected, dispatch]);

    const handleListDisplay = () => {
        setShowOptionList(!showOptionList);
    };

    const handleOptionClick = e => {
        setShowOptionList(false);
        setOptionSelected(e.target.getAttribute("data-name"));
        setVoluumId(e.target.getAttribute("data-id"));
        dispatch(accountSelected(e.target.getAttribute("data-name")));
        if(setSettingsStatus && curValue !== '') {
            setSettingsStatus(e.target.getAttribute("data-id") !== curValue);
        } else if(setSettingsStatus) {
            setSettingsStatus(e.target.getAttribute("data-name") !== optionSelected)
        }
    };

    return (
        <div className={`transparent-btn category-dropdown ${showOptionList ? 'active' : ''}`} onClick={handleListDisplay}>
            <div className={`selected-text ${showOptionList ? 'active' : ''}`}>
                {optionSelected}
            </div>
            {showOptionList && (
                <ul className='custom-select-option-container'>
                    <li
                        className="custom-select-option"
                        data-name={optionSelected}
                        hidden
                    >
                        {optionSelected}
                    </li>
                    {options.map((option, i) => {
                        return (
                            <li
                                className="custom-select-option"
                                data-name={option.project}
                                data-id={option['voluum_id']}
                                key={i}
                                onClick={handleOptionClick}
                            >
                                {option.project}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
