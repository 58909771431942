import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import Loader from "../../components/loaders/Loader";
import OffersCheckerForm from "../../components/forms/OffersCheckerForm";
import getCheckedYKOfferDetails from "../../data-collectors/get-checked-yk-offer-details";
import PreTableYieldkitOffersListData from "../../components/tables/PreTableYieldkitOffersListData";
import ClearResults from "../../components/buttons/ClearResults";
import CsvDownload from "../../components/buttons/CsvDownload";
import {checkYieldkitOffersService} from "../../services/yieldkit";
import {UPLOADER_TYPE_LINKS} from "../../constants";
import {USER_ID, YK_OFFERS_CHECKER_CHECKER_SHOW, YK_OFFERS_CHECKER_PROCESS_ID} from "../../constants/Auth";

import './yieldkit-status-checker.scss';

const YieldkitStatusChecker = () => {
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        linksList: ''
    });
    const [currentValues, setCurrentValues] = useState({
        linksList: ''
    });
    const [emailNotification, setEmailNotification] = useState(false);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const userId = localStorage.getItem(USER_ID);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [listLengthError, setlistLengthError] = useState(false);
    const [tableDataStatus, setTableDataStatus] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [countdown, setCountdown] = useState(0);
    const [fileLinksList, setFileLinksList] = useState([]);

    useEffect(() => {
        getCheckedYKOfferDetails(setLoading, setError, setTableData);
    }, [tableDataStatus])

    useEffect(() => {
        if(countdown > 0) {
            setTimeout(() => {
                setCountdown(countdown - 1)
            }, 1000);
        }
    }, [countdown])

    useEffect(() => {
        if(localStorage.getItem(YK_OFFERS_CHECKER_CHECKER_SHOW) === 'true' && tableData && tableData.filter(el => el['process_status'] === 'new' || el['process_status'] === 'in progress').length > 0) {
            setCountdown(5);
            setTimeout(() => {
                setTableDataStatus(!tableDataStatus);
                setCountdown(0);
            }, 5000);
        } else {
            setFileLinksList([]);
            setCurrentValues(previousState => {
                return {...previousState, linksList: ''}
            });
        }
    }, [tableData])

    const onSendLinksList = async value => {
        const {linksList} = value;
        const linksArr = linksList
            .split(/[,\n]/)
            .filter(link => link !== '')
            .map(link => {
                    return {
                        url: link,
                        jsonData: ''
                    }
                }
            )

        if(linksArr.length > 250) {
            setlistLengthError(true);
        } else {
            setLoading(true);
            const data = {
                user_id: userId,
                linksList: formError || linksList === '' ? fileLinksList : linksArr,
                email_notification: !!fileLinksList.length
            }

            try {
                const results = await checkYieldkitOffersService(data);
                if(data.email_notification) setEmailNotification(true);
                const processId = results['data']['process_id'];
                localStorage.setItem(YK_OFFERS_CHECKER_PROCESS_ID, processId);
                if(fileLinksList.length === 0) {
                    localStorage.setItem(YK_OFFERS_CHECKER_CHECKER_SHOW, 'true');
                } else {
                    localStorage.setItem(YK_OFFERS_CHECKER_CHECKER_SHOW, 'false');
                }
                setTableDataStatus(!tableDataStatus);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }
    }

    const validateLinks = value => {
        setlistLengthError(false);
        let error, valueArr;

        valueArr = value.split(/[,\n]/);
        if (!value && value === '' && fileLinksList.length === 0) {
            error = 'Required';
        } if (value !== '' && fileLinksList.length !== 0) {
            setFormError(true);
        } else if (value && (valueArr.length === 0 || !valueArr.filter(el => el.length > 0).every(el => /^http(?!.+http(.+http))/i.test(el)))) {
            error = 'Links must be divided by comma or new line';
        } else if(value && valueArr.filter(el => /^http/i.test(el)).length === 0) {
            error = 'Please provide the list of valid links';
        } else {
            setCurrentValues(previousState => {
                return {...previousState, linksList: value}
            });
        }
        return error;
    }

    const clearResults = () => {
        setTableData([]);
        localStorage.removeItem(YK_OFFERS_CHECKER_PROCESS_ID);
        localStorage.removeItem(YK_OFFERS_CHECKER_CHECKER_SHOW);
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }
    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <div className='yk-checker-page'>
            <p className='yk-checker-text'>Please insert the links you want to check (<b>maximum 250</b>), divided by comma or new line.</p>
            <p className='yk-checker-text'>If you need to check more than <b>250 links</b>, please upload a file.</p>
            <div className='form-wrap textarea-checker-form-wrap'>
                <OffersCheckerForm
                    type={UPLOADER_TYPE_LINKS}
                    initialValues={initialValues}
                    currentValues={currentValues}
                    emailNotification={emailNotification}
                    setEmailNotification={setEmailNotification}
                    validate={validateLinks}
                    onSubmit={onSendLinksList}
                    isLoading={isLoading}
                    fileLinksList={fileLinksList}
                    setFileLinksList={setFileLinksList}
                    listLengthError={listLengthError}
                    formError={formError}
                    setFormError={setFormError}
                />
                {formError ? <span className='yk-checker-form-error'>The file is too large!</span> : null}
            </div>
            {localStorage.getItem(YK_OFFERS_CHECKER_CHECKER_SHOW) === 'true' ?
                <>
                    {countdown > 0 ? <p className='yk-checker-countdown'>Data will be refreshed in {countdown} seconds</p> : null}
                    {tableData && tableData.length > 0 ?
                        <>
                            <div className={`yk-checker-btns-container${menuStatus ? '' : ' collapse-menu'}`}>
                                <CsvDownload csvData={tableData} separator=',' children='Download Results' filename='yk_offers_status' additionalClass='all-results' />
                                <ClearResults clearResults={clearResults} />
                            </div>
                            <PreTableYieldkitOffersListData rowData={tableData}/>
                        </> :
                        null
                    }
                </> :
                null
            }
        </div>
    )
}

export default YieldkitStatusChecker;