import {CompactTable} from "@table-library/react-table-library/compact";
import {useTheme} from "@table-library/react-table-library/theme";
import {DEFAULT_OPTIONS, getTheme} from "@table-library/react-table-library/chakra-ui";
import "./data-table.scss";
import {useRowSelect} from "@table-library/react-table-library/select";

const DataTable = ({
                       data = [],
                       columnDefs = [],
                       maxHeight = "550px",
                       isSelectableRows = false,
                       onSelectChange,
                   }) => {
    const columnWidths = columnDefs.map(item => item["width"]).join(" ");
    const chakraTheme = getTheme(DEFAULT_OPTIONS);
    const theme = useTheme([
        chakraTheme,
        {
            Table: `
                --data-table-library_grid-template-columns: ${isSelectableRows ? "70px" : ""} ${columnWidths};
                max-height: ${maxHeight};
                height: auto;
              `,
        },
    ]);

    const tableData = {nodes: data};

    const select = useRowSelect(tableData, {
        onChange: (action, state) => {
            onSelectChange([...new Set(state["ids"])]) // "new Set" is to remove duplicates
        },
    });

    return (
        <div className="data-table-wrap">
            {tableData.nodes.length > 0 && (
                <CompactTable
                    columns={columnDefs}
                    data={tableData}
                    theme={theme}
                    select={isSelectableRows ? select : null}
                    layout={{
                        custom: true,
                        horizontalScroll: true,
                        fixedHeader: true,
                    }}
                />
            )}
        </div>
    )
}

export default DataTable;
