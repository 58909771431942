import fetchData from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const resetPassword = async (newPassword, resetPassToken, email) => {
    const url = `/reset-password`;
    const res = await fetchData.post(
        url,
        {
            "newPassword": newPassword,
            "email": email,
            "resetPassToken": resetPassToken
        },
    );
    return res;
}

export default resetPassword;