import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import CustomDatalist from "../../CustomDatalist";
import CsvDownload from "../../buttons/CsvDownload";
import {setIsVoluumCSVdifferentColumn} from "../../../redux/dataUploader/dataSlice";

const CSVinVoluumFormatPopup = ({csvData, closePopup}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        country: '',
        pagesFrom: 1,
        pagesTo: csvData.length,
        workspaceId: '',
        header: true,
        diffCol: false,
        includeMultipleGeos: false,
        specGeoForMultiple: false,
        blogrefNoref: '',
    });
    const [currentValues, setCurrentValues] = useState({
        country: '',
        pagesFrom: 1,
        pagesTo: csvData.length,
        workspaceId: '',
        header: true,
        diffCol: false,
        includeMultipleGeos: false,
        specGeoForMultiple: false,
        blogrefNoref: '',
    })
    const [filterCountryData, setFilterCountryData] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [country, setCountry] = useState('');
    const [formError, setFormError] = useState(false);
    const countrySelectRef = useRef();
    const specGeoForMultipleRef = useRef();

    useEffect(() => {
        setCurrentValues((previousState) => {
            return {
                ...previousState,
                country: country,
                pagesTo: country === '' ? csvData.length : filterCountryData.length,
            };
        });
    }, [country])

    useEffect(() => {
        const countryCodes = {}
        let countryCodeExist = false;
        if (csvData && csvData.length > 0) csvData.forEach(el => {
            const countries = el['3. Country code'];
            const splittedCountry = countries.split(',');
            if (!currentValues.includeMultipleGeos && splittedCountry.length > 1) {
                splittedCountry.splice(0, splittedCountry.length);
            }
            splittedCountry.forEach(code => {
                countryCodes[code] = true;
                if (code === country) {
                    countryCodeExist = true;
                }
            })
        });

        const countryOptions = Object.keys(countryCodes).map(countryCode => ({
            value: countryCode,
            label: countryCode
        }));
        if (country && !countryCodeExist) {
            countrySelectRef.current.clearValue();
        } else {
            countrySelectRef.current.refreshFilterData();
        }
        setCountriesList(countryOptions);
        if (currentValues.specGeoForMultiple === true && currentValues.includeMultipleGeos === false) {
            specGeoForMultipleRef.current.click();
        }
    }, [csvData, currentValues.includeMultipleGeos])

    const validateCountry = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, country: value}
        });
    }

    const validatePagesFrom = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, pagesFrom: value}
        });

        let error;
        if (value < 1 || value > currentValues.pagesTo || (filterCountryData.length !== 0 && value > filterCountryData.length) || (filterCountryData.length === 0 && value > csvData.length)) {
            setFormError(true);
            error = 'Please choose the right start of rows';
        } else {
            setFormError(false);
        }
        return error;
    }

    const validatePagesTo = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, pagesTo: value}
        });

        let error;
        if (value < 1 || (filterCountryData.length === 0 && currentValues.country !== '' && value > csvData.length) || (filterCountryData.length !== 0 && value > filterCountryData.length)) {
            error = 'Please choose the right end of rows';
            setFormError(true);
        }
        return error;
    }

    const validateHeader = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, header: value}
        });
    }

    const validateWorkspaceId = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, workspaceId: value}
        });
    }
    const validateBlogrefNoref = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, blogrefNoref: value}
        });
        let error;
        const expression = /^https:\/\/(?:(?!www\.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#&//=]*)\?\b([-a-zA-Z0-9()@:%_{}\+.~#&//=]*)\=$/;
        const regex = new RegExp(expression);
        if (value && !value.match(regex)) {
            error = 'Value have to started with "https://", no "www.", include "?", end with "="';
            setFormError(true);
        }
        return error;
    }
    const validateDiffColumn = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, diffCol: value}
        });
        dispatch(setIsVoluumCSVdifferentColumn(value));
    }
    const validateIncludeMultipleGeos = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, includeMultipleGeos: value}
        });
    }
    const validateSpecGeoForMultiple = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, specGeoForMultiple: value}
        });
    }

    return (
        <div className='add-item-popup-container voluum-popup-container'>
            <div className="add-item-popup-block">
                <div className='form-wrap'>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}>
                        {({errors, touched, values, setFieldValue}) => (
                            <Form>
                                <div className="title-wrap">
                                    <h1>Before Uploading CSV file</h1>
                                    <div className="close-btn" onClick={closePopup}>
                                        <img src={`${process.env.PUBLIC_URL}/images/close-btn.svg`} alt="close popup"/>
                                    </div>
                                </div>
                                <>
                                    <div className="form-flex-container">
                                        <div
                                            className='input-block without-config-item-block input-search-select-block'>
                                            <label htmlFor="country">You can choose the country</label>
                                            <CustomDatalist ref={countrySelectRef} id="country" name="country" options={countriesList}
                                                            placeholder='Select or enter a country'
                                                            data={csvData}
                                                            setFilterCountryData={setFilterCountryData}
                                                            setCountry={setCountry}
                                                            includeMultipleGeos={currentValues.includeMultipleGeos}
                                                            validate={value => validateCountry(value, setCurrentValues)}
                                                            setFormError={setFormError}
                                                            setFieldValue={setFieldValue}
                                            />
                                        </div>

                                        <fieldset className='input-block without-config-item-block'>
                                            <legend>It is possible to download only a restricted number of offers. Now
                                                you
                                                have {filterCountryData.length === 0 && currentValues.country === '' ? csvData.length : filterCountryData.length} offers
                                                to download. You can choose the range of offers
                                            </legend>
                                            <div className="input-range-block">
                                                <div className="input-range-inner-block">
                                                    <label htmlFor="pagesFrom">From</label>
                                                    <Field
                                                        id="pagesFrom"
                                                        name="pagesFrom"
                                                        type='number'
                                                        validate={value => validatePagesFrom(value, setCurrentValues)}
                                                    />
                                                    {errors.pagesFrom && touched.pagesFrom ?
                                                        <div className='form-error'>{errors.pagesFrom}</div> : null}
                                                </div>

                                                <div className="input-range-inner-block">
                                                    <label htmlFor="pagesTo">to</label>
                                                    <Field
                                                        id="pagesTo"
                                                        name="pagesTo"
                                                        type='number'
                                                        validate={value => validatePagesTo(value, setCurrentValues)}
                                                    />
                                                    {errors.pagesTo && touched.pagesTo ?
                                                        <div className='form-error'>{errors.pagesTo}</div> : null}
                                                </div>
                                            </div>
                                        </fieldset>

                                        <div className="input-block without-config-item-block">
                                            <label htmlFor="workspaceId">Workspace Id (if needed)</label>
                                            <Field
                                                id="workspaceId"
                                                name="workspaceId"
                                                type='text'
                                                validate={value => validateWorkspaceId(value, setCurrentValues)}
                                            />
                                            {errors.workspaceId && touched.workspaceId ?
                                                <div className='form-error'>{errors.workspaceId}</div> : null}
                                        </div>

                                        <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                type="checkbox"
                                                className="toggle-button"
                                                id='header'
                                                name="header"
                                                validate={value => validateHeader(value, setCurrentValues)}
                                            />
                                            <label htmlFor='header' className="toggle-label">Include column
                                                names</label>
                                        </div>

                                        <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                type="checkbox"
                                                className="toggle-button"
                                                id='diff-column'
                                                name="diff-column"
                                                validate={value => validateDiffColumn(value, setCurrentValues)}
                                            />
                                            <label htmlFor='diff-column' className="toggle-label">Include "Domain"
                                                column instead of "Offer Name"</label>
                                        </div>
                                        <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                type="checkbox"
                                                className="toggle-button"
                                                id='include-multiple-geos'
                                                name="include-multiple-geos"
                                                validate={value => validateIncludeMultipleGeos(value, setCurrentValues)}
                                            />
                                            <label htmlFor='include-multiple-geos' className="toggle-label">Include offers with multiple geos</label>
                                        </div>
                                        <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                innerRef={specGeoForMultipleRef}
                                                type="checkbox"
                                                className="toggle-button"
                                                id='spec-geo-for-multiple'
                                                name="spec-geo-for-multiple"
                                                validate={value => validateSpecGeoForMultiple(value, setCurrentValues)}
                                            />
                                            <label htmlFor='spec-geo-for-multiple' className="toggle-label">Specify offers with multiple geos as global</label>
                                        </div>

                                        <div className="input-block without-config-item-block after-checkbox-block">
                                            <label htmlFor="blogrefNoref">Blogref/noref</label>
                                            <Field
                                                id="blogrefNoref"
                                                name="blogrefNoref"
                                                type='text'
                                                validate={value => validateBlogrefNoref(value, setCurrentValues)}
                                            />
                                            {errors.blogrefNoref && touched.blogrefNoref ?
                                                <div className='form-error'>{errors.blogrefNoref}</div> : null}
                                        </div>

                                    </div>
                                </>
                            </Form>
                        )}
                    </Formik>
                    <div className="form-submit-block">
                        <CsvDownload csvData={filterCountryData.length === 0 ? csvData : filterCountryData}
                                     filename='voluum_format_yieldkit_offers_list' separator={';'}
                                     enclosingCharacter={''} voluumFile={true} params={currentValues}
                                     additionalClass={formError ? ' disabled' : ''}
                                     specGeoForMultiple={currentValues.specGeoForMultiple === true ? '' : country}>
                            Download CSV - Voluum format
                        </CsvDownload>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CSVinVoluumFormatPopup;
