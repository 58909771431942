import { handleClickToCopy } from "../../helpers/click-to-copy";

export const columnDefs = [
    {
        label: 'Offers: AN Offer ID',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["offers_an_offer_id"])}
            title={"Click to copy"}
        >{item["offers_an_offer_id"]}</span>,
        resize: true,
        key: "offers_an_offer_id",
        width: "400px",
        select: true,
    },
    {
        label: 'Offers: AN',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["offers_an"])}
            title={"Click to copy"}
        >{item["offers_an"]}</span>,
        resize: true,
        key: "offers_an",
        width: "120px",
    },
    {
        label: 'Offers: AN Offer Name',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["offers_an_offer_name"])}
            title={"Click to copy"}
        >{item["offers_an_offer_name"]}</span>,
        resize: true,
        key: "offers_an_offer_name",
        width: "300px",
    },
    {
        label: 'Offers: Geo',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["offers_geo"])}
            title={"Click to copy"}
        >{item["offers_geo"]}</span>,
        resize: true,
        key: "offers_geo",
        width: "150px",
    },
    {
        label: 'Setups: Campaign ID',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_campaign_id"])}
            title={"Click to copy"}
        >{item["setups_campaign_id"]}</span>,
        resize: true,
        key: "setups_campaign_id",
        width: "400px",
    },
    {
        label: 'Setups: Counter',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_counter"])}
            title={"Click to copy"}
        >{item["setups_counter"]}</span>,
        resize: true,
        key: "setups_counter",
        width: "170px",
    },
    {
        label: 'Setups: Created At',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_created_at"])}
            title={"Click to copy"}
        >{item["setups_created_at"]}</span>,
        resize: true,
        key: "setups_created_at",
        width: "200px",
    },
    {
        label: 'Setups: Updated At',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_updated_at"])}
            title={"Click to copy"}
        >{item["setups_updated_at"]}</span>,
        resize: true,
        key: "setups_updated_at",
        width: "200px",
    },
    {
        label: 'Setups: Start Date',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_date_start"])}
            title={"Click to copy"}
        >{item["setups_date_start"]}</span>,
        resize: true,
        key: "setups_date_start",
        width: "200px",
    },
    {
        label: 'Setups: End Date',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_date_end"])}
            title={"Click to copy"}
        >{item["setups_date_end"]}</span>,
        resize: true,
        key: "setups_date_end",
        width: "200px",
    },
    {
        label: 'Setups: Discovery ID',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_discovery_id"])}
            title={"Click to copy"}
        >{item["setups_discovery_id"]}</span>,
        resize: true,
        key: "setups_discovery_id",
        width: "190px",
    },
    {
        label: 'Setups: Limit',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_limit"])}
            title={"Click to copy"}
        >{item["setups_limit"]}</span>,
        resize: true,
        key: "setups_limit",
        width: "150px",
    },
    {
        label: 'Setups: Priority',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_priority"])}
            title={"Click to copy"}
        >{item["setups_priority"]}</span>,
        resize: true,
        key: "setups_priority",
        width: "150px",
    },
    {
        label: 'Setups: Status',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["setups_status"])}
            title={"Click to copy"}
        >{item["setups_status"]}</span>,
        resize: true,
        key: "setups_status",
        width: "180px",
    },
];

export const rowsLimitOptions = [
    {value: "10", label: "10 / page"},
    {value: "50", label: "50 / page"},
    {value: "100", label: "100 / page"},
    {value: "500", label: "500 / page"},
];

export const orderDirectionOptions = [
    {value: "ASC", label: "Ascending"},
    {value: "DESC", label: "Descending"},
];

export const filterConditionOptions = [
    {value: "like", label: "Contains"},
    {value: "not like", label: "Not Contains"},
    {value: ">", label: "More than"},
    {value: "<", label: "Less than"},
    {value: "=", label: "Equal"},
    {value: "!=", label: "Not Equal"},
    {value: "in", label: "One of"},
]