import "./confirmation-popup.scss"

const ConfirmationPopup = ({popupText = "Are you sure?", onConfirm, onCancel}) => {

    return (
        <div className='confirmation-popup-container'>
            <div className="confirmation-popup-block">
                <h4>{popupText}</h4>
                <div className="config-btns-block">
                    <button className='update-btn' onClick={onConfirm}>Yes</button>
                    <button className='discard-btn' onClick={onCancel}>No</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPopup;