import React, {useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import * as XLSX from "xlsx";
import { readString } from "react-papaparse";
import {FILE_TYPES, UPLOADER_TYPE_LINKS, UPLOADER_TYPE_LINKS_FRONT_ONLY, UPLOADER_TYPE_OFFERS, UPLOADER_TYPE_TOP_OFFERS} from "../../constants";
import {Alert} from "@mui/material";

const CustomFileUploader = ({type, setFileLinksList, currentValues, setFormError}) => {
    const [fileError, setFileError] = useState(false);
    const [parsingErrors, setParsingErrors] = useState([]);
    const [file, setFile] = useState([])

    const handleFileChange = files => {
        setFile(files[0]);
        setFileError(false);
        const selectedFile = files[0];

        if(files[0].size > 1000000) {
            setFormError(true);
        } else {
            setFormError(false);
        }

        const reader = new FileReader();
        reader.onload = () => {
            const fileContent = reader.result;

            let cleanColumnData;
            let data = [];
            if (selectedFile.type === "text/csv") {
                let result = readString(fileContent);
                data = result.data;
                let filteredErrors = Object.values(
                    result.errors.reduce( (c, e) => {
                        if (!c[e.code]) c[e.code] = e;
                        return c;
                    }, {})
                );
                setParsingErrors(filteredErrors);
            } else {
                const workbook = XLSX.read(fileContent, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            }

            if(type === UPLOADER_TYPE_LINKS) {
                const columnIndex = findURLColumnIndex(data, data[0]);
                if (columnIndex !== -1) {
                    cleanColumnData = extractColumn(data, columnIndex);
                } else {
                    cleanColumnData = [];
                }
                setFileLinksList(cleanColumnData);
            } else if(type === UPLOADER_TYPE_OFFERS) {
                const filteredArray = data.map(innerArray => innerArray.filter(item => item !== 'empty'));
                const newArr = filteredArray
                    .filter(el => /^[a-zA-Z0-9-]+$/.test(el[0]) && el[0] && el[0] !== '')
                    .map(el => el[0]);
                 setFileLinksList(newArr);
            } else if(type === UPLOADER_TYPE_LINKS_FRONT_ONLY) {
                const columnIndex = findURLColumnIndex(data, data[0], UPLOADER_TYPE_LINKS_FRONT_ONLY);
                if (columnIndex !== -1) {
                    cleanColumnData = extractColumn(data, columnIndex, UPLOADER_TYPE_LINKS_FRONT_ONLY);
                } else {
                    cleanColumnData = [];
                }
                setFileLinksList(cleanColumnData);
            } else if(type === UPLOADER_TYPE_TOP_OFFERS) {
                setFileLinksList(data);
            }
        };

        if (selectedFile.type === "text/csv") {
            reader.readAsText(selectedFile);
        } else {
            reader.readAsBinaryString(selectedFile);
        }
    };

    const findURLColumnIndex = (data, headers) => {
        const columnCount = data[0].length;
        if (headers && headers.includes("tracking_link")) {
            return headers.indexOf("tracking_link");
        }

        for (let i = 0; i < columnCount; i++) {
            const columnData = data
                .filter((row) => row.length > 0)
                .map((row) => row[i])
                .filter((el) => /^http/i.test(el));
            if (columnData.some((el) => /^http/i.test(el))) {
                return i;
            }
        }
        return -1;
    };

    const extractColumn = (data, columnNum, type) => {
        let columnData;
        const keys = [
            "offer_name",
            "url",
            "country_code",
            "affiliate_network_id",
            "payout",
            "tags",
            "offer_cap_enabled",
            "daily_cap_value",
            "offer_cap_time_zone",
            "offer_cap_redirect_offer_id",
            "workspace_id",
            "currency_code",
            "offer_cap_type"
        ];
        if(type === UPLOADER_TYPE_LINKS_FRONT_ONLY) {
            if(data[0].filter((el) => /^http/i.test(el)).length === 0 && data[0].includes('1. Offer name')) {
                columnData = data
                    .filter(row => row.length > 0)
                const objects = columnData
                    .map(values => {
                        const obj = {};
                        keys.forEach((key, index) => {
                            obj[key] = values[index];
                        });
                        return obj;
                    })
                return objects.filter(el => /^http/i.test(el.url));
            } else {
                columnData = data
                    .filter(row => row.length > 0 && row[0] !== '')
                    .map(row => {
                        let obj = {};
                        data[0].forEach((el, i) => {
                            obj[`column${i}`] = row[i];
                        })
                        return {
                            "offer_name": "",
                            "url": row[columnNum],
                            "country_code": "",
                            "affiliate_network_id": "",
                            "payout": "",
                            "tags": "",
                            "offer_cap_enabled": "",
                            "daily_cap_value": "",
                            "offer_cap_time_zone": "",
                            "offer_cap_redirect_offer_id": "",
                            "workspace_id": "",
                            "currency_code": "",
                            "offer_cap_type": ""
                        }
                    })
                    .filter(row => /^http/i.test(row.url));
            }
        } else {
            if(data[0].filter((el) => /^http/i.test(el)).length === 0) {
                columnData = data
                    .filter(row => row.length > 0)
                    .map(row => {
                        let obj = {};
                        data[0].forEach((el, i) => {
                            obj[el] = row[i];
                        })

                        return {
                            url: row[columnNum],
                            jsonData: JSON.stringify(obj)
                        }
                    })
                    .filter(row => /^http/i.test(row.url));
            } else {
                columnData = data
                    .filter(row => row.length > 0)
                    .map(row => {
                        let obj = {};
                        data[0].forEach((el, i) => {
                            obj[`column${i}`] = row[i];
                        })

                        return {
                            url: row[columnNum],
                            jsonData: JSON.stringify(obj)
                        }
                    })
                    .filter(row => /^http/i.test(row.url));
            }
        }
        return columnData;
    }

    return (
        <>
            <FileUploader
                multiple={true}
                handleChange={handleFileChange}
                name="file"
                types={FILE_TYPES}
                disabled={currentValues.linksList !== ''}
                classes={`drop-label ${fileError ? 'error' : ''}`}
            />
            <p className='file-name'>{file && file.name ? `File name: ${file.name}` : currentValues.linksList !== ''? 'Data from the form will be used' : 'no file uploaded yet'}</p>
            {parsingErrors.length !== 0 && (
                <Alert severity="warning">
                    {parsingErrors.map(error => {
                        return (
                            <div>{error.message} (row {error.row})</div>
                        );
                    })}
                </Alert>
            )}
        </>
    );
};

export default CustomFileUploader;
