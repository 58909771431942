import {Field} from "formik";

const CustomTextarea = ({name, validate, value, errors, touched, arrLength}) => {
    return (
        <div className={`input-wrap ${errors && touched && arrLength === 0 ? 'error-input' : ''}`}>
            <div className="input-block">
                <Field
                    id={name}
                    name={name}
                    as="textarea"
                    validate={validate}
                    value={arrLength !== 0 ? '' : value}
                    disabled={arrLength !== 0}
                    placeholder={arrLength !== 0 ? 'Data from the uploaded file will be used' : ''}
                />
            </div>
            {errors && touched && arrLength === 0 &&
                <div className='error-text login-error-text'>{errors}</div>}
        </div>
    )
}

export default CustomTextarea;
