import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {USER_ROLE} from "../../constants/Auth";
import NewUserForm from "../../components/forms/NewUserForm";
import Loader from "../../components/loaders/Loader";
import addNewUser from "../../data-collectors/add-new-user";

import './new-user.scss';

const NewUser = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem(USER_ROLE);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const isLoading = useSelector((state) => state.dataUploader.isLoading);
    const [isFormLoading, setFormLoading] = useState(false);
    const [isFormError, setFormError] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('Some error occurred');
    const [successStatus, setSuccessStatus] = useState(false);
    const [formSuccessMsg, setFormSuccessMsg] = useState('User is added');
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        name: '',
        email: '',
        password: '',
        role: ''
    })
    const [currentValues, setCurrentValues] = useState({
        name: '',
        email: '',
        password: '',
        role: ''
    })

    useEffect(() => {
        if(role !== 'admin') {
            navigate('/not-found');
        }
    }, [pathname, navigate, role])

    const onSubmit = async (values, {resetForm}) => {
        const {name, email, password, role} = values;
        if (name && email && password && role) {
            addNewUser(setFormLoading, setFormErrorMsg, setSuccessStatus, setFormSuccessMsg, setFormError, dispatch, currentValues, resetForm);
        }
    }

    const validateName = (value) => {
        const nameRegex = /^[A-Za-z\s'-]+$/;
        setCurrentValues(previousState => {
            return {...previousState, name: value.trim()}

        });

        let error;
        if (!value) {
            error = 'Required';
        } else if (!nameRegex.test(value)) {
            error = 'No special symbols';
        } else if (value.length < 2 || value.length > 16) {
            error = 'Invalid name';
        }
        return error;
    }

    const validateEmail = (value) => {
        setCurrentValues(previousState => {
            return {...previousState, email: value}
        });

        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }

    const validatePassword = (value) => {
        setCurrentValues(previousState => {
            return {...previousState, password: value}
        });

        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 6 || value.length > 15) {
            error = 'Password must be min 6 and max 15 symbols';
        }
        return error;
    }

    const validateRole = (value) => {
        setCurrentValues(previousState => {
            return {...previousState, role: value}
        });

        let error;
        if (!value) {
            error = 'Required';
        } else if (value !== 'admin' && value !== 'regular_user') {
            error = 'Invalid role, can be admin or regular_user';
        }
        return error;
    }

    return (
        <>
            {role === 'admin' ?
                <>
                    <div className={`new-user-container gradient-container${!menuStatus ? ' collapse-menu' : ''}`}>
                        <h3>New User</h3>
                    </div>
                    <>
                        <NewUserForm
                            initialValues={initialValues}
                            currentValues={currentValues}
                            onSubmit={onSubmit}
                            validateName={validateName}
                            validateEmail={validateEmail}
                            validatePassword={validatePassword}
                            validateRole={validateRole}
                            isLoading={isLoading}
                        />
                        {isFormLoading ? <div className='new-user-loading'><Loader /></div> : ''}
                        {isFormError ? <p className='new-user-error'>{formErrorMsg}</p> : ''}
                        {successStatus ? <p className='new-user-success'>{formSuccessMsg}</p> : ''}
                    </>
                </> :
                <p>Sorry, you can't see this page</p>
            }
        </>
    )
}

export default NewUser