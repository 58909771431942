import { Formik, Field, Form } from 'formik';
import Loader from "../../loaders/Loader";
import "../forms-styles.scss";
import "./login-form.scss";

const LogInForm = ({
                       initialValues,
                       currentValues,
                       setCurrentValues,
                       onSubmit,
                       validateEmail,
                       validatePassword,
                       isLoading,
                       isSuccessfully,
                       setForgotPassword
                   }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ errors, touched }) => (
            <Form>
                <div className={`input-wrap ${errors.email && touched.email ? 'error-input' : ''}`}>
                    <div className="input-block login-input-block">
                        <label htmlFor="email">Email</label>
                        <Field
                            id="email"
                            name="email"
                            type="email"
                            validate={(value) => validateEmail(value, setCurrentValues)}
                            className={currentValues.email !== ''? 'filled' : ''}
                        />
                    </div>
                    {errors.email && touched.email && <div className='error-text login-error-text'>{errors.email}</div>}
                </div>
                <div className={`input-wrap ${errors.password && touched.password ? 'error-input' : ''}`}>
                    <div className="input-block login-input-block">
                        <label htmlFor="password">Password</label>
                        <Field
                            id="password"
                            type="password"
                            name="password"
                            validate={validatePassword}
                            className={currentValues.password !== ''? 'filled' : ''}
                        />
                    </div>
                    {errors.password && touched.password && <div className='error-text login-error-text'>{errors.password}</div>}
                </div>
                <div className="forgot-pass-wrap">
                    <button className="forgot-password-link" type="button" onClick={() => setForgotPassword(true)}>Forgot password?</button>
                </div>

                <button className={`form-submit ${isSuccessfully ? 'successfully' : ''}`} type="submit" disabled={isLoading}>Submit</button>
            </Form>
        )}
    </Formik>
);

export default LogInForm;