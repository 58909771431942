import React from "react";

const OpenPopupBtn = ({openPopup, title, disabled = false}) => {
    return (
        <button className='blue-btn btn-ml new-blue-btn' onClick={openPopup} disabled={disabled}>
            <span>{title}</span>
        </button>
    )
}

export default OpenPopupBtn;
