const sortGrid = (event, field, sortDir) => {
    const columnState = {
        state: [
            {
                colId: field,
                sort: sortDir
            }
        ]
    }
    event.columnApi.applyColumnState(columnState);
}

const defaultSortOfTableParams = (params, alertNum) => {
    let field;
    let sortDir;
    if (alertNum) {
        switch (alertNum) {
            case 1:
            case 2:
                field = 'cost';
                sortDir = 'desc';
                break;
            case 3:
                field = 'profit';
                sortDir = 'asc';
                break;
            case 4:
            case 5:
                field = 'percent';
                sortDir = 'desc';
                break;
        }
    }
    sortGrid(params, field, sortDir)
    params.api.sizeColumnsToFit();
}


export default defaultSortOfTableParams;
