import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/loaders/Loader";
import PreTableWithPopups from "../../components/tables/PreTableWithPopups";
import CustomSelect from "../../components/CustomSelect";
import {USER_PROJECT, USER_ROLE} from "../../constants/Auth";
import GetVoluumAlertsConfigData from "../../data-collectors/get-voluum-alerts-config";
import getVoluumHiddenColumns from "../../helpers/get-voluum-hidden-columns";
import {
    accountDetailsStart,
    voluumAlertsStart,
    accountInformationStart
} from "../../redux/dataUploader/dataSlice";

import './voluum-alerts-config.scss'
import CustomTooltip from "../../components/CustomTooltip";
import ClearFilters from "../../components/buttons/ClearFilters";

const VoluumAlertsConfig = () => {
    const dispatch = useDispatch();
    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const accountArr = useSelector(state => state.dataUploader.accountDetails);
    const voluumAlerts = useSelector(state => state.dataUploader.voluumAlerts);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const voluumId = useSelector(state => state.dataUploader.voluumId);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [newVoluumId, setNewVoluumId] = useState(voluumId);
    const [sortedRowData, setSortedRowData] = useState([]);
    const [newDataStatus, setNewDataStatus] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);
    const [configData, setConfigData] = useState([]);
    const [selectData, setSelectData] = useState([]);
    const [addPopupStatus, setAddPopupStatus] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [addPopupWithConfigData, setAddPopupWithConfigData] = useState(true);
    const [isPopupType, setPopupType] = useState('updating');
    const [tableRef, setTableRef] = useState(null);
    const additionalDefCol = {flex: 1};

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
    }, [dispatch, projectName]);

    useEffect(() => {
        if(accountInformation.length > 0) {
            setSelectData(accountInformation.filter(el => el['voluum_id'] < 1000));
        }
    }, [projectName, accountInformation]);

    useEffect(() => {
        if(role !== 'admin') {
            setLoading(true);
            dispatch(voluumAlertsStart(projectName));
            setNewDataStatus(false);
        } else {
            if(voluumAlerts.length === 0 || newDataStatus) {
                setLoading(true);
                dispatch(voluumAlertsStart());
                setNewDataStatus(false);
            }
            dispatch(accountDetailsStart({an_id: '', voluum_id: ''}));
        }
        GetVoluumAlertsConfigData(setError, dispatch, setConfigData);
    }, [dispatch, newDataStatus]);

    useEffect(() => {
        if(voluumAlerts.length > 0) {
            setLoading(false);
            setSortedRowData([...voluumAlerts]);
        } else {
            setLoading(false);
            setSortedRowData([]);
        }
        if(newVoluumId) {
            const arr = voluumAlerts.filter(el => el.voluumId === newVoluumId.toString());
            setSortedRowData(arr);
        } else if(role !== 'admin' && !newVoluumId) {
            setSortedRowData([]);
        } else {
            setSortedRowData([...voluumAlerts]);
        }
    }, [voluumAlerts, newVoluumId]);

    useEffect(() => {
        if(configData.length > 0) getVoluumHiddenColumns(configData, dispatch);
        if(configData.length > 0) {
            const countedNames = configData.reduce((allEl, el) => {
                return {
                    ...allEl,
                    [el.name]: '',
                };
            }, {});
            setInitialValues(countedNames)
        }
    }, [configData])

    const modifiedColumnsData = (columns) => columns.map(el => {
        let columnDef = {
            field: el,
            filter: true,
            wrapText: true,
            tooltipField: el,
            tooltipComponent: CustomTooltip,
        };

        if (el === 'alertName') {
            columnDef = {
                ...columnDef,
                maxWidth: 150
            }
        } else if (el === 'alertDescription') {
            columnDef = {
                ...columnDef,
                width: 340
            }
        } else if (el === 'active') {
            columnDef = {
                ...columnDef,
                minWidth: 140,
                maxWidth: 140,
                cellRenderer: (params) => {
                    const {value} = params;
                    return (
                        <span className={`status-cell${value ? ' active-cell' : ' deactivated-cell'}`}>
                            {value ? 'active' : 'deactivated'}
                        </span>
                    )
                }
            }
        }
        return columnDef;
    })

    const onCountedNames = (allValues, currentItemData) => {
        const countedNames = allValues.reduce((allEl, el) => {
            return {
                ...allEl,
                [el.name]: currentItemData[el.name] !== null ? currentItemData[el.name] : '',
            };
        }, {});
        setInitialValues(countedNames);
    }

    const clearFilters = () => {
        tableRef.api.setFilterModel(null);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <>
            <p className='voluum-text'>In this table you can update alert configuration or activate/deactivate any of your alerts.</p>

            <div className={`page-btns-container${!menuStatus ? ' collapsed-menu' : ''}`}>
                {role === 'admin' && accountArr.length > 1 ?
                    <>
                        <CustomSelect data={selectData} applyDispatch={true} setVoluumId={setNewVoluumId} />
                        <ClearFilters clearFilters={clearFilters} />
                    </> : null
                }
            </div>

            {sortedRowData.length > 0 || voluumAlerts.length > 0 ?
                <div className="voluum-config-table">
                    <PreTableWithPopups
                        rowData={sortedRowData.length > 0 ? sortedRowData : voluumAlerts.length > 0 ? voluumAlerts : []}
                        voluumId={newVoluumId}
                        setNewDataStatus={setNewDataStatus}
                        columnDefs={columnDefs}
                        setColumnDefs={setColumnDefs}
                        addPopupStatus={addPopupStatus}
                        setAddPopupStatus={setAddPopupStatus}
                        initialValues={initialValues}
                        configData={configData}
                        modifiedColumnsData={modifiedColumnsData}
                        onCountedNames={onCountedNames}
                        addPopupWithConfigData={addPopupWithConfigData}
                        isPopupType={isPopupType}
                        setPopupType={setPopupType}
                        additionalDefCol={additionalDefCol}
                        getTableRef={getTableRef}
                    />
                </div> :
                <h3 className="voluum-config-table-no-data">No data to provide</h3>
            }
        </>
    )
}

export default VoluumAlertsConfig;