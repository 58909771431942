import { handleClickToCopy } from "../../helpers/click-to-copy";
import {DISCOVERY_TOOL_CAMPAIGN_STATUSES} from "../../constants/index";

export const columnDefs = [
    {
        label: 'ID',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["id"])}
            title={"Click to copy"}
        >{item["id"]}</span>,
        resize: true,
        key: "id",
        width: "100px",
        select: true,
    },
    {
        label: 'Campaign ID',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["campaign_id"])}
            title={"Click to copy"}
        >{item["campaign_id"]}</span>,
        resize: true,
        key: "campaign_id",
        width: "2fr",
    },
    {
        label: 'Created At',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["created_at"])}
            title={"Click to copy"}
        >{item["created_at"]}</span>,
        resize: true,
        key: "created_at",
        width: "minmax(200px, 1fr)",
    },
    {
        label: 'Updated At',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["updated_at"])}
            title={"Click to copy"}
        >{item["updated_at"]}</span>,
        resize: true,
        key: "updated_at",
        width: "minmax(200px, 1fr)",
    },
    {
        label: 'Geo',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["geo"])}
            title={"Click to copy"}
        >{item["geo"]}</span>,
        resize: true,
        key: "geo",
        width: "100px",
    },
    {
        label: 'Status',
        renderCell: (item) => <span
            className={"data-table-cell"}
            onClick={() => handleClickToCopy(item["status"])}
            title={"Click to copy"}
        >{item["status"]}</span>,
        resize: true,
        key: "status",
        width: "minmax(150px, 1fr)",
    },
];

export const rowsLimitOptions = [
    {value: "10", label: "10 / page"},
    {value: "50", label: "50 / page"},
    {value: "100", label: "100 / page"},
];

export const orderDirectionOptions = [
    {value: "ASC", label: "Ascending"},
    {value: "DESC", label: "Descending"},
];

export const filterConditionOptions = [
    {value: "like", label: "Contains"},
    {value: "not like", label: "Not Contains"},
    {value: ">", label: "More than"},
    {value: "<", label: "Less than"},
    {value: "=", label: "Equal"},
    {value: "!=", label: "Not Equal"},
    {value: "in", label: "One of"},
]