import React, {useState, useEffect, useMemo} from 'react';
import CustomTooltip from "../../CustomTooltip";
import roundToFourDecimals from "../../../helpers/round-to-four-decimals";
import Table from "../Table";
import defaultSortOfTableParams from "../../../helpers/default-sort-of-table-params";

const PreTableAlerts = ({rowData, smallTable, onFilterChanged, onSortChanged, name, alertNum, getTableRef}) => {
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
        if (rowData && rowData.length > 0) {
            const colArr = Object.entries(rowData[0]).map(([field, value]) => {
                let columnDef = {
                    field: field,
                    headerName: field.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
                    filter: !isNaN(value) && value !== '' ? 'agNumberColumnFilter' : true,
                    wrapText: true,
                    tooltipField: field,
                    tooltipComponent: CustomTooltip,
                    valueFormatter: params => roundToFourDecimals(params.value),
                    hide: rowData.every(row => row[field] === '' || row[field] === null)
                };

                if (field === 'account_id' || field === 'account_name' || field === 'status_for_action' || field === 'action') {
                    columnDef = {
                        ...columnDef,
                        hide: true
                    };
                } else if (field === 'order') {
                    columnDef = {
                        ...columnDef,
                        maxWidth: 100,
                    };
                } else if (field === 'period') {
                    columnDef = {
                        ...columnDef,
                        width: 400,
                        minWidth: 400,
                        maxWidth: 400,
                    };
                } else if (field === 'campaign_id') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 120,
                    };
                } else if (field === 'entity_type') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 130,
                    };
                } else if (field === 'status') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 170,
                        maxWidth: 170,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return (
                                <span className={`status-cell${value === 'to be reactivated' ? ' active-cell' : value === 'to be deactivated' ? ' deactivated-cell' : ' empty-cell'}`}>
                                    {value}
                                </span>
                            )
                        }
                    };
                } else {
                    columnDef = {
                        ...columnDef,
                        filter: !isNaN(value) && value !== '' ? 'agNumberColumnFilter' : true
                    };
                }

                return columnDef;
            });

            if (colArr[0].field === 'offerName') {
                colArr.forEach(el => {
                    switch (el.field) {
                        case 'cost':
                            el.width = 150;
                            el.maxWidth = 150;
                            break;
                        case 'offerName':
                        case 'tags':
                            el.width = 200;
                            el.minWidth = 200;
                            el.maxWidth = 200;
                            break;
                    }
                })
            } else if (colArr[0].field === 'period') {
                colArr.forEach(el => {
                    switch (el.field) {
                        case 'totalCost':
                        case 'totalRevenue':
                            el.width = 150;
                            el.maxWidth = 150;
                            break;
                    }
                })
            }

            if (colArr.length > 1 && colArr[1].field === 'offerName') {
                colArr.forEach(el => {
                    switch (el.field) {
                        case 'period':
                            el.width = 150;
                            el.minWidth = 150;
                            el.maxWidth = 150;
                            break;
                    }
                })
            } else if (colArr.length > 1 && colArr[1].field === 'AffNetwork') {
                colArr.forEach(el => {
                    switch (el.field) {
                        case 'period':
                            el.hide = true;
                        case 'url':
                            el.minWidth = 300;
                            break;
                    }
                })
            } else if (colArr.length > 1 && colArr[0].field === 'AffNetwork') {
                colArr.forEach(el => {
                    switch (el.field) {
                        case 'url':
                            el.minWidth = 300;
                            break;
                    }
                })
            }

            if (name && name === 'Yieldkit') {
                colArr.forEach(el => {
                    switch (el.field) {
                        case 'cost':
                            el.headerName = 'Daily Cost';
                            break;
                        case 'offer_id':
                            el.headerName = 'YK offer id';
                            break;
                        case 'current_status':
                            el.headerName = 'Current YK offer status';
                            el.minWidth = 150;
                            el.cellRenderer = (params) => {
                                const {value} = params;
                                return (
                                    <span className={`status-cell${value === 'active' ? ' active-cell' : value === 'not found' ? ' not-found-cell' : ' deactivated-cell'}`}>
                                    {value}
                                </span>
                                )
                            }
                            break;
                    }
                })
            }
            setColumnDefs(colArr);
        }
    }, [rowData]);

    const headerClassCol = useMemo(() => {
        if (alertNum) {
            return alertNum === 4 || alertNum === 5 || alertNum === 6 || alertNum === 7;
        }
        return false;
    }, [alertNum]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        autoHeight: true,
        minWidth: 150,
        flex: 1
    }));

    return (
        <Table
            rowData={rowData}
            smallTable={smallTable}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            onGridReady={params => defaultSortOfTableParams(params, alertNum)}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            headerClassCol={headerClassCol}
            getTableRef={getTableRef}
        />
    );
};


export default PreTableAlerts;