import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {accountSelected} from "../../redux/dataUploader/dataSlice";

import './custom-select.scss';

const CustomOpenSelect = ({data, setTableLoading, disabled = false}) => {
    const dispatch = useDispatch();
    const curAccount = useSelector(state => state.dataUploader.accountSelected);
    const [optionSelected, setOptionSelected] = useState(curAccount);
    const [showOptionList, setShowOptionList] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            setOptionSelected(data[0].name);
        }
    }, []);

    useEffect(() => {
        dispatch(accountSelected(optionSelected));
    }, [optionSelected, dispatch]);

    const handleListDisplay = () => {
        setShowOptionList(!showOptionList);
    };

    const handleOptionClick = e => {
        if(setTableLoading) setTableLoading(true);
        setShowOptionList(false);
        setOptionSelected(e.target.getAttribute("data-name"));
    };

    return (
        data.length <= 3 ?
            <div className={`transparent-btn category-dropdown custom-select-horizontal ${showOptionList ? 'active' : ''}`} onClick={handleListDisplay}>
                <ul className='custom-select-option-container custom-select-horizontal'>
                    {data.map((option, i) => {
                        return (
                            <li
                                className={`custom-select-option${option.name === optionSelected ? ' active' : ''}`}
                                data-name={option.name}
                                key={i}
                                onClick={disabled ? null : handleOptionClick}
                            >
                                {option.name}
                            </li>
                        );
                    })}
                </ul>
            </div> :
            <div className={`transparent-btn category-dropdown ${showOptionList ? 'active' : ''} ${disabled ? 'disabled' : ''}`} onClick={disabled ? null : handleListDisplay}>
                <div className={`selected-text ${showOptionList ? 'active' : ''}`}>
                    {optionSelected}
                </div>
                <img className='category-icon' src={`${process.env.PUBLIC_URL}/images/down-arrow.png`} alt="Icon"/>
                {showOptionList && (
                    <ul className='custom-select-option-container'>
                        <li
                            className="custom-select-option"
                            data-name={optionSelected}
                            hidden
                        >
                            {optionSelected}
                        </li>
                        {data.map((option, i) => {
                            return (
                                <li
                                    className="custom-select-option custom-select-vertical"
                                    data-name={option.name}
                                    data-id={option.id}
                                    key={i}
                                    onClick={disabled ? null : handleOptionClick}
                                >
                                    {option.name}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
    );
};

export default CustomOpenSelect;
