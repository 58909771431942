import {VOLUUM_ALERTS_ORDER} from '../constants';

const groupVoluumAlerts = alerts => {
    const result = [];
    const groups = {};

    for (const key of Object.keys(VOLUUM_ALERTS_ORDER)) {
        groups[key] = [];
    }
    groups.temp = [];

    for (const obj of alerts) {
        const groupName = Object.keys(VOLUUM_ALERTS_ORDER).find(key => VOLUUM_ALERTS_ORDER[key].includes(obj.name)) || 'temp';
        groups[groupName].push(obj);
    }

    for (const key of Object.keys(VOLUUM_ALERTS_ORDER)) {
        let sortedArr = [];
        if (groups.temp.length > 0) {
            sortedArr =  groups.temp.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        }
        const orderedGroups = reorderObjectByNames(groups);
        orderedGroups.others = [...orderedGroups.others, ...sortedArr];
        if (orderedGroups[key].length > 0) {
            result.push(orderedGroups[key]);
        }
    }

    return result;
}

const reorderObjectByNames = obj => {
    const orderedObj = {};

    Object.keys(VOLUUM_ALERTS_ORDER).forEach(key => {
        const orderedNames = VOLUUM_ALERTS_ORDER[key];
        const unorderedItems = obj[key];
        const orderedItems = [];

        orderedNames.forEach(name => {
            const item = unorderedItems.find(item => item.name === name);
            if (item) {
                orderedItems.push(item);
            }
        });

        orderedObj[key] = orderedItems;
    });

    Object.keys(obj).forEach(key => {
        if (!orderedObj[key]) {
            orderedObj[key] = obj[key];
        }
    });

    return orderedObj;
}

export default groupVoluumAlerts;
