import {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import {useSelector, useDispatch} from "react-redux";
import {
    setErrorSubmitted,
    logInStart,
    setLoadingState,
    setSuccessfullyState,
    getSitesData
} from "../../redux/dataUploader/dataSlice";
import {AUTH_TOKEN, USER_ID, USER_OTP_ENABLED} from "../../constants/Auth";
import validateOpts from "../../services/validate-opt";
import forgotPassword from "../../services/forgot-password";
import delayedExecution from "../../helpers/delayed-execution";
import LogInForm from "../../components/forms/LogInForm";
import OptValidate from "../../components/forms/OptValidate";
import ForgotPassword from "../../components/forms/ForgotPassword";
import ErrorPopup from "../../components/popups/ErrorPopup";
import Loader from "../../components/loaders/Loader";
import FormsLayout from "../../components/forms/FormsLayout";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = localStorage.getItem(USER_ID);
    const userOtpEnabled = useSelector((state) => state.dataUploader.userOtpEnabled);
    const token = useSelector((state) => state.dataUploader.token);
    const localToken = localStorage.getItem(AUTH_TOKEN);
    const isLoading = useSelector((state) => state.dataUploader.isLoading);
    const isSuccessfully = useSelector((state) => state.dataUploader.isSuccessfully);
    const errorSubmitted = useSelector((state) => state.dataUploader.errorSubmitted);
    const currentPath = useSelector((state) => state.dataUploader.currentPath);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [readyToValidateOpt, setReadyToValidateOpt] = useState(false);
    const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        email: '',
        password: ''
    })
    const [currentValues, setCurrentValues] = useState({
        email: '',
        password: ''
    })
    const [initialOptValue, setInitialOptValue] = useState({ // eslint-disable-line no-unused-vars
        optCode: ''
    })
    const [currentOptValue, setCurrentOptValue] = useState({
        optCode: ''
    })
    const [initialEmailValue, setInitialEmailValue] = useState({
        email: ''
    })
    const [currentEmailValue, setCurrentEmailValue] = useState({
        email: ''
    })

    useEffect(() => {
        setLoadingStatus(true);
        if (!userOtpEnabled) {
            if (token) {
                localStorage.setItem(AUTH_TOKEN, token);
                localStorage.setItem(USER_OTP_ENABLED, userOtpEnabled);
                delayedExecution(
                    () => {
                        navigate('/');
                        dispatch(setSuccessfullyState(false));
                    }
                )
            }
        } else {
            if (localToken) {
                delayedExecution(
                    () => {
                        navigate('/');
                        dispatch(setSuccessfullyState(false));
                    }
                )
            } else {
                delayedExecution(
                    () => {
                        setReadyToValidateOpt(true);
                        dispatch(setSuccessfullyState(false));
                    }
                )
            }
        }
        setLoadingStatus(false);
    }, [token, navigate, currentPath])

    const onSubmit = (values) => {
        const {email, password} = values;
        if (email && password) {
            dispatch(logInStart(values));
            dispatch(getSitesData());
        }
    }

    const onOptValidate = async (value) => {
        const {optCode} = value;
        try {
            dispatch(setLoadingState(true));
            await validateOpts(userId, optCode);
            dispatch(setSuccessfullyState(true));
            localStorage.setItem(AUTH_TOKEN, token);
            localStorage.setItem(USER_OTP_ENABLED, userOtpEnabled);
            dispatch(setLoadingState(false));
            delayedExecution(
                () => {
                    navigate(currentPath);
                    dispatch(setSuccessfullyState(false));
                }
            )
        } catch (err) {
            dispatch(setLoadingState(false));
            dispatch(setErrorSubmitted(true));
            console.error(err);
        }
    }

    const onSendResetPasswordLink = async (value) => {
        const {email} = value;
        try {
            dispatch(setLoadingState(true));
            await forgotPassword(email);
            dispatch(setSuccessfullyState(true));
            dispatch(setLoadingState(false));
            delayedExecution(
                () => {
                    navigate('/login');
                    dispatch(setSuccessfullyState(false));
                }
            )
        } catch (err) {
            dispatch(setLoadingState(false));
            dispatch(setErrorSubmitted(true));
            console.error(err);
        }
    }

    const validateEmail = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, email: value}
        });

        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }

    const validatePassword = (value) => {
        setCurrentValues(previousState => {
            return {...previousState, password: value}
        });

        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 6 || value.length > 16) {
            error = 'Invalid password';
        }
        return error;
    }

    const validateCode = (value) => {
        setCurrentOptValue(previousState => {
            return {...previousState, optCode: value}
        });

        let error;
        if (!value) {
            error = 'Required';
        }
        return error;
    }

    const formErrorText = () => {
        if (readyToValidateOpt) {
            return '2FA code is invalid or user doesn\'t exist';
        } else if (forgotPasswordStatus) {
            return 'No user with that email exists';
        } else {
            return 'Login or password is invalid';
        }
    }

    if (errorSubmitted) {
        setTimeout(() => {
            dispatch(setErrorSubmitted(false))
        }, 2000)
    }

    if (loadingStatus) {
        return (
            <div className='loader-wrap'>
                <Loader/>
            </div>
        )
    }

    return (
        <>
            <ErrorPopup
                popupText={formErrorText}
                popupIsActive={errorSubmitted}
            />
            {
                readyToValidateOpt &&
                <FormsLayout formTitle='2FA Code' isLoading={isLoading}>
                    <OptValidate
                        onSubmit={onOptValidate}
                        initialValues={initialOptValue}
                        currentValues={currentOptValue}
                        validateCode={validateCode}
                        isLoading={isLoading}
                        isSuccessfully={isSuccessfully}
                    />
                </FormsLayout>
            }
            {
                forgotPasswordStatus &&
                <FormsLayout formTitle='Forgot Password' isLoading={isLoading}>
                    <ForgotPassword
                        initialValues={initialEmailValue}
                        onSubmit={onSendResetPasswordLink}
                        currentValues={currentEmailValue}
                        setCurrentEmailValue={setCurrentEmailValue}
                        validateEmail={validateEmail}
                        isLoading={isLoading}
                        isSuccessfully={isSuccessfully}
                    />
                </FormsLayout>
            }
            {
                !readyToValidateOpt && !forgotPasswordStatus &&
                <FormsLayout formTitle='Log in' isLoading={isLoading}>
                    <LogInForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        currentValues={currentValues}
                        setCurrentValues={setCurrentValues}
                        validateEmail={validateEmail}
                        validatePassword={validatePassword}
                        isLoading={isLoading}
                        isSuccessfully={isSuccessfully}
                        setForgotPassword={setForgotPasswordStatus}
                    />
                </FormsLayout>
            }
        </>
    )
}

export default Login;