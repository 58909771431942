import {logOut} from "../redux/dataUploader/dataSlice";
import {updateVoluumAlertService} from "../services/voluum";

const updateVoluumAlert = async (setFormLoading, setSuccessStatus, setFormError, dispatch, data) => {
    setFormLoading(true);

    try {
        const results = await updateVoluumAlertService(data);
        if(results.data.error) {
            setFormError(true)
        } else {
            setSuccessStatus(true);
        }
        setFormLoading(false);
    } catch (error) {
        setFormLoading(false);
        setFormError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default updateVoluumAlert;