import {VOLUUM_REPORTS_FILTER_LIST} from "../constants";

const formVoluumReportsData = (values, dateRangeEmpty, setCsvFileName, setTitle, voluumId) => {
    const {type, roi, cr, cost, operatorForRoi, operatorForCr, operatorForCost} = values;
    let reportType;
    if(!type || type === '') {
        reportType = VOLUUM_REPORTS_FILTER_LIST[0].id;
    } else if(Array.isArray(type)) {
        reportType = type[0].id;
    } else {
        reportType = type;
    }
    const dateFrom = dateRangeEmpty[0] && dateRangeEmpty[0] !== '' ? `${dateRangeEmpty[0].getFullYear()}-${(dateRangeEmpty[0].getMonth() + 1).toString().padStart(2, '0')}-${dateRangeEmpty[0].getDate().toString().padStart(2, '0')}` : '';
    const dateTo = dateRangeEmpty[1] && dateRangeEmpty[1] !== '' ? `${dateRangeEmpty[1].getFullYear()}-${(dateRangeEmpty[1].getMonth() + 1).toString().padStart(2, '0')}-${dateRangeEmpty[1].getDate().toString().padStart(2, '0')}` : '';
    setCsvFileName(`voluum_report_${reportType}_${dateFrom}_${dateTo}`);
    const reportTitle = VOLUUM_REPORTS_FILTER_LIST.filter(el => el.id === reportType)[0].name;
    setTitle(`Report ${reportTitle} ${dateFrom && dateFrom !== '' && dateTo && dateTo !== '' ? `from ${dateFrom} to ${dateTo}` : ''}`);
    return {
        type: reportType === '' ? VOLUUM_REPORTS_FILTER_LIST[0].id : reportType,
        voluumAccountId: voluumId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        roi: roi,
        cr: cr,
        cost: cost,
        operatorForRoi: operatorForRoi === '' && roi !== '' ? '>' : operatorForRoi !== '' && roi === '' ? '' : operatorForRoi,
        operatorForCr: operatorForCr === '' && cr !== '' ? '>' : operatorForCr !== '' && cr === '' ? '' : operatorForCr,
        operatorForCost: operatorForCost === '' && cost !== '' ? '>' : operatorForCost !== '' && cost === '' ? '' : operatorForCost
    }
}

export default formVoluumReportsData;
