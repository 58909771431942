import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './components/App';

import './reset.scss';
import './index.scss';

let container = null;

document.addEventListener('DOMContentLoaded', function() {
    if (!container) {
        container = document.getElementById('root');
        const root = ReactDOM.createRoot(container)
        root.render(
            <Provider store={store}>
                <App />
            </Provider>
        );
    }
});
