import {UPLOADER_TYPE_LINKS_FRONT_ONLY} from '../constants/index';

const prepareVoluumFormatCSVData = (type, data, voluumANId, diffColumn = false) => {
    let newData = [];
    if (type === UPLOADER_TYPE_LINKS_FRONT_ONLY) {
        data.forEach(obj => {
            const {
                offer_name,
                url,
                country_code,
                affiliate_network_id,
                payout,
                tags,
                offer_cap_enabled,
                daily_cap_value,
                offer_cap_time_zone,
                offer_cap_redirect_offer_id,
                workspace_id,
                currency_code,
                offer_cap_type,
                domain,
            } = obj;

            const newObj = {
                "1. Offer name": diffColumn ? domain : offer_name,
                "2. URL": url,
                "3. Country code": country_code,
                "4. Affiliate network ID": affiliate_network_id,
                "5. Payout": payout,
                "6. Tags": tags,
                "7. Offer cap enabled": offer_cap_enabled,
                "8. Daily cap value": daily_cap_value,
                "9. Offer cap time zone": offer_cap_time_zone,
                "10. Offer cap redirect offer ID": offer_cap_redirect_offer_id,
                "11. Workspace ID": workspace_id,
                "12. Currency code": currency_code,
                "13. Offer cap type": offer_cap_type
            }
            newData.push(newObj);
        })
    } else {
        data.forEach(obj => {
            const newObj = {
                "1. Offer name": diffColumn ? obj['domain'] : obj['offer_name'],
                "2. URL": obj['tracking_link'],
                "3. Country code": obj['countries'],
                "4. Affiliate network ID": voluumANId ? voluumANId : '',
                "5. Payout": '',
                "6. Tags": '',
                "7. Offer cap enabled": '',
                "8. Daily cap value": '',
                "9. Offer cap time zone": '',
                "10. Offer cap redirect offer ID": '',
                "11. Workspace ID": "",
                "12. Currency code": obj['currency'],
                "13. Offer cap type": ""
            }
            newData.push(newObj);
        })
    }
    return newData;
}

export default prepareVoluumFormatCSVData;