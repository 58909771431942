import {logOut} from "../redux/dataUploader/dataSlice";
import {getVoluumReportsService} from "../services/voluum";
import moveColumnToFirstPlace from "../helpers/move-column-to-first-place";

const getVoluumReportsData = async (
    data,
    setLoading,
    setError,
    dispatch,
    setData,
    setCsvData,
    setDataAvailability,
    setTitleStatus
) => {
    setLoading(true);

    try {
        const results = await getVoluumReportsService(data);
        if(results.data.error) {
            setDataAvailability(false);
            setTitleStatus(false);
            setError(true);
            setLoading(false);
        } else {
            const data = moveColumnToFirstPlace(results.data.data, 'voluum_name');
            if(results.data.data.length > 0) {
                setDataAvailability(true);
                setError(false);
                setTitleStatus(true)
                setData(data);
                setCsvData(data);
                setLoading(false);
            } else {
                setDataAvailability(false);
                setError(false);
                setTitleStatus(false);
                setData(data);
                setCsvData(data);
                setLoading(false);
            }
        }
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default getVoluumReportsData;