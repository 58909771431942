const sortAccParams = (accountArr, account) => {
    let result = {};
    if(account !== '' && accountArr.length > 0) {
        accountArr.forEach(el => {
            if(el.name.trim() === account) {
                result['api_key'] = el['api_key'];
                result['site_id'] = el['site_id'];
            }
        })
    } else if(account === '' && accountArr.length > 0) {
        result['api_key'] = accountArr[0]['api_key'];
        result['site_id'] = accountArr[0]['site_id'];

    }
    return result;
}


export default sortAccParams;
