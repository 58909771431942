import {Formik, Field, Form} from 'formik';
import Loader from "../../loaders/Loader";
import "../forms-styles.scss";
import "./new-user-form.scss";
import {useSelector} from "react-redux";

const NewUserForm = ({
                         initialValues,
                         currentValues,
                         onSubmit,
                         validateName,
                         validateEmail,
                         validatePassword,
                         validateRole,
                         isLoading
                     }) => {
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);

    return (
        <div className={`form-wrap form-new-user-wrap${!menuStatus ? ' collapse-menu' : ''}`}>
            {isLoading && <div className='form-loading'><Loader/></div>}
            <div className="form-inner-wrap">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}>
                    {({errors, touched}) => (
                        <Form>
                            <div className="form-flex-container">
                                <div className="form-left-block">
                                    <div className={`input-wrap ${errors.name && touched.name ? 'error-input' : ''}`}>
                                        <div className="input-block">
                                            <label htmlFor="name" className="label-name">
                                                <span className="content-name">Name</span>
                                            </label>
                                            <Field
                                                id="name"
                                                name="name"
                                                type="name"
                                                validate={validateName}
                                                className={currentValues.name !== '' ? 'filled' : ''}
                                            />
                                        </div>
                                        {errors.name && touched.name && <div className='error-text'>{errors.name}</div>}
                                    </div>
                                    <div className={`input-wrap ${errors.email && touched.email ? 'error-input' : ''}`}>
                                        <div className="input-block">
                                            <label htmlFor="email" className="label-name">
                                                <span className="content-name">Email</span>
                                            </label>
                                            <Field
                                                id="email"
                                                name="email"
                                                type="email"
                                                validate={validateEmail}
                                                className={currentValues.email !== '' ? 'filled' : ''}
                                            />
                                        </div>
                                        {errors.email && touched.email &&
                                            <div className='error-text'>{errors.email}</div>}
                                    </div>
                                    <div
                                        className={`input-wrap ${errors.password && touched.password ? 'error-input' : ''}`}>
                                        <div className="input-block">
                                            <label htmlFor="password" className="label-name">
                                                <span className="content-name">Password</span>
                                            </label>
                                            <Field
                                                id="password"
                                                type="password"
                                                name="password"
                                                validate={validatePassword}
                                                className={currentValues.password !== '' ? 'filled' : ''}
                                            />
                                        </div>
                                        {errors.password && touched.password &&
                                            <div className='error-text'>{errors.password}</div>}
                                    </div>
                                </div>
                                <div className="form-right-block">
                                    <div className={`input-wrap ${errors.role && touched.role ? 'error-input' : ''}`}>
                                        <div className="input-block">
                                            <label htmlFor="role" className="label-name">
                                                <span className="content-name">Role</span>
                                            </label>
                                            <Field
                                                id="role"
                                                name="role"
                                                type="role"
                                                validate={validateRole}
                                                className={currentValues.role !== '' ? 'filled' : ''}
                                            />
                                        </div>
                                        {errors.role && touched.role && <div className='error-text'>{errors.role}</div>}
                                    </div>
                                </div>
                            </div>
                            <button className="blue-btn form-submit" type="submit" disabled={isLoading}>Submit</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default NewUserForm;