import React from "react";
import './clear-filters.scss';

const ClearFilters = ({clearFilters, disabled, text = "Clear all filters"}) => {
    return (
        <button className='clear-filters-btn transparent-btn' onClick={clearFilters} disabled={disabled}>
            <img src={`${process.env.PUBLIC_URL}/images/clear-filters.svg`} alt="clear all filters"/>
            <span>{text}</span>
        </button>
    )
}

export default ClearFilters;
