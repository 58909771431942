const compareVoluumObj = (curObj, newObj) => {
    for (let key in curObj) {
        if (newObj.hasOwnProperty(key)) {
            if (curObj[key] !== newObj[key]) {
                return false;
            }
        }
    }
    return true;
}

export default compareVoluumObj;