import React from 'react';
import {useSelector} from "react-redux";

const ErrorFallback = ({ error }) => {
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);

    return (
        <>
            <div className={`home-bg-container error-handler-container gradient-container${!menuStatus ? ' collapse-menu' : ''}`}>
                <h3>Ooops...</h3>
                <div>Something went wrong: {error.message}.<br />Please try again later.</div>
            </div>
        </>
    )
};

export default ErrorFallback;