import React, {useState, useEffect, useMemo} from 'react';
import {useLocation} from "react-router-dom";
import CustomTooltip from "../../CustomTooltip";
import Table from "../Table";
import roundToFourDecimals from "../../../helpers/round-to-four-decimals";
import {
    AWIN_STATUS_ACTIVE,
    AWIN_STATUS_HIDDEN,
    DISCOVERY_TOOL_STATUSES_TO_CHANGE,
    DISCOVERY_TOOL_TYPE_CAMPAIGNS,
    filterParams
} from "../../../constants";

const PreTableInfected = ({
                              rowData,
                              type,
                              selectedItems,
                              onFilterChanged,
                              onSortChanged,
                              getTableRef,
                              onChange,
                              onChangeAll,
                              rowSelection,
                              onSelectionChanged
}) => {
    const {pathname} = useLocation();
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
        if (rowData && rowData.length > 0) {
            const colArr = Object.entries(rowData[0]).map(([field, value]) => {
                let columnDef = {
                    field: field,
                    headerName: field.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
                    filter: !isNaN(value) && value !== '' ? 'agNumberColumnFilter' : true,
                    wrapText: true,
                    tooltipField: field,
                    tooltipComponent: CustomTooltip,
                    valueFormatter: params => !isNaN(value) && value !== '' ? roundToFourDecimals(params.value, true) : null,
                    comparator: !isNaN(value) && value !== '' ? (valueA, valueB) => valueA - valueB : null
                };

                if (field === 'domain' || field === 'reason') {
                    columnDef = {
                        ...columnDef,
                        maxWidth: 200
                    };
                } else if (field === 'string') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 600
                    };
                } else if (field === 'id') {
                    columnDef = {
                        ...columnDef,
                        width: 90
                    };
                } else if (field === 'kpi_average_payment_time') {
                    columnDef = {
                        ...columnDef,
                        filter: 'agNumberColumnFilter',
                        comparator: (valueA, valueB) => valueA - valueB
                    };
                } else if (field === 'click_through_url') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 450
                    };
                } else if (field === 'created_at' || field === 'updated_at') {
                    columnDef = {
                        ...columnDef,
                        filter: 'agDateColumnFilter',
                        filterParams: filterParams,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return value.replace('.000000Z', '').replace('T', ' ')
                        }
                    };
                } else if (field === 'status' && value && pathname.includes('approval')) {
                    columnDef = {
                        ...columnDef,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return (
                                <span className={`status-cell${value.toLowerCase() === AWIN_STATUS_ACTIVE ? ' active-cell' : value.toLowerCase() === AWIN_STATUS_HIDDEN ? ' deactivated-cell' : ' not-found-cell'}`}>
                                    {value}
                                </span>
                            )
                        }
                    };
                } else if (field === 'voluum_account_id') {
                    columnDef = {
                        ...columnDef,
                        hide: true
                    };
                } else if (field === 'voluum_name') {
                    columnDef = {
                        ...columnDef,
                        headerName: 'account'
                    };
                } else if (field === 'an_offer_link') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 600
                    };
                } else if (field === 'status' && type && type !== DISCOVERY_TOOL_TYPE_CAMPAIGNS && pathname.includes('discovery')) {
                    columnDef = {
                        ...columnDef,
                        headerComponent: selectedItems.length > 0 ? headerComponent : null,
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        headerCheckboxSelectionCurrentPageOnly: true,
                        checkboxSelection: params => DISCOVERY_TOOL_STATUSES_TO_CHANGE.includes(params.data.status),
                        minWidth: selectedItems.length > 0 ? 260 : 100,
                        cellRenderer: params => {
                            let arr;
                            const {value} = params;
                            const {id} = params.data;
                            if(value === 'new') {
                                arr = [value, 'deactivated'];
                            } else if(value === 'in_discovery') {
                                arr = [value, 'completed', 'deactivated'];
                            } else {
                                arr = [];
                            }

                            if(selectedItems.length > 0) {
                                return value;
                            } else {
                                return (
                                    arr.length > 0 ?
                                        <select id={id} name="discovery_tool_statuses" defaultValue={value} onChange={onChange ? onChange : null}>
                                            {arr.map((el, i) => {
                                                return <option key={el + i} value={el}>{el}</option>
                                            })}
                                        </select> :
                                        value
                                );
                            }
                        }
                    };
                }
                return columnDef;
            });
            setColumnDefs(colArr);
        }
    }, [rowData, selectedItems]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        autoHeight: true,
    }));

    const headerComponent = () => {
        return (
            <div className='header-component-block'>
                <span>New status for chosen items ({selectedItems.length})</span>
                <button
                    className='blue-btn'
                    onClick={e => onChangeAll(e, selectedItems)}
                >
                    deactivate
                </button>
            </div>
        )
    }

    return (
        <Table
            rowData={rowData}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            getTableRef={getTableRef}
            rowSelection={rowSelection}
            onSelectionChanged={onSelectionChanged}
        />
    );
};

export default PreTableInfected;
