import React from "react";

const ClearFilters = ({clearResults}) => {
    return (
        <button className='transparent-btn' onClick={clearResults}>
            <span>Clear Results</span>
        </button>
    )
}

export default ClearFilters;
