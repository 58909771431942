const roundToFourDecimals = value => {
    if(value === null) return null
    else if(value === '') return ''
    else if (!isNaN(value)) {
        const roundedValue = Math.round(value * 10000) / 10000;
        return roundedValue.toString();
    }
};

export default roundToFourDecimals;
