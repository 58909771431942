import {logOut} from "../redux/dataUploader/dataSlice";
import {
    getClosedCampaignPathService,
    getClosedCampaignFlowsService,
    getClosedCampaignCampaignsService
} from "../services/voluum";
import {FLOWS_TYPE, PATH_TYPE, CAMPAIGNS_TYPE} from "../constants";

const GetClosedCampaignDetailsData = async (setLoading, setError, dispatch, setData, setChangedData, state, type) => {
    setLoading({
        path: true,
        flow: true,
        campaign: true
    });

    try {
        let results;
        if (type === PATH_TYPE) {
            results = await getClosedCampaignPathService(state);
        } else if (type === FLOWS_TYPE) {
            results = await getClosedCampaignFlowsService(state);
        } else if (type === CAMPAIGNS_TYPE) {
            results = await getClosedCampaignCampaignsService(state);
        }
        if (results.data.error) {
            setError(true);
        } else {
            setData(prevState => ({
                ...prevState, [type]: results['data'].data
            }));

            setChangedData(prevState => ({
                ...prevState, [type]: results['data'].data
            }));
        }
        setLoading(prevState => ({
            ...prevState,
            [type]: false
        }));
    } catch (error) {
        setLoading(prevState => ({
            ...prevState,
            [type]: false
        }));
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default GetClosedCampaignDetailsData;