import fetchData from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const userResetPassword = async (userId, oldPassword, newPassword) => {
    const url = `/user-reset-password`;
    const res = await fetchData.post(
        url,
        {
            "userId": userId,
            "oldPassword": oldPassword,
            "newPassword": newPassword
        },
        {
            headers: {
                'Authorization': getAuthorizationHeader()
            }
        });
    return res;
}

export default userResetPassword;