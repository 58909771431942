export const setFormHint = (alertName, name, value, method) => {
    switch (name) {
        case 'conversions':
            return (<p className='form-hint'>Conversions of less than {value} will be alerted</p>)
        case 'cost':
            return (<p className='form-hint'>Offers with Cost of more than ${value}</p>)
        case 'visits':
            return (<p className='form-hint'>Number of Visits during the Visit Period</p>)
        case 'visitsPeriod':
            return (<p className='form-hint'>Visits Period of Last {value} days will be checked</p>)
        case 'roi':
            return (<p className='form-hint'>ROI of {method === 'checkRealOffersWithRoiAndRevenue' ? 'less' : 'more'} than {value}% will be alerted</p>)
        case 'period':
            return (<p className='form-hint'>Last {value} days will be checked</p>)
        case 'offersTrafficPercentDown':
            return (<p className='form-hint'>{alertName === 'Alert 6' || alertName === 'Alert 7' ? `Set the percentage by which the ROI in a specific mobile ${alertName === 'Alert 6' ? 'carrier' : 'devices'} should be lower than the overall ROI to trigger an alert.` : `The gap of ${value}% of Offers Traffic`}</p>)
        default:
            return null
    }
}
