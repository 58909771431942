import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DeleteSettingsViewData} from "../../data-collectors/settings-view-hooks";
import {FILTERS_TYPE_VOLUUM_REPORT} from "../../constants";
import {USER_ID} from "../../constants/Auth";
import {settingsViewSelected} from "../../redux/dataUploader/dataSlice";
import Loader from '../../components/loaders/TableLoader';

import './custom-editable-select.scss';

const CustomEditableSelect = ({
                                  data,
                                  setSettingsList,
                                  setSettingsView,
                                  settingsStatus,
                                  setSettingsStatus,
                                  setSettingsName,
                                  nameToEdit,
                                  isViewLoading,
                                  setViewLoading,
                                  isViewError,
                                  setViewError,
                                  viewErrorText,
                                  setViewErrorText
}) => {
    const dispatch = useDispatch();
    const userId = localStorage.getItem(USER_ID);
    const optionSelected = useSelector(state => state.dataUploader.settingsViewSelected);
    const [showOptionList, setShowOptionList] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            if(optionSelected === '') {
                dispatch(settingsViewSelected(data[0].name));
                setSettingsView(data[0]['filters']);
            }
        }
    }, [data]);

    const handleListDisplay = e => {
        if(!e.target.closest('.delete-icon')) setShowOptionList(!showOptionList);
    };

    const handleOptionClick = e => {
        e.preventDefault();
        setViewError(false);
        if(e.target.matches('.custom-select-option .delete-icon') || e.target.matches('.custom-select-option img')) {
            setShowOptionList(true);
        } else if(e.target.matches('.custom-select-option')) {
            setShowOptionList(false);
            setSettingsStatus(false);
            const optionName = e.target.getAttribute("data-name");
            dispatch(settingsViewSelected(optionName));
            nameToEdit(optionName);
            setSettingsView(data.filter(el => el.name === optionName)[0]['filters']);
        }
    };

    const deleteItem = e => {
        const name = e.target.closest('.custom-select-option').getAttribute("data-name");
        DeleteSettingsViewData(data, setViewLoading, setViewError, setSettingsList, dispatch, userId, name, FILTERS_TYPE_VOLUUM_REPORT, setSettingsView);
    }

    const onChange = (e) => {
        const value = e.target.value;
        let error;
        const nameRegex = /^(?=.*[\dA-Za-z'-])[\dA-Za-z\s'-]+$/;
        if (!value && value === '') {
            error = 'Required';
        } else if (!nameRegex.test(value)) {
            error = 'No special symbols';
        } else if (value.length < 2 || value.length > 30) {
            error = 'Invalid name';
        }
        setViewError(true);
        setViewErrorText(error);
        setSettingsName(value);
    }

    if(data.length === 0 && !settingsStatus) {
        return null;
    } else {
        return (
            <div className={`transparent-btn category-dropdown editable-dropdown ${showOptionList ? 'active' : ''}`} onClick={handleListDisplay}>
                <div className={`selected-text ${showOptionList ? 'active' : ''}`}>
                    {settingsStatus ?
                        <input type='text' name='settings-name' defaultValue={optionSelected} onChange={e => onChange(e)} /> :
                        optionSelected
                    }
                    {isViewLoading ? <Loader /> : null}
                    {isViewError && viewErrorText === '' ? <span className='delete-error' title='error'>!</span> : null}
                    {isViewError && viewErrorText !== '' ? <span className='delete-error delete-error-text' title='error'>{viewErrorText}</span> : null}
                </div>
                {showOptionList && (
                    <ul className='custom-select-option-container'>
                        <li
                            className="custom-select-option"
                            data-name={optionSelected}
                            hidden
                        >
                            {optionSelected}
                        </li>
                        {data.map((option, i) => {
                            return (
                                <li
                                    className="custom-select-option"
                                    data-name={option.name}
                                    key={i}
                                    onClick={e => handleOptionClick(e)}
                                >
                                    {option.name}
                                    <button type='button' className="delete-icon" onClick={deleteItem}>
                                        <img src={`${process.env.PUBLIC_URL}/images/delete-btn.svg`} alt="delete"/>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }

};

export default CustomEditableSelect;
