import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import '../buttons.scss';
import './excel-link.scss'

const ExcelDownload = ({name, titles, data, additionalData}) => {
    const date = useSelector((state) => state.dataUploader.date);
    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
        let jsonData = [];
        if (data && data.length > 0 && titles && titles.length > 0 && additionalData && additionalData.length > 0) {
            data.forEach((el, i) => {
                let obj = {};
                if(titles[i]) obj.sheetName = titles[i].slice(0, 31);
                if(typeof el[0] === "string") {
                    obj.details = [{'info': el[0]}];
                    obj.extra = additionalData[i];
                } else if(el.length === 0) {
                    obj.details = [{'info': 'no data for this alert'}];
                    obj.extra = [additionalData[i]];
                } else {
                    if(Object.keys(el[0])[0] !== Object.keys(el[el.length - 1])[0]) {
                        obj.details = el.slice(0, el.length - 1);
                        obj.extra = additionalData[i];
                    } else {
                        obj.details = el;
                        obj.extra = additionalData[i];
                    }
                }
                jsonData.push(obj);
            })
            setExcelData(jsonData);
        } else {
            if (data && data.length > 0 && titles && titles.length > 0) {
                data.forEach((el, i) => {
                    let obj = {};
                    if(titles[i]) obj.sheetName = titles[i].slice(0, 31);
                    if(typeof el[0] === "string") {
                        obj.details = [{'info': el[0]}];
                    } else if(el.length === 0) {
                        obj.details = [{'info': 'no data for this alert'}];
                    } else {
                        if(Object.keys(el[0])[0] !== Object.keys(el[el.length - 1])[0]) {
                            obj.details = el.slice(0, el.length - 1);
                        } else {
                            obj.details = el;
                        }
                    }
                    jsonData.push(obj);
                })
                setExcelData(jsonData);
            }
        }
    }, [data, titles, additionalData]);

    const downloadExcel = data => {
        const wb = XLSX.utils.book_new();

        data.forEach(el => {
            const ws = XLSX.utils.json_to_sheet(el.details);
            XLSX.utils.sheet_add_json(ws, [{}], {origin:-1});
            if(el.extra) XLSX.utils.sheet_add_json(ws, el.extra, {origin:-1});
            XLSX.utils.book_append_sheet(wb, ws, el.sheetName);
        })

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, `${name}_${date.toISOString().split('T')[0]}.xlsx`);
    }

    return (
        <div className='csv-btn-wrap'>
            <button className='blue-btn' onClick={() => downloadExcel(excelData)}>
                Download as Excel
            </button>
        </div>
    )
};

export default ExcelDownload;

