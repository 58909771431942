import React from "react";

import './doubled-checkbox.scss';


const CustomDoubledCheckbox = ({title, insideTextClass, value, getValue, disabled, style, className}) => {
    return (
        <div className={`doubled-checkbox-block${disabled ? ' disabled' : ''} ${className}`}>
            {title ? <p>{title}</p> : null}
            <input type="checkbox" style={style} className={insideTextClass} onChange={getValue} checked={value} disabled={disabled ? disabled : false}/>
        </div>
    )
}

export default CustomDoubledCheckbox;
