import {useEffect, useState} from "react";
import Select from "react-select";
import DataTable from "../../components/tables/DataTable";
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination";
import AffiliateNetworkOffersTableFilter from "../../components/AffiliateNetworkOffersTableFilter";
import {
    orderDirectionOptions,
    rowsLimitOptions,
} from "./table-options";
import "./affiliate-network-offers.scss";
import MessageSendingData from "../../components/popups/MessageSendingData";

const AffiliateNetworkOffers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [restOffersData, setRestOffersData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowsLimit, setRowsLimit] = useState({value: "10", label: "10 / page"});
    const [totalRows, setTotalRows] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        orderDirection: orderDirectionOptions[0],
        searchInColumns: {},
    });
    const [orderOptions, setOrderOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({
        id: localStorage.getItem("id"),
        name: localStorage.getItem("name"),
    })
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [confirmationPopupText, setConfirmationPopupText] = useState("")
    const [usersList, setUsersList] = useState([])
    const [anTypes, setAnTypes] = useState([])
    const [restData, setRestData] = useState(null)
    const [anAccounts, setAnAccounts] = useState([])
    const [selectedANType, setSelectedANType] = useState(null)
    const [selectedANAccount, setSelectedANAccount] = useState(null)
    const loggedUserEmail = localStorage.getItem("email")

    const [parsedOffersData, setParsedOffersData] = useState([]);
    const [searchByColumnFields, setSearchByColumnFields] = useState([]);
    const [filterParams, setFilterParams] = useState({});
    let [tags, setTags] = useState([]);


    const selectStyles = {
        container: (baseStyles) => ({
            ...baseStyles,
            maxWidth: "180px",
            width: "100%",
            margin: "0 20px 0 0",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            minHeight: "26px",
            border: "none",
            borderRadius: "8px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontWeight: 500,
            color: "#1A3353",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }

    const rowsLimitSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        })
    }

    useEffect(() => {
        if (totalRows > 0) {
            setTotalPagesCount(Math.ceil(totalRows / rowsLimit.value));
        } else {
            setTotalPagesCount(1);
        }
        setCurrentPage(1);
    }, [totalRows, rowsLimit]);

    /* fetch user services */
    useEffect(() => {
        setIsLoading(true);

        // initial fetch
        fetch(`${process.env.REACT_APP_API_URL}api/user/${selectedUser.id}/services/`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
            .then(res => {

                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setSelectedUser({id: res.id, name: res.name})
                setUsersList([...res["user_managers_accounts"]])
                setAnTypes([])
                setAnAccounts([])
                if (res["user_services"] && res["user_services"].length > 0) {
                    let temp = []
                    let types = [] // for unique types
                    res["user_services"].forEach(item => {
                        if (item?.types?.name && !types.includes(item?.types?.name)) {
                            types.push(item?.types?.name)
                            temp.push({value: item?.types?.name, label: item?.types?.name})
                        }
                    })
                    setAnTypes(temp)
                }

                setRestData(res)
                setIsLoading(false)
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }, []);

    /* fetch offer_directions */
    useEffect(() => {
        if (restOffersData.data) {
            setIsLoading(true);

            let urlOrderParam = ""
            if (selectedFilters.orderDirection && selectedFilters.orderBy) {
                urlOrderParam = `&offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`
            }

            let urlColumnsToSearchParam = {};
            if (filterParams) {
                urlColumnsToSearchParam = {...filterParams};
            }


            let urlParams = `
                ?size=${rowsLimit.value}
                &page=${currentPage}
                ${urlOrderParam}
                &email=${loggedUserEmail}
                &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
            `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks


            // fetch offers on filters change
            if (selectedANAccount) {
                fetch(`${process.env.REACT_APP_API_URL}api/affiliate-network/offers/${selectedANAccount.value}/${selectedUser.id}/${urlParams}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                })
                    .then(res => {
                        if (res.ok) {
                            return res.json()
                        }
                        return Promise.reject(res)
                    })
                    .then(res => {
                        setRestOffersData(res.offers)
                        let temp = []

                        res["sortable_columns"].forEach(item => {
                            temp.push({value: item, label: item.replaceAll("_", " ")})
                        })
                        setOrderOptions(temp)
                        setIsLoading(false);
                    })
                    .catch(res => {
                        setIsLoading(false)
                        setConfirmationPopupText("Something went wrong")
                        setShowConfirmationPopup(true)
                    })
            }
        }
    }, [rowsLimit, currentPage, selectedFilters, filterParams, tags]);

    useEffect(() => {
        if (restOffersData?.data) {
            let temp = [];
            if (restOffersData.data.length !== 0) {
                for (let key in restOffersData.data[0]) {
                    let width = "20%";
                    if (key === "offer_tracking_link") width = "1500px";
                    temp.push({
                        label: key.replaceAll("_", " "),
                        renderCell: (item) => <span
                            className={"data-table-cell"}
                            title={"Click to copy"}
                            onClick={() => handleClickToCopy(item[key])}
                        >
                            {item[key]}
                        </span>,
                        resize: true,
                        key: key,
                        width: width,
                    });
                }
            }
            if (temp.length > 0) {
                Array.prototype.move = function (from, to) {
                    this.splice(to, 0, this.splice(from, 1)[0]);
                };

                // move Offer Tracking Link column to the end
                let index = temp.findIndex(item => item.key === "offer_tracking_link")
                temp.move(index, temp.length - 1)

                // move Offer Name column to the second position
                index = temp.findIndex(item => item.key === "offer_name")
                temp.move(index, 1)
            }
            setColumnDefs(temp);
            setTotalRows(restOffersData["total"])
            setTotalPagesCount(restOffersData["last_page"])
        }
    }, [restOffersData]);

    /*  Psrsing offer_country and offer_domain fields  */
    useEffect(() => {
        parsingArrayFields();
    }, [restOffersData.data])

    const handleDownloadCsv = () => {
        setIsLoading(true)

        let urlOrderParam = ""
        if (selectedFilters.orderDirection && selectedFilters.orderBy) {
            urlOrderParam = `offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`
        }


        let urlColumnsToSearchParam = {};
        if (filterParams) {
            urlColumnsToSearchParam = {...filterParams};
        }


        const urlParams = `
            ${urlOrderParam}
            &email=${loggedUserEmail}
            &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
        `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks


        fetch(`${process.env.REACT_APP_API_URL}api/affiliate-network/download-offers/${selectedANAccount.value}/${selectedUser.id}/?${urlParams}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setConfirmationPopupText(res.message)
                setShowConfirmationPopup(true)
                setIsLoading(false);
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })

    }


    const parsingArrayFields = (offers = restOffersData.data) => {
        const currentOffers = offers;
        const correctOffers = [];
        if (currentOffers && offers.length) {
            setCurrentFilterKeys(offers[0]);

            for (let offer of currentOffers) {
                let correctCountryRow = "";
                let correctDomainRow = "";

                if (offer.offer_country) {
                    let parsedData = JSON.parse(offer.offer_country)
                    for (let country of parsedData) {
                        if (parsedData.length === 1) {
                            correctCountryRow = country
                        } else {
                            correctCountryRow += country + ", "
                        }
                    }
                }

                if (offer.offer_domain) {
                    let parsedData = JSON.parse(offer.offer_domain)
                    for (let domain of parsedData) {
                        if (parsedData.length === 1) {
                            correctDomainRow = domain
                        } else {
                            correctDomainRow += domain + ", "
                        }
                    }
                }

                correctOffers.push(
                    {
                        ...offer,
                        offer_country: correctCountryRow,
                        offer_domain: correctDomainRow
                    });
                setParsedOffersData(correctOffers)
            }
        }
    }

    const handleChangeSelectedUser = (user) => {
        setIsLoading(true);
        setSelectedANType(null)
        setSelectedANAccount(null)
        setParsedOffersData([])

        let filters = {
            ...selectedFilters,
            searchInColumns: {}
        };
        setSelectedFilters(filters);


        // fetch user services
        fetch(`${process.env.REACT_APP_API_URL}api/user/${user.value}/services`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setSelectedUser({
                    id: res.id,
                    name: res.name
                })
                setAnTypes([])
                setAnAccounts([])
                setRestOffersData([])
                if (res["user_services"] && res["user_services"].length > 0) {
                    let temp = []
                    let types = [] // for unique types
                    res["user_services"].forEach(item => {
                        if (item?.types?.name && !types.includes(item?.types?.name)) {
                            types.push(item?.types?.name)
                            temp.push({value: item?.types?.name, label: item?.types?.name})
                        }
                    })
                    setAnTypes(temp)
                }
                setRestData(res)
                setIsLoading(false);
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }

    const handleChangeANType = (option) => {
        setSelectedANType(option)
        let temp = []
        if (restData["user_services"] && restData["user_services"].length > 0) {
            temp = restData["user_services"].filter(item => item?.types?.name === option.value)
            temp = temp.reduce((acc, item) => {
                acc.push({value: item.id, label: item.name})
                return acc
            }, [])
        }
        setAnAccounts(temp)
        setSelectedANAccount(null)
    }

    const handleChangeANAccount = (option) => {
        setSelectedANAccount(option);
        setIsLoading(true);

        // TODO: this filter reset cause redundant API call. Should figure out how to avoid it.
        let filters = {
            ...selectedFilters,
            searchInColumns: {}
        };
        setSelectedFilters(filters);


        const accountID = option.value
        fetch(`${process.env.REACT_APP_API_URL}api/affiliate-network/offers/${accountID}/${selectedUser.id}/?size=${rowsLimit.value}&email=${loggedUserEmail}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(res => {
                if (res.status !== "error" && res.offers.data.length !== 0) {
                    setRestOffersData(res.offers)
                    setCurrentFilterKeys(res.offers.data[0])

                    let filters = {
                        ...selectedFilters,
                        searchInColumns: {}
                    };
                    setSelectedFilters(filters);
                    const filteredColumnsByOrder = res["sortable_columns"].filter(col => col !== "offer_country");

                    setOrderOptions(filteredColumnsByOrder.reduce((acc, item) => {
                        acc.push({value: item, label: item.replaceAll("_", " ")})
                        return acc
                    }, []))
                } else {
                    setRestOffersData([])
                    console.error("There are no offers for this account")
                }
                setIsLoading(false)
            })
            .catch(res => {
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            })
    }


    const setCurrentFilterKeys = (offer) => {
        const filterKeys = Object.keys(offer);
        let filteredKeys = filterKeys.filter(f => f !== "offer_tracking_link");
        let filterValuesArr = [];

        for (let filterKey of filteredKeys) {

            const fieldLabel = filterKey.replaceAll("_", " ");
            const fieldType = (filterKey === "created_at"
                || filterKey === "updated_at"
                || filterKey === "last_deactivation_date"
                || filterKey === "last_activation_date") ? "date" : "string";

            filterValuesArr.push({
                value: filterKey,
                label: fieldLabel,
                type: fieldType,
            })
        }

        if (filterValuesArr.length > 0) {
            setSearchByColumnFields(filterValuesArr);
        }
    }


    const handleGettingOfferWithSearchParams = async (filters = {}) => {
        setIsLoading(true);

        let urlOrderParam = ""
        if (selectedFilters.orderDirection && selectedFilters.orderBy) {
            urlOrderParam = `offerSortColumn=${selectedFilters.orderBy.value}&offerSortDirection=${selectedFilters.orderDirection.value}`
        }


        let urlColumnsToSearchParam = {};
        if (filterParams) {
            urlColumnsToSearchParam = {...filters};
        }

        const urlParams = `
            ${urlOrderParam}
            &email=${loggedUserEmail}
            &where=${encodeURI(JSON.stringify(urlColumnsToSearchParam))}
        `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks


        fetch(`${process.env.REACT_APP_API_URL}api/affiliate-network/offers/${selectedANAccount.value}/${selectedUser.id}?${urlParams}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setTotalRows(res.offers.total);
                setParsedOffersData(res.offers.data);
                parsingArrayFields(res.offers.data);
            })
            .catch(e => console.error(e))


        setIsLoading(false);

    }


    const handleChangeSelectedFilters = (value) => {
        setSelectedFilters(value)
    }

    const handleClearSearchByColumnFilter = () => {
        setIsLoading(true);
        let temp = {
            ...selectedFilters,
            searchInColumns: {}
        };

        setFilterParams({});
        setTags([]);
        setSelectedFilters(temp);
        setIsLoading(false);
    }

    const handleClickToCopy = (value) => {
        navigator.clipboard.writeText(value).then(() => {
        }, () => {
            console.error('Failed to copy');
        });
    }

    if (isLoading) return <Loader/>

    return (
        <>
            <div className={`affiliate-network-offers-container`}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 10px",
                }}>

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={usersList.reduce((acc, item) => {
                            acc.push({value: item.id, label: item.name})
                            return acc
                        }, [])}
                        isSearchable={true}
                        defaultValue={{value: selectedUser.id, label: selectedUser.name}}
                        name={"user"}
                        placeholder={"User"}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeSelectedUser(option)}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anTypes}
                        isSearchable={true}
                        defaultValue={selectedANType}
                        name={"affiliate_network_type"}
                        placeholder={"AN Type"}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeANType(option)}
                    />

                    <Select
                        className={""}
                        styles={selectStyles}
                        options={anAccounts}
                        isSearchable={true}
                        defaultValue={selectedANAccount}
                        name={"affiliate_network_account"}
                        placeholder={"AN Account"}
                        value={selectedANAccount}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(option) => handleChangeANAccount(option)}
                    />

                    <button
                        className={`blue-btn ${!Boolean(restOffersData["data"]) ? "disabled" : ""}`}
                        onClick={handleDownloadCsv}
                        disabled={!Boolean(restOffersData["data"])}
                    >
                        Download CSV
                    </button>
                </div>




                    <>
                        <AffiliateNetworkOffersTableFilter
                            orderByOptions={orderOptions}
                            orderDirectionOptions={orderDirectionOptions}
                            searchByColumnOptions={searchByColumnFields}
                            selectedFilters={selectedFilters}
                            handleGettingOfferWithSearchParams={handleGettingOfferWithSearchParams}
                            changeSelectedFilters={handleChangeSelectedFilters}
                            clearSearchFilters={handleClearSearchByColumnFilter}
                            setFilterParams={setFilterParams}
                            filterParams={filterParams}
                            setTags={setTags}
                            tags={tags}
                        />


                        <DataTable
                            data={parsedOffersData}
                            columnDefs={columnDefs}
                        />
                    </>



                {restOffersData.data && (
                    <div className="data-table-footer">
                        {rowsLimitOptions.length > 0 && (
                            //TODO: винести в окремий компонент
                            <Select
                                className={"data-table-rows-limit"}
                                styles={rowsLimitSelectStyles}
                                options={rowsLimitOptions}
                                isSearchable={false}
                                defaultValue={rowsLimit}
                                name={"rows_count"}
                                onChange={(option) => {
                                    setRowsLimit(option);
                                }}
                            />
                        )}
                        <div className={"data-table-rows-count"}>Total rows: <strong>{totalRows}</strong></div>
                        <Pagination
                            totalPagesCount={totalPagesCount}
                            setCurrentPage={(value) => setCurrentPage(value)}
                            currentPage={currentPage}
                        />
                    </div>
                )}
            </div>
            {showConfirmationPopup && (
                <MessageSendingData
                    messageText={confirmationPopupText}
                    setClosePopup={() => setShowConfirmationPopup(false)}
                />
            )}
        </>
    )
}

export default AffiliateNetworkOffers;
