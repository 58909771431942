const extractCapInformation = (params, condition) => {
    const valueStatus = params['status_for_action'];
    const valueAction = params['action'];
    const valueReason = params['changed_reason'];
    if(valueStatus && valueAction && valueReason) {
        const valueReasonArr = valueReason.split('|');
        if(valueReasonArr.length > 0) {
            if(condition === 'clicks_num') {
                return valueReasonArr[1].includes('(') ? valueReasonArr[1].slice(1, -1).split('/')[0] : '';
            } else if(condition === 'cap_limit'){
                return valueReasonArr[1].includes('(') ? valueReasonArr[1].slice(1, -1).split('/')[1] : '';
            } else if(condition === 'cap_action' && (valueReasonArr[0] && valueReasonArr[0].includes('cap'))){
                if(valueAction === 'auto' && valueStatus === 'activated') {
                    return 'activated';
                } else if(valueAction === 'auto' && valueStatus === 'deactivated') {
                    if(valueReasonArr[1].includes('(')) {
                        const clicksNum = valueReasonArr[1].slice(1, -1).split('/')[0];
                        return `deactivated when reached ${clicksNum} ${clicksNum === '1' ? 'click' : 'clicks'}`;
                    } else {
                        return 'deactivated';
                    }
                } else if(valueAction === 'manual' && valueStatus === 'deactivated') {
                    return 'to be deactivated';
                } else if(valueAction === 'manual' && valueStatus === 'activated') {
                    return 'to be reactivated';
                }
            }
        }
    }
}

export default extractCapInformation;
