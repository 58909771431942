import fetchData from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const yieldkitOffers = async (email, api_key, api_secret, site_id, unique) => {
    const res = await fetchData.post('/process-sites/get-yieldkit-offers', {email, api_key, api_secret, site_id, unique}, {
        headers: { 'Authorization': getAuthorizationHeader() },
    });
    return res;
}

export default yieldkitOffers;