import {logOut, settingsViewSelected} from "../redux/dataUploader/dataSlice";
import {getSettingsViewService, saveSettingsViewService, deleteSettingsViewService} from "../services/voluum";
import {FILTERS_TYPE_VOLUUM_REPORT} from "../constants";

export const GetSettingsViewData = async (setLoading, setError, dispatch, setData, id, type) => {
    setLoading(true);
    try {
        let results;
        if(type === FILTERS_TYPE_VOLUUM_REPORT) {
            results = await getSettingsViewService({id: id, type: type});
        }
        if(results.data.error) {
            setError(true);
        } else {
            if(results.data.data.length > 0) setData(results.data.data);
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export const SaveSettingsViewData = async (settingList, setLoading, setError, dispatch, setData, setSettingsStatus, data, type) => {
    setLoading(true);
    try {
        let results;
        if(type === FILTERS_TYPE_VOLUUM_REPORT) {
            results = await saveSettingsViewService(data);
        }
        if(results.data.error) {
            setError(true);
        } else if(results.data.data && results.data.data !== '') {
            if(settingList.filter(el => el.name === data.name).length === 0) {
                setData([data, ...settingList]);
            } else {
                setData(settingList);
            }
            setSettingsStatus(false);
            dispatch(settingsViewSelected(data.name));
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export const DeleteSettingsViewData = async (settingList, setLoading, setError, setData, dispatch, id, name, type, setSettingsView) => {
    setLoading(true);
    try {
        let results;
        if(type === FILTERS_TYPE_VOLUUM_REPORT) {
            results = await deleteSettingsViewService({id: id, name: name, type: type});
        }
        if(results.data.error) {
            setError(true);
        } else if(results.data.data && results.data.data.message !== '') {
            setData(settingList.filter(el => el.name !== name));
            if(settingList.filter(el => el.name !== name).length > 0) {
                dispatch(settingsViewSelected(settingList.filter(el => el.name !== name)[0].name));
                setSettingsView(settingList.filter(el => el.name !== name)[0]['filters']);
            }
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}
