import {logOut} from "../redux/dataUploader/dataSlice";
import {addAccountDetailsService} from "../services/yieldkit";
import {modifyTableData} from '../helpers/modify-accounts-data';

const createAccountData = async (setFormLoading, setSuccessStatus, setFormError, dispatch, data, accountDetails, setAccountDetails) => {
    setFormLoading(true);

    try {
        const copyAccountsData = [...accountDetails];
        const {id, ...other} = data;
        const newAccountData = {
            'data': {...other}
        }

        const results = await addAccountDetailsService(newAccountData);
        if(results.data.error) {
            setFormError(true)
        } else {
            setSuccessStatus(true);
        }
        setFormError(results.data.error);
        setSuccessStatus(!results.data.error);

        const modifiedData = modifyTableData(results.data.data);
        copyAccountsData.push(modifiedData[0]);
        setAccountDetails(copyAccountsData);
        setFormLoading(false);
    } catch (error) {
        setFormLoading(false);
        setFormError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
};

export default createAccountData;