import "./dt-change-status-popup.scss"
import Select from "react-select";
import {useState} from "react";

const DTChangeStatusPopup = ({statusOptions = [], onConfirm, onCancel}) => {

    const [selectedStatus, setSelectedStatus] = useState(null);

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
        })
    }

    return (
        <div className='dt-change-status-popup-container'>
            <div className="dt-change-status-popup-block">
                <h4>Choose new status for selected rows</h4>
                <Select
                    className={"status-select"}
                    styles={selectStyles}
                    options={statusOptions}
                    isSearchable={false}
                    name={"statuses"}
                    placeholder={"Select status"}
                    onChange={(option) => {
                        setSelectedStatus(option);
                    }}
                />
                <div className="config-btns-block">
                    <button
                        className={`update-btn ${!selectedStatus ? 'disabled' : ''}`}
                        onClick={() => onConfirm(selectedStatus)}
                        disabled={!selectedStatus}
                    >Confirm</button>
                    <button className='discard-btn' onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default DTChangeStatusPopup;