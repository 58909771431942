import {USER_PROJECT} from "../constants/Auth";

const parseVoluumAccountDetails = (data) => {
    const accountsArr = [];
    const curProject = localStorage.getItem(USER_PROJECT);
    if(curProject !== '') {
        data.forEach(acc => {
            if(acc.remark.toLowerCase() === curProject.toLowerCase() && acc.status) {
                const accObj = {
                    name: acc['name'].trim(),
                    remark: acc['remark'],
                    site_id: acc['site_id'],
                    api_key: acc['api_key'],
                    voluum_affiliate_network_id: acc['voluum_affiliate_network_id'],
                    an_id: acc['affiliate_network_id']
                };
                accountsArr.push(accObj);
            }
        })
    } else {
        data.forEach(acc => {
            const accObj = {
                name: acc['remark'].trim(),
                voluumAccountId: acc['voluum_account_id']
            };
            if (accountsArr.filter(el => el.name === accObj.name).length === 0) {
                accountsArr.push(accObj);
            }
        })
    }
    return accountsArr;
}

export default parseVoluumAccountDetails;
