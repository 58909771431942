import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik, Form, Field} from "formik";
import TagsInput from "react-tagsinput";
import updateVoluumAlert from "../../../data-collectors/update-voluum-alert";
import Loader from "../../loaders/Loader";
import {itemToUpdateStatus} from "../../../redux/dataUploader/dataSlice";
import compareVoluumObj from "../../../helpers/compare-voluum-objects";
import {setFormHint} from "../../../helpers/set-form-hint";

import "react-tagsinput/react-tagsinput.css";
import './update-config-form.scss'

const UpdateConfigForm = ({columnsArr, initialValues, closePopup, onCountedNames = null, allValues = null}) => {
    const dispatch = useDispatch();
    const currentItemData = useSelector((state) => state.dataUploader.voluumAlertItem);
    const [inputTagsValues, setInputTagsValues] = useState({});
    const [inputJSONValues, setInputJSONValues] = useState('');
    const [affStatus, setAffStatus] = useState(currentItemData['affNetworksOption']);
    const [formErrorObj, setFormErrorObj] = useState({});
    const [formError, setFormError] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    useEffect(() => {
        if (allValues.length && onCountedNames) {
            onCountedNames(allValues, currentItemData)
        }
    }, [columnsArr])

    const handleTagsChange = (tags, name, setFieldValue) => {
        const cleanTags = tags.filter(el => el != null);
        if(cleanTags.length > 0 && cleanTags[tags.length - 1].length > 0) {
            setFieldValue(name, cleanTags.join(','));
            setInputTagsValues({
                ...inputTagsValues,
                [name]: cleanTags
            })
        } else if(cleanTags.length === 0) {
            setFieldValue(name, null);
            setInputTagsValues({
                ...inputTagsValues,
                [name]: cleanTags
            })
        }
    }

    const handleJSONChange = (status, name, setFieldValue) => {
        setFieldValue(name, JSON.stringify({"active": status,"include": [],"exclude": []}));
        setInputJSONValues({
            ...inputJSONValues,
            [name]: JSON.stringify({"active": status,"include": [],"exclude": []})
        })
    }

    const validateTags = (value, name) => {
        if(/[^A-Za-z\d _]+/g.test(value)) {
            setFormErrorObj({
                ...formErrorObj,
                [name]: true
            })
        } else {
            setFormErrorObj({
                ...formErrorObj,
                [name]: false
            })
            return true;
        }
    }

    const validateField = (value, type, name) => {
        if (value === '') {
            return false;
        } else {
            if(type === 'number' && (name === 'period' || name === 'visitsPeriod')) {
                if (!/^(|\d+)$/.test(value)) {
                    return 'Only positive number';
                }
            } else if(type === 'number') {
                if (!/^(|-?\d+)$/.test(value)) {
                    return 'Numbers only';
                }
            } else {
                if (!/^[a-zA-Z\d ()]*$/.test(value)) {
                    return 'No special symbols';
                }
            }
        }
    }

    const validateSelect = value => {
        setAffStatus(value);
    }

    const onSubmit = async values => {
        if(values['affNetworksOption'] === 'all') {
            values['affNetworks'] = null;
        }

        let newValuesObj = {};
        Object.entries(values).forEach(el => {
            if(el[1] === '') {
                newValuesObj[el[0]] = null;
            } else {
                newValuesObj[el[0]] = el[1];
            }
        })

        newValuesObj.id = currentItemData.id;
        dispatch(itemToUpdateStatus(!compareVoluumObj(currentItemData, newValuesObj)))
        updateVoluumAlert(setFormLoading, setFormSuccess, setFormError, dispatch, newValuesObj);
    }

    return (
        <div className='form-wrap'>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({errors, touched, values, setFieldValue }) => (
                    <Form>
                        <div className="title-wrap">
                            <h1>Update {currentItemData['alertName']}</h1>
                            <div className="close-btn" onClick={closePopup}>
                                <img src={`${process.env.PUBLIC_URL}/images/close-btn.svg`} alt="close popup"/>
                            </div>
                        </div>
                        <div className="form-flex-container">
                            {Object.entries(columnsArr).map(item => {
                                return (
                                    <div key={item[0]} className={`form-${item[0]}-block`}>
                                        {item[1].map((el, i) => {
                                            return (
                                                <div className='form-block' key={i}>
                                                    {el.map((formField, j) => {
                                                        if (formField.type === 'array') {
                                                            const inputStatus = formField.name === 'affNetworks' && affStatus === 'all';

                                                            return (
                                                                <div
                                                                    className={`input-block config-item-block${inputStatus ? ' disabled' : ''}`}
                                                                    key={j+i}
                                                                    title={inputStatus ? 'no tags for "all" option in "affNetworksOption" field' : null}>
                                                                    <label htmlFor={formField.name}>{formField.name}</label>
                                                                    <Field name={formField.name}>
                                                                        {({
                                                                              field,
                                                                              form: {setFieldValue},
                                                                          }) => (
                                                                            <TagsInput
                                                                                name={formField.name}
                                                                                disabled={inputStatus}
                                                                                value={inputTagsValues.hasOwnProperty(formField.name) ? inputTagsValues[formField.name] : field.value ? field.value.split(',') : []}
                                                                                onChange={tags => {
                                                                                    handleTagsChange(tags, formField.name, setFieldValue)
                                                                                }}
                                                                                addOnBlur={true}
                                                                                validate={tags => validateTags(tags, formField.name)}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {formErrorObj[formField.name] ? <div className='form-error'>Please provide valid data</div> : null}
                                                                </div>
                                                            )
                                                        } else if (formField.type === 'options') {
                                                            return (
                                                                <div className='input-block config-item-block' key={j+i}>
                                                                    <label htmlFor={formField.name}>{formField.name}</label>
                                                                    <Field as="select" name={formField.name} value={values['affNetworksOption'] ? values['affNetworksOption'] : currentItemData['affnetworksoption']} onChange={e => setFieldValue('affNetworksOption', e.target.value)} validate={validateSelect}>
                                                                        {formField['options_list'].split(',').map((option, i) => {
                                                                            return (
                                                                                <option key={i} value={option}>{option}</option>
                                                                            )
                                                                        })}
                                                                    </Field>
                                                                </div>
                                                            )
                                                        } else if (formField.type === 'boolean') {
                                                            return (
                                                                <div className='input-block config-item-block checkbox-block'
                                                                     key={j+i}>
                                                                    <Field type="checkbox" className="toggle-button" name={formField.name} />
                                                                    <label htmlFor={formField.name} className="toggle-label">{currentItemData['alertName']}</label>
                                                                </div>
                                                            )
                                                        } else if (formField.type === 'json' && currentItemData[formField.name]) {
                                                            return (
                                                                <div className='input-block config-item-block checkbox-block'
                                                                     key={j+i}>
                                                                    <Field type="checkbox" className="toggle-button" name={formField.name}
                                                                    checked={values[formField.name] ? JSON.parse(values[formField.name]).active : JSON.parse(currentItemData[formField.name]).active}
                                                                   onChange={e => {
                                                                       handleJSONChange(e.target.checked, formField.name, setFieldValue)
                                                                   }}
                                                                    />
                                                                    <label htmlFor={formField.name} className="toggle-label">{formField.name === 'alertConfig' ? 'Offer deactivation' : formField.name}</label>
                                                                </div>
                                                            )
                                                        } else if (formField.type === 'json' && !currentItemData[formField.name]) {
                                                            return null;
                                                        } else {
                                                            const inputStatus = formField['method_allows'] && !formField['method_allows'].includes(currentItemData.method);
                                                            let label = formField.name;
                                                            if(formField.name === 'roi') {
                                                                if(currentItemData['alertName'] === 'Alert 6' || currentItemData['alertName'] === 'Alert 7') {
                                                                    label = 'Total ROI Threshold';
                                                                } else {
                                                                    label = formField.name;
                                                                }
                                                            } else if(formField.name === 'offersTrafficPercentDown') {
                                                                if(currentItemData['alertName'] === 'Alert 6') {
                                                                    label = 'Mobile Carrier ROI decrease %';
                                                                } else if(currentItemData['alertName'] === 'Alert 7') {
                                                                    label = 'Mobile Device ROI decrease %';
                                                                } else {
                                                                    label = formField.name;
                                                                }
                                                            }

                                                            return (
                                                                <div className={`input-block config-item-block${inputStatus ? ' disabled' : ''}`} key={j+i}>
                                                                    <label htmlFor={formField.name}>{label}</label>
                                                                    <Field
                                                                        id={formField.name}
                                                                        name={formField.name}
                                                                        type={formField.type === 'number' ? 'number' : 'text'}
                                                                        min={formField.type === 'number' && (formField.name === 'period' || formField.name === 'visitsPeriod') ? 0 : null}
                                                                        disabled={inputStatus}
                                                                        title={inputStatus ? 'no changing of the field for this alert' : null}
                                                                        validate={value => validateField(value, formField.type, formField.name)}
                                                                    />
                                                                    {errors[formField.name] && touched[formField.name] ? <div className='form-error'>{errors[formField.name]}</div> : setFormHint(currentItemData['alertName'], formField.name, values[formField.name], currentItemData.method)}
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="config-btns-block">
                            <button className='update-btn' type="submit">Update</button>
                            <div className="discard-btn" onClick={closePopup}>Close</div>
                        </div>
                    </Form>
                )}
            </Formik>
            {formLoading ? <div className="config-btns-loader-block"><Loader /></div>: null}
            {formError ? <div className="config-btns-message-block"><p className='new-user-error'>Some error occurred</p></div> : ''}
            {formSuccess ? <div className="config-btns-message-block"><p className='new-user-success'>Alert is updated</p></div> : ''}
        </div>
    )
}

export default UpdateConfigForm;
