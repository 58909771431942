import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {USER_ID, USER_OTP_ENABLED} from "../../constants/Auth";
import getUserData from "../../services/get-user-info";
import Loader from "../../components/loaders/Loader";
import OtpPopup from "../../components/popups/OtpPopup";
import ResetPassPopup from "../../components/popups/ResetPassPopup";
import {logOut} from "../../redux/dataUploader/dataSlice";
import generateOpt from "../../services/generate-opt";
import userResetPassword from "../../services/reset-password-with-email";
import varifyOpt from "../../services/verify-opt";
import disableOpt from "../../services/disable-opt";

import "./user-page.scss";

const UserInfoPage = () => {
    const [userData, setUserData] = useState({});
    const [optStatus, setOptStatus] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isVisibleOptPopup, setVisibleOptPopup] = useState(false);
    const [isVisibleResetPassPopup, setVisibleResetPassPopup] = useState(false);
    const [qrcodeUrl, setqrCodeUrl] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [optTokenValue, setOptTokenValue] = useState("");
    const [codeFormError, setCodeFormError] = useState(false);
    const [resetPassError, setResetPassError] = useState(false);
    const dispatch = useDispatch();
    const userId = Number(localStorage.getItem(USER_ID));
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);

    const getDataUser = async (userId) => {
        try {
            setLoading(true)
            const user = await getUserData(userId);
            setUserData(user.data.user);
            setOptStatus(user.data.user.otp_enabled)
            setLoading(false);
        } catch (err) {
            console.error(err);
            if (err.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    useEffect(() => {
        getDataUser(userId);
    }, []);

    const onSetupOpt = async () => {
        try {
            const userOpt = await generateOpt(userId);
            const {base32, otpauth_url} = userOpt.data;
            setqrCodeUrl(otpauth_url);
            setOtpCode(base32);
            setVisibleOptPopup(true)
            setOptTokenValue("");
        }
        catch (err) {
            setOptTokenValue("");
            console.error(err);
            if (err.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    const onVerifyCode = async () => {
        try {
            if(!optTokenValue) {
                setCodeFormError(true);
                setTimeout(() => {
                    setCodeFormError(false);
                }, 2000)
            }

            const userData = await varifyOpt(userId, optTokenValue)
            const {otp_enabled} = userData.data.user;
            setUserData(userData.data.user);
            setOptStatus(otp_enabled);
            localStorage.setItem(USER_OTP_ENABLED, otp_enabled);
            setVisibleOptPopup(false);
            setOptTokenValue("");
        }
        catch (err) {
            setCodeFormError(true);
            setTimeout(() => {
                setCodeFormError(false);
            }, 2000)
            setOptTokenValue("");
            console.error(err);
            if (err.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    const onDisableOpt = async () => {
        try {
            const userData = await disableOpt(userId);
            const {otp_enabled} = userData.data.user;
            setUserData(userData.data.user);
            setOptStatus(otp_enabled);
            localStorage.setItem(USER_OTP_ENABLED, otp_enabled);
            setOptTokenValue("");
        }
        catch (err) {
            setOptTokenValue("");
            console.error(err);
            if (err.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    const onResetPassword = async () => {
        try {
            if(!oldPassword || !newPassword) {
                setResetPassError(true);
                setTimeout(() => {
                    setResetPassError(false);
                }, 2000)
            }

            await userResetPassword(userId, oldPassword, newPassword);

            setVisibleResetPassPopup(false);
            setOldPassword("");
            setNewPassword("")
        }
        catch (err) {
            setResetPassError(true);
            setTimeout(() => {
                setResetPassError(false);
            }, 2000)
            setOldPassword("");
            setNewPassword("")
            console.error(err);
            if (err.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }


    return (
        <div className='user-page'>
            {
                isVisibleOptPopup &&
                <OtpPopup
                    setStatus={setVisibleOptPopup}
                    qrcodeUrl={qrcodeUrl}
                    optTokenValue={optTokenValue}
                    setOptTokenValue={setOptTokenValue}
                    onVerifyCode={onVerifyCode}
                    otpCode={otpCode}
                    formError={codeFormError}
                />
            }
            {
                isVisibleResetPassPopup &&
                    <ResetPassPopup
                        setStatus={setVisibleResetPassPopup}
                        onResetPassword={onResetPassword}
                        oldPasswordValue={oldPassword}
                        newPasswordValue={newPassword}
                        setOldPassword={setOldPassword}
                        setNewPassword={setNewPassword}
                        formError={resetPassError}
                    />
            }

            <div className={`user-page-container gradient-container${!menuStatus ? ' collapse-menu' : ''}`}>
                <h3>Profile page</h3>
            </div>
            <div className={`user-page-wrap${!menuStatus ? ' collapse-menu' : ''}`}>
                <div className="wrap">
                    <div className="block-l">
                        <ul className="user-data">
                            <li><span>Name:</span> {userData.name}</li>
                            <li><span>Email:</span> {userData.email}</li>
                            <li><span>Project:</span> {userData.project}</li>
                            <li><span>Role:</span> {userData.role}</li>
                        </ul>
                         <button className="reset-pass" onClick={() => setVisibleResetPassPopup(!isVisibleResetPassPopup)}>
                             Reset password
                         </button>
                    </div>
                    <div className="block-r">
                        <h3>Mobile App Authetication (2FA)</h3>
                        <p>Secure your account with TOTP two-factor authetication</p>

                        <button onClick={optStatus ? onDisableOpt : onSetupOpt} className={optStatus ? 'disable-btn' : 'setup-btn'}>
                            {optStatus ? 'Disable 2FA' : 'Setup 2FA'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserInfoPage;