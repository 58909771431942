import fetchData from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const disableOtp = async (userId) => {
    const url = `/disable-opt`;
    const res = await fetchData.post(url, {"userId": userId}, {
        headers: { 'Authorization': getAuthorizationHeader() }
    });
    return res;
}

export default disableOtp