import React, {useEffect, useState} from "react";
import {Formik, Form} from "formik";
import {useSelector} from "react-redux";
import {USER_ROLE} from "../../../constants/Auth";
import {
    VOLUUM_REPORTS_FILTER_LIST,
    VOLUUM_REPORTS_MAIN_FILTERS,
    VOLUUM_REPORTS_OPERATORS_LIST
} from "../../../constants";
import CustomSelectWithoutRedux from "../../CustomSelectWithoutRedux";
import CustomDateRangePicker from "../../CustomDateRangePicker";
import CustomInputSelect from "../inputs/CustomInputSelect";
import CustomInput from "../inputs/CustomInput";
import CustomEditableSelect from "../../CustomEditableSelect";

import "../forms-styles.scss";
import "../NewUserForm/new-user-form.scss";

const VoluumReportsForm = ({
                               initialValues,
                               setInitialValues,
                               onSubmit,
                               isLoading,
                               setVoluumId,
                               setSettingsView,
                               settingsList,
                               setSettingsList,
                               settingsStatus,
                               setSettingsStatus,
                               saveView,
                               settingsName,
                               setSettingsName,
                               isViewLoading,
                               setViewLoading,
                               isViewError,
                               setViewError,
                               viewErrorText,
                               setViewErrorText
                           }) => {
    const role = localStorage.getItem(USER_ROLE);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const currentItemData = useSelector((state) => state.dataUploader.voluumAlertItem);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const [settingsNameToEdit, setSettingsNameToEdit] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        const currentItemDataLength = Object.keys(currentItemData).length;
        if (currentItemDataLength) {
            setInitialValues(currentItemData);
        }
    }, [currentItemData, setInitialValues])

    const validateNumber = value => {
        if (!/^$|^-?\d+(\.\d+)?$/.test(value)) {
            return 'Numbers only';
        }
    }

    const nameToEdit = value => {
        setSettingsNameToEdit(value);
    }

    return (
        <div className={`report-form-wrap form-wrap ${!menuStatus ? ' collapse-menu' : ''}`}>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}>
                {({errors, touched, values, setFieldValue}) => (
                    <Form>
                        <div className="form-flex-container">
                            <div className="form-flex-row">
                                <div className="form-flex-col">
                                    {settingsList.length === 0 && !settingsStatus ?
                                        null :
                                        settingsList.length === 0 && settingsStatus ?
                                            <p>Save filter settings</p> :
                                            <p>Choose filter settings or save current options</p>
                                    }
                                    <div className="form-settings-block">
                                        <CustomEditableSelect
                                            data={settingsList}
                                            setSettingsList={setSettingsList}
                                            setSettingsView={setSettingsView}
                                            settingsStatus={settingsStatus}
                                            setSettingsStatus={setSettingsStatus}
                                            setSettingsName={setSettingsName}
                                            nameToEdit={nameToEdit}
                                            isViewLoading={isViewLoading}
                                            setViewLoading={setViewLoading}
                                            isViewError={isViewError}
                                            setViewError={setViewError}
                                            viewErrorText={viewErrorText}
                                            setViewErrorText={setViewErrorText}
                                        />
                                        {settingsStatus ?
                                            <button className='blue-btn' onClick={e => saveView(e, values, settingsName)}>{settingsName === settingsNameToEdit && settingsName !== '' && settingsList.length > 0 ? 'Resave current settings' : 'Save new settings'}</button> :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form-left-block">
                                <div className="form-flex-row">
                                    {Object.entries(initialValues).map(inputItem => {
                                        if(inputItem[0] === 'voluumAccountId') {
                                            if(role === 'admin') {
                                                return (
                                                    <div className='flex-range-block' key={inputItem[0]}>
                                                        <label htmlFor="name" className="label-name">
                                                            <span className="content-name">ACCOUNT</span>
                                                        </label>
                                                        <CustomSelectWithoutRedux
                                                            data={accountInformation.filter(acc => +acc['voluum_id'] < 1000)}
                                                            curValue={initialValues[inputItem[0]]}
                                                            setVoluumId={setVoluumId}
                                                            allOption={true}
                                                            setSettingsStatus={setSettingsStatus}
                                                        />
                                                    </div>
                                                )
                                            }
                                        } else if(inputItem[0] === 'dates') {
                                            return (
                                                <div className="flex-range-block" key={inputItem[0]}>
                                                    <label htmlFor="name" className="label-name">
                                                        <span className="content-name">DATES RANGE</span>
                                                    </label>
                                                    <CustomDateRangePicker
                                                        type={'report'}
                                                        curValue={initialValues[inputItem[0]]}
                                                        setSettingsStatus={setSettingsStatus}
                                                        setSubmitDisabled={setSubmitDisabled}
                                                        isClearable={true}
                                                    />
                                                </div>
                                            )
                                        } else if (inputItem[0] === 'type') {
                                            return (
                                                <div className='flex-range-block' key={inputItem[0]}>
                                                    <CustomInputSelect
                                                        name={inputItem[0]}
                                                        labelName={'REPORT TYPE'}
                                                        list={VOLUUM_REPORTS_FILTER_LIST}
                                                        value={values[inputItem[0]]}
                                                        currentValue={initialValues[inputItem[0]]}
                                                        setFieldValue={setFieldValue}
                                                        errors={errors[inputItem[0]]}
                                                        touched={touched[inputItem[0]]}
                                                        additionalClass={'without-config-item-block'}
                                                        hint={false}
                                                        setSettingsStatus={setSettingsStatus}
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="form-flex-row">
                                    {Object.entries(initialValues).map(inputItem => {
                                        if (inputItem[0].includes('operator') && values[inputItem[0].replace('operatorFor', '').toLowerCase()] !== '') {
                                            const name = inputItem[0].replace('operatorFor', '').toLowerCase();
                                            return (
                                                <div className='flex-range-block' key={inputItem[0]}>
                                                    <CustomInputSelect
                                                        name={inputItem[0]}
                                                        list={VOLUUM_REPORTS_OPERATORS_LIST}
                                                        value={values[inputItem[0]]}
                                                        currentValue={initialValues[inputItem[0]]}
                                                        setFieldValue={setFieldValue}
                                                        errors={errors[inputItem[0]]}
                                                        touched={touched[inputItem[0]]}
                                                        additionalClass={'without-config-item-block inside-input'}
                                                        label={false}
                                                        hint={true}
                                                        hintValue={values[name]}
                                                        setSettingsStatus={setSettingsStatus}
                                                    />
                                                </div>
                                            )
                                        } else if (!inputItem[0].includes('operator') && (VOLUUM_REPORTS_MAIN_FILTERS.includes(inputItem[0].toLowerCase()))) {
                                            return (
                                                <div className='flex-range-block' key={inputItem[0]}>
                                                    <CustomInput
                                                        name={inputItem[0]}
                                                        value={values[inputItem[0]]}
                                                        currentValue={initialValues[inputItem[0]]}
                                                        setFieldValue={setFieldValue}
                                                        type={typeof inputItem[1]}
                                                        validate={validateNumber}
                                                        errors={errors[inputItem[0]]}
                                                        touched={touched[inputItem[0]]}
                                                        additionalClass={`without-config-item-block${values[inputItem[0]] !== '' ? ' outside-input' : ''}` }
                                                        setSettingsStatus={setSettingsStatus}
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <button className="blue-btn form-submit" type="submit" disabled={isLoading || submitDisabled}>Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default VoluumReportsForm;
