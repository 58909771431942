import React from 'react';
import {Field, Form, Formik} from "formik";

const ForgotPassword = ({
                            initialValues,
                            currentValues,
                            onSubmit,
                            isLoading,
                            isSuccessfully,
                            changeNewPassword,
                            changeDuplicatePassword
                        }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({errors, touched}) => (
            <Form>
                <div className={`input-wrap ${errors.newPassword && touched.newPassword ? 'error-input' : ''}`}>
                    <div className="input-block login-input-block">
                        <label htmlFor="newPassword" className="label-name">
                            <span className="content-name">
                              New password
                            </span>
                        </label>
                        <Field
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            validate={changeNewPassword}
                            className={currentValues.newPassword !== '' ? 'filled' : ''}
                        />
                    </div>
                    {errors.newPassword && touched.newPassword &&
                    <div className='error-text login-error-text'>{errors.newPassword}</div>}
                </div>
                <div
                    className={`input-wrap ${errors.duplicateNewPassword && touched.duplicateNewPassword ? 'error-input' : ''}`}>
                    <div className="input-block login-input-block">
                        <label htmlFor="duplicateNewPassword" className="label-name">
                            <span className="content-name">
                              Confirm password
                            </span>
                        </label>
                        <Field
                            id="duplicateNewPassword"
                            name="duplicateNewPassword"
                            type="password"
                            validate={changeDuplicatePassword}
                            className={currentValues.duplicateNewPassword !== '' ? 'filled' : ''}
                        />
                    </div>
                    {errors.duplicateNewPassword && touched.duplicateNewPassword &&
                    <div className='error-text login-error-text'>{errors.duplicateNewPassword}</div>}
                </div>
                <button className={`form-submit ${isSuccessfully ? 'successfully' : ''}`} type="submit" disabled={isLoading}>Submit</button>
            </Form>
        )}
    </Formik>
);

export default ForgotPassword;