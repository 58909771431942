import {Routes, Route} from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import routeConfig from "./index";

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<AuthWrapper />}>
                {routeConfig.map(route => <Route key={route.path} path={route.path} element={route.element}/>)}
            </Route>
        </Routes>
    )
}

export default Router;