import {logOut} from "../redux/dataUploader/dataSlice";
import {getAllAlertsService} from "../services/alerts";

const GetAlertsData = async (setLoading, setError, dispatch, setData, date, id) => {
    setLoading(true);
    try {
        const results = await getAllAlertsService(date, id);
        if(results.data.error) {
            setError(true);
        } else {
            setData(results.data.data);
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default GetAlertsData;