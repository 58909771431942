const moveColumnToFirstPlace = (arr, name) => {
    return arr.map(obj => {
        const data = { [name]: obj[name] };

        for (const prop in obj) {
            if (prop !== name && obj.hasOwnProperty(prop)) {
                data[prop] = obj[prop];
            }
        }

        return data;
    });
}

export default moveColumnToFirstPlace;