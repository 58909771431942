const checkUser = (data, email) => {
    let projectName = '';
    data.forEach(el => {
        if(el['emails'] && el['emails'].split(',').includes(email)) {
            projectName = el.project;
        }
    })
    return projectName;
}

export default checkUser;
