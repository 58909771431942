import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {USER_ID, USER_ROLE} from "../../constants/Auth";
import Loader from "../../components/loaders/Loader";
import OffersCheckerForm from "../../components/forms/OffersCheckerForm";
import PreTableYieldkitOffersListData from "../../components/tables/PreTableYieldkitOffersListData";
import CsvDownload from "../../components/buttons/CsvDownload";
import {checkOponiaOffersService} from "../../services/yieldkit";
import {OPONIA_VISIBLE_COLUMNS, UPLOADER_TYPE_LINKS_FRONT_ONLY} from "../../constants";
import prepareVoluumFormatCSVData from "../../helpers/prepare-voluum-format-csv-data";

import '../YieldkitStatusChecker/yieldkit-status-checker.scss';

const OponiaStatusChecker = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem(USER_ROLE);
    const userId = localStorage.getItem(USER_ID);
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        linksList: ''
    });
    const [currentValues, setCurrentValues] = useState({
        linksList: ''
    });
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [fileLinksList, setFileLinksList] = useState([]);
    const [voluumData, setVoluumData] = useState([]);
    const [voluumDataParams, setVoluumDataParams] = useState({});

    useEffect(() => {
        if(role !== 'admin') {
            navigate('/not-found');
        }
    }, [userId, pathname])

    useEffect(() => {
        if(tableData.length > 0) setLoading(false);
        if(tableData && tableData.length > 0 && tableData[0]['json_file_data'] !== '') {
            let voluumArr = [];
            const headerArr = Object.keys(tableData[0]);
            if (headerArr.includes('url')) {
                setVoluumDataParams({header: true});
                voluumArr = prepareVoluumFormatCSVData(UPLOADER_TYPE_LINKS_FRONT_ONLY, tableData.filter(el => el['offer_status'] === 'active'));
            } else if(!headerArr[0].includes('column')) {
                setVoluumDataParams({header: true});
            } else {
                setVoluumDataParams({header: false});
            }

            if(voluumArr.length === 0) {
                voluumArr = tableData
                    .filter(el => el['offer_status'] === 'active')
                    .map(el => JSON.parse(el['json_file_data']));
            }
            setVoluumData(voluumArr);
        }
    }, [tableData])

    const onSendLinksList = async value => {
        setLoading(true);
        const {linksList} = value;
        const linksArr = linksList
            .split(/[,\n]/)
            .filter(link => link !== '')
            .map(link => {
                    return {
                        "offer_name": "",
                        "url": link,
                        "country_code": "",
                        "affiliate_network_id": "",
                        "payout": "",
                        "tags": "",
                        "offer_cap_enabled": "",
                        "daily_cap_value": "",
                        "offer_cap_time_zone": "",
                        "offer_cap_redirect_offer_id": "",
                        "workspace_id": "",
                        "currency_code": "",
                        "offer_cap_type": ""
                    }
                }
            )

        const offers = {
            "offers": formError || linksList === '' ? fileLinksList : linksArr
        }

        const batchSize = 10;
        const totalOffers = offers['offers'].length;

        for (let i = 0; i < totalOffers; i += batchSize) {
            const batch = offers['offers'].slice(i, i + batchSize);

            try {
                const result = await checkOponiaOffersService({ offers: { "offers": batch } });

                if (result['data']['data'].offers.length > 0) {
                    setTableData(prevData => [...prevData, ...result['data']['data'].offers]);
                } else if (result['data']['data'].errors.length > 0) {
                    const errorArr = result['data']['data'].errors.map(item => {
                        const match = item[0].match(/offer_id\s([^"]+)/);
                        return match ? match[1] : null;
                    });

                    const updatedArray = offers['offers'].map(obj => {
                        const match = obj.url.match(/offerid=([^&]+)/);
                        const offerId = match ? match[1] : null;

                        if (offerId && errorArr.includes(offerId)) {
                            return { ...obj, offer_status: "failed to check" };
                        }
                        return obj;
                    });

                    setTableData(prevData => [...prevData, ...updatedArray]);
                } else {
                    setError(true);
                }
            } catch (err) {
                console.error(err);
                setLoading(false);
                setError(true);
            }
        }
    }

    const validateLinks = value => {
        let error, valueArr;

        valueArr = value.split(/[,\n]/);
        if (!value && value === '' && fileLinksList.length === 0) {
            error = 'Required';
        } if (value !== '' && fileLinksList.length !== 0) {
            setFormError(true);
        } else if (value && (valueArr.length === 0 || !valueArr.filter(el => el.length > 0).every(el => /^http(?!.+http(.+http))/i.test(el)))) {
            error = 'Links must be divided by comma or new line';
        } else if(value && valueArr.filter(el => /^http/i.test(el)).length === 0) {
            error = 'Please provide the list of valid links';
        } else {
            setCurrentValues(previousState => {
                return {...previousState, linksList: value}
            });
        }
        return error;
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }
    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <div className='yk-checker-page'>
            <p className='yk-checker-text'>Please insert the links you want to check, divided by comma or new line</p>
            <div className='form-wrap textarea-checker-form-wrap'>
                <OffersCheckerForm
                    type={UPLOADER_TYPE_LINKS_FRONT_ONLY}
                    initialValues={initialValues}
                    currentValues={currentValues}
                    validate={validateLinks}
                    onSubmit={onSendLinksList}
                    isLoading={isLoading}
                    fileLinksList={fileLinksList}
                    setFileLinksList={setFileLinksList}
                    formError={formError}
                    setFormError={setFormError}
                    fileText={'Or upload a tab file with links in one column'}

                />
                {formError ? <span className='yk-checker-form-error'>The file is too large!</span> : null}
            </div>
            {tableData && tableData.length > 0 ?
                <>
                    <div className={`yk-checker-btns-container${menuStatus ? '' : ' collapse-menu'}`}>
                        <CsvDownload csvData={tableData} separator=',' children='Download Results' filename='offers_status' additionalClass='all-results' />
                        <CsvDownload csvData={voluumData} filename='voluum_format_active_offers_list' separator={';'} enclosingCharacter={''} voluumFile={true} params={voluumDataParams} additionalClass={formError ? ' disabled' : ''}>
                            Download active links in Voluum format
                        </CsvDownload>
                    </div>
                    <PreTableYieldkitOffersListData rowData={tableData} hiddenColumns={OPONIA_VISIBLE_COLUMNS} addFlex={true} />
                </> :
                null
            }
        </div>
    )
}

export default OponiaStatusChecker;