import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import Loader from "../../components/loaders/Loader";
import formVoluumReportsData from "../../helpers/voluum-reports-data";
import getVoluumReportsData from "../../data-collectors/get-voluum-reports-data";
import PreTableInfected from "../../components/tables/PreTableInfected";
import {FILTERS_TYPE_VOLUUM_REPORT} from "../../constants";
import {accountInformationStart, accountSelected} from "../../redux/dataUploader/dataSlice";
import {USER_ID, USER_PROJECT, USER_ROLE} from "../../constants/Auth";
import VoluumReportsForm from "../../components/forms/VoluumReportsForm";
import CsvDownload from "../../components/buttons/CsvDownload";
import {GetSettingsViewData, SaveSettingsViewData} from "../../data-collectors/settings-view-hooks";

import './voluum-reports.scss';

const VoluumReports = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem(USER_ROLE);
    const userId = localStorage.getItem(USER_ID);
    const projectName = localStorage.getItem(USER_PROJECT);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const optionSelected = useSelector(state => state.dataUploader.settingsViewSelected);
    const dateRangeEmpty = useSelector((state) => state.dataUploader.dateRangeEmpty);
    const voluumIdDispatch = useSelector(state => state.dataUploader.voluumId);
    const [initialValues, setInitialValues] = useState({
        type: '',
        voluumAccountId: '',
        dates: [],
        operatorForRoi: '',
        roi: '',
        operatorForCr: '',
        cr: '',
        operatorForCost: '',
        cost: ''
    })
    let [settingsView, setSettingsView] = useState({});
    const [voluumId, setVoluumId] = useState('');
    const [settingsList, setSettingsList] = useState([]);
    const [settingsName, setSettingsName] = useState(optionSelected);
    const [settingsStatus, setSettingsStatus] = useState(false);
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [csvFileName, setCsvFileName] = useState('voluum_report');
    const [title, setTitle] = useState('');
    const [titleStatus, setTitleStatus] = useState(false);
    const [dataAvailability, setDataAvailability] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isViewLoading, setViewLoading] = useState(false);
    const [isViewError, setViewError] = useState(false);
    const [viewErrorText, setViewErrorText] = useState('');

    useEffect(() => {
        if(role !== 'admin') {
            navigate('/not-found');
        }
    }, [pathname])

    useEffect(() => {
        GetSettingsViewData(setLoading, setError, dispatch, setSettingsList, userId, FILTERS_TYPE_VOLUUM_REPORT);
    }, [userId]);

    useEffect(() => {
        if(typeof settingsView === 'string') settingsView = JSON.parse(settingsView);
        const {type, voluumAccountId, dateFrom, dateTo, operatorForRoi, roi, operatorForCr, cr, operatorForCost, cost} = settingsView;
        if(Object.keys(settingsView).length > 0) {
            setInitialValues({
                type: type !== '' ? type : '',
                voluumAccountId: voluumAccountId !== '' ? voluumAccountId : voluumIdDispatch,
                dates: [dateFrom !== '' ? dateFrom : '', dateTo !== '' ? dateTo : ''],
                operatorForRoi: operatorForRoi !== '' ? operatorForRoi : '',
                roi: roi !== '' ? roi : '',
                operatorForCr: operatorForCr !== '' ? operatorForCr : '',
                cr: cr !== '' ? cr : '',
                operatorForCost: operatorForCost !== '' ? operatorForCost : '',
                cost: cost !== '' ? cost : ''
            });
        }
    }, [settingsView])

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
    }, [dispatch, projectName]);

    useEffect(() => {
        if(accountInformation.length > 0) {
            const firstAcc = accountInformation.reduce((prev, curr) => +prev['voluum_id'] < +curr['voluum_id'] ? prev : curr);
            setVoluumId(firstAcc.voluum_id);
            dispatch(accountSelected(firstAcc.project));
        }
    }, [dispatch, accountInformation]);

    const onSubmit = values => {
        const data = formVoluumReportsData(values, dateRangeEmpty, setCsvFileName, setTitle, voluumId);
        setSettingsView(data);
        getVoluumReportsData(data, setLoading, setError, dispatch, setData, setCsvData, setDataAvailability, setTitleStatus);
    }

    const saveView = (e, values, name) => {
        e.preventDefault();
        if(!viewErrorText || viewErrorText === '') {
            const data = formVoluumReportsData(values, dateRangeEmpty, setCsvFileName, setTitle, voluumId);
            const saveData = {
                name: name === '' ? optionSelected : name,
                user_id: userId,
                filter_type: FILTERS_TYPE_VOLUUM_REPORT,
                filters: JSON.stringify(data)
            }
            SaveSettingsViewData(settingsList, setViewLoading, setViewError, dispatch, setSettingsList, setSettingsStatus, saveData, FILTERS_TYPE_VOLUUM_REPORT);
        }
    }

    const onDataTableChanged = params => {
        let newCsvData = [];
        let {rowsToDisplay} = params.api.getModel();
        rowsToDisplay.forEach(el => {
            let obj = { ...el.data };
            newCsvData.push(obj)
        });
        setCsvData(newCsvData);
    };

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <>
            <VoluumReportsForm
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                onSubmit={onSubmit}
                isLoading={isLoading}
                setVoluumId={setVoluumId}
                setSettingsView={setSettingsView}
                setSettingsList={setSettingsList}
                settingsList={settingsList}
                settingsStatus={settingsStatus}
                setSettingsStatus={setSettingsStatus}
                saveView={saveView}
                settingsName={settingsName}
                setSettingsName={setSettingsName}
                isViewLoading={isViewLoading}
                setViewLoading={setViewLoading}
                isViewError={isViewError}
                setViewError={setViewError}
                viewErrorText={viewErrorText}
                setViewErrorText={setViewErrorText}
            />
            <div className="reports-download-block">
                <CsvDownload csvData={csvData.length > 0 ? csvData : data} filename={csvFileName} separator={','} disabled={isLoading}>
                    Download CSV
                </CsvDownload>
            </div>
            {titleStatus ? <h3 className="reports-title">{title}</h3> : null}
            {dataAvailability && data.length > 0 ?
                <PreTableInfected
                    rowData={data}
                    onFilterChanged={onDataTableChanged}
                    onSortChanged={onDataTableChanged}
                /> :
                !dataAvailability ?
                    <h3>No data for this parameters.</h3> :
                    null
            }
        </>
    )
}

export default VoluumReports;
