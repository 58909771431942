const buildAdditionalAlertsData = (name, data, period) => {
    let arr = [];
    if (period && data.length > 1) {
        arr.push({period: period, ...data[data.length - 1]});
    } else if (period && data.length === 1 && typeof data[0] === "string") {
        arr.push({period: period});
    } else if (period && data.length === 1 && data[0] instanceof Object && Object.values(data[0])[0] === 0) {
        arr.push({period: period});
    }
    return arr;
}


export default buildAdditionalAlertsData;