import React from 'react';
import './not-found.scss'

const NotFound = () => {
    return (
        <>
            <div className="not-found-container">
                <h1 className='not-found-title'>404</h1>
            </div>
            <div className="not-found-img-container">
                <img className='not-found-img-main' src={`${process.env.PUBLIC_URL}/images/not-found.webp`} alt="welcome"/>
            </div>
        </>
    )
}

export default NotFound;