import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {USER_PROJECT, USER_ROLE} from "../../constants/Auth";
import Loader from "../../components/loaders/Loader";
import {accountInformationStart} from "../../redux/dataUploader/dataSlice";
import GetAlertsData from "../../data-collectors/get-alerts";
import GetCapManagementAlertData from "../../data-collectors/get-cap-management-alert";
import CustomTabsMUI from "../../components/CustomTabsMUI";
import ExcelDownload from "../../components/buttons/ExcelDownload";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSelect from "../../components/CustomSelect";
import buildAdditionalAlertsData from "../../helpers/build-additional-alerts-data";
import ClearFilters from "../../components/buttons/ClearFilters";
import extractCapInformation from "../../helpers/extract-cap-information";

import './alerts-download.scss';

const AlertsDownload = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const voluumId = useSelector((state) => state.dataUploader.voluumId);
    const date = useSelector((state) => state.dataUploader.date);
    const curAccount = useSelector(state => state.dataUploader.accountSelected);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [tgChatId, setTgChatId] = useState('');
    const [alertsData, setAlertsData] = useState([]);
    const [capManagementAlertData, setCapManagementAlertData] = useState([]);
    const [tabsContent, setTabsContent] = useState([]);
    const [tabsTableData, setTabsTableData] = useState([]);
    const [additionalTabsTableData, setAdditionalTabsTableData] = useState([]);
    const [wrongDate, setWrongDate] = useState(false);
    const [tableRef, setTableRef] = useState(null);

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
    }, [dispatch, projectName]);

    useEffect(() => {
        if (role === 'admin' && accountInformation.length > 0) {
            const arr = accountInformation.reduce((prev, curr) => +prev['voluum_id'] < +curr['voluum_id'] ? prev : curr);
            setTgChatId(arr['tg_chat_id']);
        } else if(accountInformation.length > 0) {
            setTgChatId(accountInformation[0]['tg_chat_id']);
        }
    }, [accountInformation]);

    useEffect(() => {
        if (tgChatId) {
            GetAlertsData(setLoading, setError, dispatch, setAlertsData, date.toISOString().split('T')[0], tgChatId);
        }
    }, [dispatch, date, tgChatId, pathname]);

    useEffect(() => {
        if (voluumId) {
            GetCapManagementAlertData(setLoading, setError, dispatch, setCapManagementAlertData, date.toISOString().split('T')[0], voluumId);
        }
    }, [dispatch, date, voluumId]);

    useEffect(() => {
        if ((alertsData.length === 0 && capManagementAlertData.length === 0) || (alertsData.length > 0 && !tgChatId)) {
            setTabsContent([]);
            setTabsTableData([]);
            setWrongDate(true);
        } else if (alertsData.length > 0 && typeof alertsData !== 'string') {
            setWrongDate(false);
            let uniqueArray;
            let yieldkitStatus = false;
            alertsData.forEach(el => el['alertName'] === 'Yieldkit' ? yieldkitStatus = true : yieldkitStatus = false);

            const tabs = alertsData
                .filter(el => el['alertName'] !== 'Yieldkit')
                .reduce(
                    (acc, curVal) => [...acc, `${curVal['alertDescription'].split('(')[0]}`],
                    [],
                );
            if (yieldkitStatus) tabs.push('Yieldkit');
            uniqueArray = [...tabs];
            if (capManagementAlertData.length > 0) uniqueArray.push('campaigns managements');
            if (uniqueArray.length > 0) setTabsContent(uniqueArray);

            let contentArray = [];
            alertsData
                .forEach(el => {
                    const dataArr = Object.values(JSON.parse(el['jsonData']));
                    if (dataArr.length === 1 && dataArr[0] instanceof Object && Object.values(dataArr[0])[0] === 0) {
                        contentArray.push(['no data found for this alert'])
                    } else {
                        contentArray.push(dataArr)
                    }
                })

            let additionalArray = [];
            alertsData
                .forEach(el => {
                    const dataArr = Object.values(JSON.parse(el['jsonData']));
                    const processedDataArr = buildAdditionalAlertsData(el['alertName'], dataArr, el['periodRange']);
                    additionalArray.push(processedDataArr)
                })
            if (additionalArray.length > 0) setAdditionalTabsTableData(additionalArray);

            if (yieldkitStatus) {
                const i = alertsData.findIndex(obj => obj['alertName'] === 'Yieldkit');
                const elem = contentArray[i];
                elem.forEach(el => {
                    if (el['process_start_time']) {
                        return el['process_start_time'] = moment(el['process_start_time']).format('DD-MM-YYYY HH:mm:ss');
                    }
                })
                contentArray.splice(i, 1);
                contentArray.splice(contentArray.length, 0, elem);
            }

            const newContentArray = forCapManagementData(contentArray, capManagementAlertData);
            if (newContentArray.length > 0) setTabsTableData(newContentArray);
        } else if (capManagementAlertData.length > 0) {
            setWrongDate(false);
            setTabsContent(['campaigns managements']);
            const contentArray = forCapManagementData([], capManagementAlertData);
            if (contentArray.length > 0) setTabsTableData(contentArray);
        }
    }, [alertsData, capManagementAlertData, tgChatId]);

    const clearFilters = () => {
        tableRef.api.setFilterModel(null);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    const forCapManagementData = (arr, data) => {
        if (data && typeof data !== 'string' && data.length > 0) {
            data.forEach(el => {
                if(el.changed_reason && el.changed_reason.split('|')[0].includes('cap')) {
                    const data = {
                        status_for_action: el.status_for_action,
                        action: el.action,
                        changed_reason: el.changed_reason
                    }
                    if(el.action === 'manual') {
                        el['click cap'] = extractCapInformation(data, 'clicks_num');
                        el['daily cap'] = extractCapInformation(data, 'cap_limit');
                        el['status'] = extractCapInformation(data, 'cap_action');
                    }
                } else if(el.action === 'manual') {
                    if (el.status_for_action === 'deactivated') {
                        el['status'] = 'to be deactivated';
                    } else if (el.status_for_action === 'activated') {
                        el['status'] = 'to be reactivated';
                    }
                }

            });
            arr.push(data);
        }
        return arr;
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        !wrongDate ?
            <>
                <div
                    className={`alerts-download-select-container${role === 'admin' ? ' admin-alerts-download' : ''}`}>
                    {role === 'admin' && accountInformation.length > 0 ?
                        <>
                            <CustomDatePicker/>
                            <CustomSelect data={accountInformation} applyDispatch={true} setTgChatId={setTgChatId}/>
                            <ExcelDownload name={curAccount} titles={tabsContent} data={tabsTableData} additionalData={additionalTabsTableData}/>
                            <ClearFilters clearFilters={clearFilters} />
                        </> :
                        <div className='csv-relative-pos'>
                            <ExcelDownload name={projectName} titles={tabsContent} data={tabsTableData} additionalData={additionalTabsTableData}/>
                        </div>
                    }
                </div>
                <div className='alerts-download-container'>
                    {role !== 'admin' ? <CustomDatePicker/> : null}
                    <CustomTabsMUI titles={tabsContent} data={tabsTableData} additionalData={additionalTabsTableData ? additionalTabsTableData : null} alertsStatus={true} getTableRef={getTableRef} />
                </div>
            </> :
                role !== 'admin' && tgChatId ?
                    <div className='alerts-download-page'>
                        <h3>Please, choose another date</h3>
                        <CustomDatePicker/>
                    </div> :
                role === 'admin' ?
                    <div className='alerts-download-page'>
                        <h3>Please, choose another date{role === 'admin' ? ' or project' : ''}</h3>
                        <CustomDatePicker/>
                        <CustomSelect data={accountInformation} applyDispatch={true} setTgChatId={setTgChatId}/>
                    </div> :
                    <div className='alerts-download-page'>
                        <h3>Please, choose another date{role === 'admin' ? ' or project' : ''}</h3>
                        <CustomDatePicker/>
                    </div>
    )
}

export default AlertsDownload;

