import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import {setDateRange, setDateRangeEmpty} from '../../redux/dataUploader/dataSlice';

import "react-datepicker/dist/react-datepicker.css";
import './custom-date-range-picker.scss';

const CustomDateRangePicker = ({type = '', curValue, setSettingsStatus, isClearable, additionalClass, setSubmitDisabled}) => {
    const dispatch = useDispatch();
    const dateRange = useSelector((state) => state.dataUploader.dateRange);
    const dateRangeEmpty = useSelector((state) => state.dataUploader.dateRangeEmpty);
    const [startDate, endDate] = dateRange;
    const [startDateEmpty, endDateEmpty] = dateRangeEmpty;
    const [placeholderText, setPlaceholderText] = useState('');

    useEffect(() => {
        if(type === 'report') {
            if(curValue && curValue.length > 0 && (curValue[0] !== '' || curValue[1] !== '')) {
                const dates = curValue.map(el => new Date(el));
                dispatch(setDateRangeEmpty(dates));
            } else if(dateRangeEmpty.length > 0 && dateRangeEmpty.filter(el => el === null).length === 2) {
                setPlaceholderText('Click to choose dates range');
            }
        } else {
            if(dateRange.filter(el => el === null).length === 2) {
                setPlaceholderText('All data is shown');
            }
        }
    }, [dateRange, curValue])

    return (
        <div className={additionalClass}>
            <DatePicker
                selectsRange={true}
                startDate={type === 'report' ? startDateEmpty : startDate}
                endDate={type === 'report' ? endDateEmpty : endDate}
                onChange={update => {
                if(type === 'report') {
                    if(setSubmitDisabled) {
                        if(update.filter(el => !el || el === '').length === 1) {
                            setSubmitDisabled(true);
                        } else {
                            setSubmitDisabled(false);
                        }
                    }
                    dispatch(setDateRangeEmpty(update));
                    let dates;
                    if(curValue) {
                        dates = curValue.map(el => new Date(el));
                        setSettingsStatus(JSON.stringify(update) !== JSON.stringify(dates))
                    }
                } else {
                    dispatch(setDateRange(update));
                }
            }}
                isClearable={isClearable}
                maxDate={moment().toDate()}
                placeholderText={placeholderText}
            />
        </div>
    );
};

export default CustomDateRangePicker;