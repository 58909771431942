const rewriteTrackingLink = (yieldkitOffersListData, setModifiedData, accParams, an_id) => {
    let newData = [];
    let apiKey = '';
    let siteId = '';

    if(Object.keys(accParams).length > 0 && accParams['api_key'] && accParams['site_id']) {
        apiKey = accParams['api_key'];
        siteId = accParams['site_id'];
    } else {
        apiKey = '{api_key}';
        siteId = '{site_id}';
    }

    yieldkitOffersListData.forEach(obj => {
        const trackingLinkArr = obj['tracking_link'].split('&');
        const newParam = trackingLinkArr.map(el => {
            return el.includes('api_key') ? `api_key=${apiKey}` : el.includes('site_id') ? `site_id=${siteId}` :el;
        });
        obj['tracking_link'] = newParam.join('&');

        if(obj['tracking_link'].includes('offerid')) {
            obj['tracking_link'] = newParam.join('&');
        } else {
            if (an_id === 2) {
                obj['tracking_link'] = `${newParam.join('&')}&ctrl_offerid=${obj['offer_id']}&ctrl_aff=yk_cpc&ctrl_vol_oid={offer.id}&yk_tag={clickid}`;

            } else {
                obj['tracking_link'] = `${newParam.join('&')}&ctrl_offerid=${obj['offer_id']}&ctrl_aff=yk&ctrl_vol_oid={offer.id}&yk_tag={clickid}`;
            }
        }

        delete obj['checked'];
        delete obj['deleted_at'];
        newData.push(obj);
    })
    setModifiedData(newData);
}

export default rewriteTrackingLink;
