import fetchData from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const varifyOpt = async (userId, token) => {
    const url = `/verify-opt`;
    const res = await fetchData.post(url, {"userId": userId, "token": token}, {
        headers: { 'Authorization': getAuthorizationHeader() }
    });
    return res;
}

export default varifyOpt;