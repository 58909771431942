import React from "react";
import {useNavigate} from "react-router";

import './navigate-to.scss';

const NavigateTo = ({url, title, right}) => {
    const navigate = useNavigate();

    return (
        <button className='blue-btn navigate-to-btn' onClick={() => navigate(url)}>
            {!right ?
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L8 8L2 14" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> :
                null
            }
            <span>{title}</span>
            {right ?
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L8 8L2 14" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> :
                null
            }
        </button>
    )
}

export default NavigateTo;