import React, {useEffect, useState} from "react";
import PreTableInfected from "../../components/tables/PreTableInfected";
import Loader from "../../components/loaders/Loader";
import ClearFilters from "../../components/buttons/ClearFilters";
import {useDispatch, useSelector} from "react-redux";
import CsvDownload from "../../components/buttons/CsvDownload";
import ApprovalRateCheckerData from "../../data-collectors/approval-rate-checker-data";
import {USER_ROLE} from "../../constants/Auth";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

const ApprovalRateChecker = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem(USER_ROLE);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [ErrorText, setErrorText] = useState('');
    const [tableRef, setTableRef] = useState(null);

    useEffect(() => {
        if(role !== 'admin') {
            navigate('/not-found');
        }
    }, [pathname])

    useEffect(() => {
        ApprovalRateCheckerData(setLoading, setError, setErrorText, dispatch, setData, setCsvData);
    }, [])

    const onDataTableChanged = params => {
        tableRef.api.deselectAll();
        let newCsvData = [];
        let {rowsToDisplay} = params.api.getModel();
        rowsToDisplay.forEach(el => {
            let obj = { ...el.data };
            newCsvData.push(obj)
        });
        setCsvData(newCsvData);
    };

    const clearFilters = () => {
        tableRef.api.setFilterModel(null);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later. <br/>
                {ErrorText}
            </div>
        )
    }

    return (
        <>
            <div className={`page-btns-container${!menuStatus ? ' collapsed-menu' : ''}`}>
                <CsvDownload csvData={csvData.length > 0 ? csvData : data} filename='approval_rate_checker_data' separator={','} disabled={isLoading}>
                    Download CSV
                </CsvDownload>
                <ClearFilters clearFilters={clearFilters} />
            </div>
            <PreTableInfected
                rowData={data}
                onFilterChanged={onDataTableChanged}
                onSortChanged={onDataTableChanged}
                getTableRef={getTableRef}
            />
        </>
    )
}

export default ApprovalRateChecker;
