import {useEffect, useState} from "react";
import Select from "react-select";
import DataTable from "../../components/tables/DataTable";
import Loader from "../../components/loaders/Loader";
import Pagination from "../../components/Pagination";
import DiscoveryToolTableFilter from "../../components/DiscoveryToolTableFilter";
import CsvDownload from "../../components/buttons/CsvDownload";
import {
    columnDefs,
    orderDirectionOptions,
    rowsLimitOptions,
    filterConditionOptions,
} from "./table-options";
import './discovery-tool-campaigns.scss';
import ConfirmationPopup from "../../components/popups/ConfirmationPopup";
import InfoPopup from "../../components/popups/InfoPopup";
import {DISCOVERY_TOOL_CAMPAIGN_STATUSES} from "../../constants/index";
import DTCreateCampaignPopup from "../../components/popups/DTCreateCampaignPopup";


const DiscoveryToolCampaigns = ({type}) => {
    const orderByOptions = columnDefs.reduce((acc, item) => {
        if (item.key !== "change_status") {
            acc.push({value: item.key, label: item.label})
        }
        return acc;
    }, [])
    const searchByColumnOptions = columnDefs.reduce((acc, item) => {
        if (item.key !== "change_status") {
            acc.push({value: item.key, label: item.label})
        }
        return acc;
    }, [])

    const [isLoading, setIsLoading] = useState(true);
    const [restTableData, setRestTableData] = useState([]);
    const [rowsLimit, setRowsLimit] = useState({value: "10", label: "10 / page"});
    const [totalRows, setTotalRows] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState({
        orderDirection: orderDirectionOptions[0],
        orderBy: orderByOptions[0],
        columnsToSearch: [],
    });
    const [allRestData, setAllRestData] = useState([])
    const [csvData, setCSVData] = useState([])
    const [isShowInfoPopup, setIsShowInfoPopup] = useState(false)
    const [infoPopupText, setInfoPopupText] = useState("")
    const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false)
    const [confirmPopupText, setConfirmPopupText] = useState("")
    const [triggerUpdateTable, ] = useState(false)
    const [isShowCreateCampaignPopup, setIsShowCreateCampaignPopup] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState({
        id: null,
        status: null,
    })

    const changeStatusColumn = {
        label: "Change status",
        renderCell: (item) => <button
            className={`campaign-change-status-btn ${item["status"] === DISCOVERY_TOOL_CAMPAIGN_STATUSES.active ? "" : "activate-btn"}`}
            onClick={() => {
                setConfirmPopupText(`The campaign will be ${item["status"] === DISCOVERY_TOOL_CAMPAIGN_STATUSES.active ? "deactivated" : "activated"}. Do you confirm?`)
                setIsShowConfirmationPopup(true)
                setSelectedCampaign({
                    id: item["id"],
                    status: item["status"],
                })
            }}
        >
            {item["status"] === DISCOVERY_TOOL_CAMPAIGN_STATUSES.active ? "Deactivate" : "Activate"}
        </button>,
        key: "change_status",
        resize: false,
        width: "150px",
    }

    const rowsLimitSelectStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px",
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px",
        })
    }

    useEffect(() => {
        // add column to table
        if (!columnDefs.some(item => item.key === "change_status")) {
            columnDefs.push(changeStatusColumn)
        }
    }, [])

    useEffect(() => {
        //fetch data when sorting, filtering, rows limit or page changes
        handleGetTableData()
    }, [rowsLimit, currentPage, selectedFilters]);

    useEffect(() => {
        // fetch all data for CSV
        handleGetTableData(true)
    }, [totalRows, selectedFilters]);

    useEffect(() => {
        if (totalRows > 0) {
            setTotalPagesCount(Math.ceil(totalRows / rowsLimit.value));
        } else {
            setTotalPagesCount(1);
        }
        setCurrentPage(1);
    }, [totalRows, rowsLimit]);

    const handleGetTableData = (isForCSV = false) => {
        if (isForCSV && totalRows === 0) return
        if (!isForCSV) {
            setIsLoading(true)
        }

        //get parameter for filtering
        let filterParam = {}
        if (selectedFilters.columnsToSearch.length > 0) {
            for (let i = 0; i < selectedFilters.columnsToSearch.length; i++) {

                let val = selectedFilters.columnsToSearch[i].searchValue
                if (selectedFilters.columnsToSearch[i].condition === "like" || selectedFilters.columnsToSearch[i].condition === "not like") {
                    val = `%${selectedFilters.columnsToSearch[i].searchValue}%`
                }

                filterParam[selectedFilters.columnsToSearch[i].columnName] = {
                    operator: selectedFilters.columnsToSearch[i].condition,
                    value: val
                }
            }
        }

        let urlParams = `
            ?limit=${isForCSV ? totalRows : rowsLimit.value}
            &offset=${isForCSV ? 0 : (currentPage - 1) * rowsLimit.value}
            &orderBy=["${selectedFilters?.orderBy?.value ? selectedFilters.orderBy.value : "id"}"]
            &orderByDirection=${selectedFilters.orderDirection.value}
            &where=${encodeURI(JSON.stringify(filterParam))}
        `.replace(/\s{2,}|\n+/gm, ""); //regexp removes more than two whitespaces one by one and linebreaks

        //fetch when params changes
        fetch(`${process.env.REACT_APP_DT_API_URL}campaigns${urlParams}`, {
            method: "GET",
            headers: {
                "X-Api-Static-Key": process.env.REACT_APP_DT_API_KEY,
            }
        })
            .then(res => res.json())
            .then(res => {
                if (!isForCSV) {
                    setIsLoading(false);
                    setRestTableData(res.data);
                    setTotalRows(res.count);
                }

                if (isForCSV) {
                    setCSVData(res.data)
                    setAllRestData(res.data);
                }
            })
    }

    const handleChangeSelectedFilters = (value) => {
        setSelectedFilters(value)
    }

    const handleClearSearchByColumnFilter = () => {
        let temp = {...selectedFilters};
        temp["columnsToSearch"] = [];
        setSelectedFilters(temp);
    }

    const handleChangeSelectedRows = (idsArr) => {
        if (idsArr.length > 0) {
            setCSVData(allRestData.filter(item => idsArr.includes(item.id)))
        } else {
            setCSVData(allRestData)
        }
    }

    const handleChangeCampaignStatus = () => {
        if (selectedCampaign.id) {
            setIsLoading(true)
            fetch(`${process.env.REACT_APP_DT_API_URL}campaigns`, {
                method: "PUT",
                headers: {
                    "X-Api-Static-Key": process.env.REACT_APP_DT_API_KEY,
                },
                body: JSON.stringify({
                    id: selectedCampaign.id,
                    status: selectedCampaign.status === DISCOVERY_TOOL_CAMPAIGN_STATUSES.active ? DISCOVERY_TOOL_CAMPAIGN_STATUSES.deactivated : DISCOVERY_TOOL_CAMPAIGN_STATUSES.active
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (Object.keys(res).length > 0) {
                        handleGetTableData()
                        handleGetTableData(true)
                        setIsShowConfirmationPopup(false)
                    } else {
                        setIsLoading(false)
                        setIsShowConfirmationPopup(false)
                        setInfoPopupText("There is no campaign with such ID.")
                        setIsShowInfoPopup(true)
                    }
                })
        }
    }

    const handleCreateNewCampaign = (campaign) => {
        setIsShowCreateCampaignPopup(false)
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_DT_API_URL}campaigns`, {
            method: "PUT",
            headers: {
                "X-Api-Static-Key": process.env.REACT_APP_DT_API_KEY,
            },
            body: JSON.stringify({
                campaign_id: campaign.campaign_id,
                geo: campaign.geo.toLowerCase(),
                status: campaign.status
            })
        })
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.campaign_id) {
                    handleGetTableData()
                    handleGetTableData(true)
                } else {
                    setInfoPopupText("Something went wrong.")
                    setIsShowInfoPopup(true)
                }
            })
    }

    if (isLoading) return <Loader/>

    return (
        <div className={`discovery-tool-container ${type}-container`}>
            <div className="dt-buttons-container">
                <CsvDownload
                    csvData={csvData}
                    children={"Download CSV"}
                    filename={"discovery-campaigns"}
                    disabled={!csvData.length > 0}
                />
                <button
                    className={"blue-btn"}
                    onClick={() => setIsShowCreateCampaignPopup(true)}
                >Create new campaign
                </button>
            </div>
            <DiscoveryToolTableFilter
                orderByOptions={orderByOptions}
                orderDirectionOptions={orderDirectionOptions}
                searchByColumnOptions={searchByColumnOptions}
                filterConditionOptions={filterConditionOptions}
                selectedFilters={selectedFilters}
                changeSelectedFilters={handleChangeSelectedFilters}
                clearSearchFilters={handleClearSearchByColumnFilter}
            />
            <DataTable
                data={restTableData}
                columnDefs={columnDefs}
                isSelectableRows={true}
                onSelectChange={handleChangeSelectedRows}
            />
            <div className="data-table-footer">
                {rowsLimitOptions.length > 0 && (
                    <Select
                        className={"data-table-rows-limit"}
                        styles={rowsLimitSelectStyles}
                        options={rowsLimitOptions}
                        isSearchable={false}
                        defaultValue={rowsLimit}
                        name={"rows_count"}
                        onChange={(option) => {
                            setRowsLimit(option);
                        }}
                    />
                )}
                <div className={"data-table-rows-count"}>Total rows count: <strong>{totalRows}</strong></div>
                <Pagination
                    totalPagesCount={totalPagesCount}
                    setCurrentPage={(value) => setCurrentPage(value)}
                    currentPage={currentPage}
                />
            </div>
            {isShowInfoPopup &&
                <InfoPopup
                    text={infoPopupText}
                    onClose={() => {
                        setIsShowInfoPopup(false)
                        if (triggerUpdateTable) {
                            handleGetTableData()
                            handleGetTableData(true)
                        }
                    }}
                    onConfirm={() => {
                        setIsShowInfoPopup(false)
                        if (triggerUpdateTable) {
                            handleGetTableData()
                            handleGetTableData(true)
                        }
                    }}
                />
            }

            {isShowConfirmationPopup &&
                <ConfirmationPopup
                    onConfirm={handleChangeCampaignStatus}
                    onCancel={() => setIsShowConfirmationPopup(false)}
                    popupText={confirmPopupText}
                />
            }

            {isShowCreateCampaignPopup &&
                <DTCreateCampaignPopup
                    onConfirm={handleCreateNewCampaign}
                    onCancel={() => setIsShowCreateCampaignPopup(false)}
                />}
        </div>
    )
}

export default DiscoveryToolCampaigns;
