import React from 'react';
import Loader from "../../loaders/Loader";
import {Field, Form, Formik} from "formik";

const ForgotPassword = ({
                            initialValues,
                            currentValues,
                            setCurrentEmailValue,
                            onSubmit,
                            validateEmail,
                            isLoading,
                            isSuccessfully
                        }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({errors, touched}) => (
            <Form>
                <div className={`input-wrap ${errors.email && touched.email ? 'error-input' : ''}`}>
                    <div className="input-block login-input-block">
                        <label htmlFor="email" className="label-name">
                                        <span className="content-name">
                                          Email
                                        </span>
                        </label>
                        <Field
                            id="email"
                            name="email"
                            type="text"
                            validate={(value) => validateEmail(value, setCurrentEmailValue)}
                            className={currentValues.email !== '' ? 'filled' : ''}
                        />
                    </div>
                    {errors.email && touched.email &&
                    <div className='error-text login-error-text'>{errors.email}</div>}
                </div>
                <button className={`form-submit ${isSuccessfully ? 'successfully' : ''}`} type="submit" disabled={isLoading}>Submit</button>
            </Form>
        )}
    </Formik>
);

export default ForgotPassword;