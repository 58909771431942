import internalFetch from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

export const getYieldkitOffersListService = async (offset = 0, limit = 'ALL', id) => await internalFetch.get(`/yieldkit/offers-list-data/?offset=${offset}&limit=${limit}&an=${id}`, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getActiveYieldkitOffersListService = async (offset = 0, limit = 'ALL', id) => await internalFetch.get(`/yieldkit/active-offers-list-data/?offset=${offset}&limit=${limit}&an=${id}`, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const accountDetailsService = async ({an_id, voluum_id}) => await internalFetch.get(`/yieldkit/accounts/${an_id && voluum_id ? `?an=${an_id}&id=${voluum_id}` : an_id ? `?an=${an_id}` : voluum_id ? `?id=${voluum_id}` : ''}`, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const addAccountDetailsService = async data => await internalFetch.post('/yieldkit/add/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const updateAccountDetailsService = async data => await internalFetch.post('/yieldkit/update/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const deleteAccountDetailsService = async id => await internalFetch.post('/yieldkit/delete/', id, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const checkYieldkitAccountService = async data => await internalFetch.get(`/yieldkit/check/?api_key=${data['apiKey']}&api_secret=${data['apiSecret']}&site_id=${data['siteId']}`, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const checkYieldkitOffersService = async data => await internalFetch.post
('/yieldkit/check-yk-offers/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const checkYieldkitTopOffersService = async data => await internalFetch.post
('/yieldkit/check-yk-top-offers/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getCheckedYieldkitOffersService = async data => await internalFetch.post
('/yieldkit/get-checked-yk-offers/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getAffiliateNetworksService = async () => await internalFetch.get
('/yieldkit/networks/', {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const checkOponiaOffersService = async offers => await internalFetch.post
('/yieldkit/check-oponia-offers/', offers, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
