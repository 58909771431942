import React, {useState, useEffect}  from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import ClearFilters from "../../components/buttons/ClearFilters";
import GetClosedCampaignDetailsData from "../../data-collectors/get-closed-campaign-details";
import Loader from "../../components/loaders/Loader";
import NavigateTo from "../../components/buttons/NavigateTo";
import CustomTabs from "../../components/CustomTabs";
import ExcelDownload from "../../components/buttons/ExcelDownload";
import {CAMPAIGNS_TYPE, CLOSED_CAMPAIGNS_TABS, FLOWS_TYPE, PATH_TYPE} from "../../constants";
import './closed-campaigns-details.scss';

const ClosedCampaignsDetails = () => {
    const dispatch = useDispatch();
    const {state, pathname} = useLocation();
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const [closedCampaignsData, setClosedCampaignsData] = useState({
        path: [],
        flow: [],
        campaign: []
    });
    const [changedClosedCampaignsData, setChangedClosedCampaignsData] = useState({
        path: [],
        flow: [],
        campaign: []
    });
    const [isLoading, setLoading] = useState({
        path: false,
        flow: false,
        campaign: false
    });
    const [isError, setError] = useState(false);
    const [tabTitle, setTabTitle] = useState('');
    const [tableRef, setTableRef] = useState(null);

    useEffect(() => {
        if(state) {
            GetClosedCampaignDetailsData(setLoading, setError, dispatch, setClosedCampaignsData, setChangedClosedCampaignsData, state, PATH_TYPE);
            GetClosedCampaignDetailsData(setLoading, setError, dispatch, setClosedCampaignsData, setChangedClosedCampaignsData, state, FLOWS_TYPE);
            GetClosedCampaignDetailsData(setLoading, setError, dispatch, setClosedCampaignsData, setChangedClosedCampaignsData, state, CAMPAIGNS_TYPE);
        }
    }, [dispatch, pathname]);

    const onDataTableChanged = params => {
        let newData = [];
        let {rowsToDisplay} = params.api.getModel();
        rowsToDisplay.forEach(el => {
            let obj = { ...el.data };
            newData.push(obj)
        });
        setChangedClosedCampaignsData(prevState => ({
            ...prevState,
            [tabTitle]: newData
        }));
    };

    const getTabTitle = title => {
        setTabTitle(title)
    };

    const clearFilters = () => {
        tableRef.api.setFilterModel(null);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    if (Object.values(isLoading).filter(el => el === false).length !== Object.keys(isLoading).length) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <div className="closed-campaigns-container">
            {state ?
                <>
                    <div className={`page-btns-container${!menuStatus ? ' collapsed-menu' : ''}`}>
                        <NavigateTo title={'Back'} url={'/changed-campaigns/'} right={false} />
                        <ExcelDownload name={`${state.offerId}_offer_data`} titles={CLOSED_CAMPAIGNS_TABS} data={[changedClosedCampaignsData[PATH_TYPE], changedClosedCampaignsData[FLOWS_TYPE], changedClosedCampaignsData[CAMPAIGNS_TYPE]]} />
                        <ClearFilters clearFilters={clearFilters}/>
                        <div className='closed-campaigns-offer-info'>
                            <p>Offer Name: <span>{state.voluum_offer_name}</span></p>
                            <p>Offer Id: <span>{state.offerId}</span></p>
                        </div>
                    </div>
                    <CustomTabs
                        data={[closedCampaignsData[PATH_TYPE], closedCampaignsData[FLOWS_TYPE], closedCampaignsData[CAMPAIGNS_TYPE]]}
                        titles={CLOSED_CAMPAIGNS_TABS}
                        getTabTitle={getTabTitle}
                        closedCampaignsStatus={true}
                        onDataTableChanged={onDataTableChanged}
                        getTableRef={getTableRef}
                    />
                </> :
                <>
                    <h3>Please choose an offer on this page to learn more details about it.</h3>
                    <NavigateTo title={'Closed Campaigns List'} url={'/changed-campaigns/'} right={true}/>
                </>
            }
        </div>
    )
}

export default ClosedCampaignsDetails;