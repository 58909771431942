import fetchData from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const getUserData = async (userId) => {
    const url = `/me`;
    const res = await fetchData.post(url, {"id": userId}, {
        headers: { 'Authorization': getAuthorizationHeader() }
    });
    return res;
}

export default getUserData;