import {logOut} from "../redux/dataUploader/dataSlice";
import {getKelkooReportService} from "../services/kelkoo";
import prepareCSVData from "../helpers/prepare-csv-data";

const GetKelkooReportData = async (setLoading, setError, dispatch, setData, setCsvData, dates) => {
    setLoading(true);
    try {
        const results = await getKelkooReportService({dates: dates});
        if(results.data.error) {
            setError(true);
        } else {
            let data;
            if(results.data.data.data) {
                data = results.data.data.data;
                setData(data);
                setCsvData(prepareCSVData(data));
            } else if(results.data.data.status === 'error') {
                console.error('Error!', results.data.data.error);
                setLoading(false);
                setError(true);
            }
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default GetKelkooReportData;