import internalFetch from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

export const getAllAlertsService = async (date, id) => await internalFetch.post('/alerts/', {"date": date, "id": id}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});

export const getCapManagementService = async (date, id) => await internalFetch.post('/alerts/cap-management-alert/', {"date": date, "id": id}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
