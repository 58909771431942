import {useEffect, useState} from "react";
import {Field} from "formik";
import {VOLUUM_REPORTS_OPERATORS_LIST} from "../../../../constants";

const CustomInputSelect = ({name, labelName, list, currentValue, value, setFieldValue, errors, touched, additionalClass, label = true, hint = false, hintValue, setSettingsStatus}) => {
    const [hintText, setHintText] = useState('');

    useEffect(() => {
        if(value && value !== '') {
            const obj = VOLUUM_REPORTS_OPERATORS_LIST.filter(el => el.id === value);
            if(hint && name === 'operatorForCost') {
                setHintText(`sum ${name.replace('operatorFor', '').toUpperCase()} ${obj[0]['hintText']} ${hintValue && hintValue !== '' ? hintValue : '_'} USD`);
            } else if(hint) {
                setHintText(`${name.replace('operatorFor', '').toUpperCase()} ${obj[0]['hintText']} ${hintValue && hintValue !== '' ? hintValue : '_'}%`);
            }
        } else {
            if(name === 'operatorForCost') {
                setHintText(`sum ${name.replace('operatorFor', '').toUpperCase()} of more than ${hintValue && hintValue !== '' ? hintValue : '_'} USD`);
            } else if(hint) {
                setHintText(`${name.replace('operatorFor', '').toUpperCase()} of more than ${hintValue && hintValue !== '' ? hintValue : '_'}%`);
            }

        }
    }, [name, hint, value, hintValue])

    return (
        <div className={`input-wrap ${additionalClass}${errors && touched ? 'error-input' : ''}`}>
            <div className="input-block">
                {label ?
                    <label htmlFor="name" className="label-name">
                        <span className="content-name">{labelName? labelName : name.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}</span>
                    </label> :
                    null
                }
                <Field
                    id={name}
                    name={name}
                    value={value}
                    as="select"
                    className={currentValue !== '' ? 'filled' : ''}
                    onChange={e => {
                        setFieldValue(name, e.target.value);
                        if(setSettingsStatus) setSettingsStatus(e.target.value !== currentValue);
                    }}
                >
                    {list.map(({id, name}) => {
                        return (
                            <option
                                key={id}
                                value={id}
                            >
                                {name}
                            </option>
                        )
                    })}
                </Field>
            </div>
            {errors && touched && <div className='error-text'>{errors}</div>}
            {hint ? <p className='custom-form-hint'>{hintText}</p> : null}
        </div>
    )
}

export default CustomInputSelect;
