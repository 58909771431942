import {useSelector, useDispatch} from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import {setDate} from '../../redux/dataUploader/dataSlice';

import "react-datepicker/dist/react-datepicker.css";
import './custom-datepicker.scss';

const CustomDatePicker = () => {
    const dispatch = useDispatch();
    const date = useSelector((state) => state.dataUploader.date);

    return (
        <DatePicker
            selected={date}
            onChange={(date) => dispatch(setDate(date))}
            maxDate={moment().toDate()}
        />
    );
};

export default CustomDatePicker;