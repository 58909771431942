import React from "react";
import './update-item-btn.scss'

const UpdateItemBtn = ({onClick, newItem}) => {
    return (
        <button className={newItem ? "new-item-update-button" : "item-update-button"} onClick={onClick}>
            <img src={`${process.env.PUBLIC_URL}/images/update-btn.svg`} alt="update"/>
        </button>
    )
}

export default UpdateItemBtn;