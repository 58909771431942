import {CSVLink} from "react-csv";
import '../buttons.scss';

const CsvDownload = ({
                         csvData,
                         filename,
                         children,
                         separator,
                         voluumFile = false,
                         params,
                         additionalClass,
                         enclosingCharacter,
                         disabled = false,
                         specGeoForMultiple = '',
                         headers = null,

                     }) => {
    const generateCsvData = () => {
        let newData = [];
        const {pagesFrom, pagesTo, workspaceId, header, blogrefNoref} = params;
        let data = JSON.parse(JSON.stringify(csvData))
        if (workspaceId) data.forEach(el => el['11. Workspace ID'] = workspaceId)
        if (blogrefNoref) data.forEach(el => el['2. URL'] = blogrefNoref + el['2. URL'])
        data.forEach(el => {
            el['1. Offer name'] = '"' + el['1. Offer name'].replace(/\"/g, "\"\"") + '"';
            el['3. Country code'] = el['3. Country code'].split(',').length > 1
                ? '"' + specGeoForMultiple + '"'
                : '"' + el['3. Country code'] + '"';
        })
        if (!header) {
            data.forEach(el => newData.push(Object.values(el)));
        } else {
            newData = [...data];
        }
        const pagesFromVal = Number(pagesFrom);
        const pagesToVal = Number(pagesTo);

        if (pagesFromVal > 0 && pagesToVal >= pagesFromVal && pagesToVal <= newData.length) {
            newData = newData.slice(pagesFromVal - 1, pagesToVal);
        }
        return newData;
    };

    return (
        <div
            className={`csv-btn-wrap ${csvData.length ? '' : 'disabled'} ${additionalClass ? additionalClass : ''} ${disabled ? 'disabled' : ''}`}>
            <CSVLink
                data={voluumFile ? generateCsvData() : csvData}
                filename={filename}
                className='blue-btn'
                target='_blank'
                separator={separator}
                enclosingCharacter={enclosingCharacter}
                disabled={disabled}
                headers={headers}
            >
                {children}
            </CSVLink>
        </div>
    )
};

export default CsvDownload;