import './custom-tooltip.scss';

const CustomTooltip = () => {
    return (
        <div className="custom-tooltip">
            <p>click to copy</p>
        </div>
    );
};

export default CustomTooltip;