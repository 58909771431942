import {logOut} from "../redux/dataUploader/dataSlice";
import {getVoluumAlertsConfigService} from "../services/voluum";

const GetVoluumAlertsConfigData = async (setError, dispatch, setConfigData) => {
    try {
        const results = await getVoluumAlertsConfigService();
        if(results.data.error) {
            setError(true);
        } else {
            setConfigData(results.data.data);
        }
    } catch (error) {
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default GetVoluumAlertsConfigData;