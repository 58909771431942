import {QRCodeSVG} from 'qrcode.react';
import { Formik, Form, Field } from 'formik';
import './otp-popup.scss'

const OtpPupup = ({setStatus, qrcodeUrl, optTokenValue, setOptTokenValue, onVerifyCode, otpCode, formError}) => {
    const onClosePopup = () => {
        setStatus(false);
    }

    const onChangeToken = (inputValue) => {
        setOptTokenValue(inputValue)
    }

    return (
        <div className='otp-popup'>
            <div className="content-wrap">
                <h3>Two-factor Authentication (2FA)</h3>

                <div className="configuring-wrap">
                    <h4>Configuring Google Authentication (2FA)</h4>
                    <ol>
                        <li>1. Install Google Authenticator or Authy</li>
                        <li>2. In the authenticator app, select “+” icon</li>
                        <li>3. Select “Scan a barcode (or QR code)” and use the phone’s camera to scan this barcode</li>
                    </ol>
                </div>

                <div className="qr-code">
                    <h4>Scan QR Code</h4>
                    <div className="qr-code-wrap">
                        <QRCodeSVG value={qrcodeUrl} size={180} />,
                    </div>
                </div>

                <div className="code-wrap">
                    <h4>or Enter Code Into Your App</h4>

                    <p>
                        SecretKey: {otpCode}
                    </p>
                </div>
                <Formik
                    initialValues={{ code: '' }}
                    onSubmit={onVerifyCode}>
                    {
                        ({ errors, touched }) => (
                            <Form>
                                <div className="verify-code-wrap">
                                    <h4>Verify Code</h4>
                                    <p>for changing the setting, please verify the authentication code:</p>
                                    <Field
                                        type="text"
                                        name='code'
                                        placeholder='Authentication Code'
                                        className={formError ? 'input-error' : ''}
                                        value={optTokenValue}
                                        onChange={({target}) => onChangeToken(target.value)}
                                    />
                                    {formError && <div className='error-text login-error-text'>Code is invalid</div>}
                                </div>

                                <div className="btn-wrap">
                                    <button className="verify-btn" type="submit">Verify & Activate</button>
                                    <button className="close-btn" type="button" onClick={onClosePopup}>Close</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
    )
}

export default OtpPupup;