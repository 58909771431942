import {logOut} from "../redux/dataUploader/dataSlice";
import {updateAccountDetailsService} from "../services/yieldkit";
import {modifyEmptyTableData} from '../helpers/modify-accounts-data';

const updateAccountsConfigTable = async (setFormLoading, setSuccessStatus, setFormError, dispatch, data, accountDetails, setAccountDetails) => {
    setFormLoading(true);

    try {
        const copyAccountsData = [...accountDetails];
        copyAccountsData.forEach(itm => {
            if (itm.id === data.id) {
                data['voluum_account_id'] = itm['voluum_account_id'] === '' ? null : itm['voluum_account_id']
            }
        })

        const newAccountData = {
            'data': data
        }

        const results = await updateAccountDetailsService(newAccountData);
        setFormError(results.data.error);
        setSuccessStatus(!results.data.error);

        const newAccountsData = copyAccountsData.map(accountsData => {
            if (accountsData.id === data.id && accountsData['site_id'] === data['site_id']) {
                return modifyEmptyTableData(data);
            } else {
                return accountsData;
            }
        })
        setAccountDetails(newAccountsData)

        setFormLoading(false);
    } catch (error) {
        setFormLoading(false);
        setFormError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
};

export default updateAccountsConfigTable;